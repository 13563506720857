
import PageTitle from "../../components/PageTitle.vue";
import numeral  from 'numeral';

export default {
	components: { PageTitle },
	data() {
		return {
			cargar: false,
			title: "Detalle Liquidación de Caja",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
        	isBusy: false,
			fields: [
				{ key: "index", 				label: "N°",			sortable: false },
				{ key: "fechaEmision", 			label: "Emisión", 		sortable: true },
				{ key: "cliente", 				label: "Cliente", 		sortable: true },
				{ key: "mtoValorInicial", 		label: "P.Lista", 		sortable: true },
				{ key: "mtoDescuento", 			label: "Dscto", 		sortable: true },
				{ key: "numero", 				label: "Número", 		sortable: true },
				{ key: "moneda", 				label: "Moneda", 		sortable: true },
				{ key: "mtoOperGravadas", 		label: "Gravad", 		sortable: true },
				{ key: "mtoImpVenta", 			label: "Total", 		sortable: true },
				{ key: "tipoAtencion", 			label: "Tipo Atención", sortable: true },
				{ key: "medico", 				label: "Médico", 		sortable: true },
				{ key: "comoLlego", 			label: "Cómo Lllegó", 	sortable: true },
				{ key: "urlComprobante", 		label: "PDF", 			sortable: true },
				{ key: "estadoPaciente", 		label: "Cliente", 		sortable: true },
			],
			columns : [
				{ field: "cliNumDoc", 			label: "Num Doc", 			 },
				{ field: "cliRznSocial", 		label: "Razon Social", 		 },
				{ field: "comprobante_tipo", 	label: "Comprobante Tipo", 	 },
				{ field: "fechaEmision", 		label: "fecha Emision", 	 },
				{ field: "serie", 				label: "Serie", 			 },
				{ field: "correlativo", 		label: "Comprobante", 		 },
				{ field: "moneda", 				label: "Fecha", 			 },
				{ field: "mtoOperGravadas", 	label: "mto Oper Gravadas",  },
				{ field: "mtoImpVenta", 		label: "mto Imp Venta", 	 },
				{ field: "rutaXml", 			label: "XML", 				 },
				{ field: "rutaCDR", 			label: "CDR", 				 },
				{ field: "respSunat", 			label: "resp Sunat", 		 },
			],
			items: [],
			medicosComision:[],
			filter: null,
			filterComisiones: null,
			documentoUrl: '',
			totalRowsComisiones: 1,
			currentPageComisiones: 1,
			itemsComisiones: [],
			fieldsComisiones:[		
				{ key: "index", 				label: "N°",					sortable: false },		
				{ key: "medico", 				label: "Medico", 				sortable: true },
				{ key: "descripcion", 			label: "Servicio o Producto", 	sortable: true },
				{ key: "moneda", 				label: "Mon", 					sortable: true },
				{ key: "comision", 				label: "Comision", 				sortable: true },
				{ key: "programacion_cita_id", 	label: "Cita", 					sortable: true },
				{ key: "fecha", 				label: "Emisión", 				sortable: true },
				{ key: "comprobante", 			label: "Comprobante", 			sortable: true },
				{ key: "urlRecibo", 			label: "Recibo", 				sortable: true },
				],
			columnsComisiones : [
				{	label: "Medico",			field: "medico", },
				{	label: "Tipo Contrato",		field: "tipo_contrato", },
				{	label: "Servicio o Producto",	field: "descripcion", },
				{	label: "Mon",				field: "moneda",},
				{	label: "Comision",			field: "comision"},
				{	label: "Cita",				field: "programacion_cita_id",},
				{	label: "Comprobante",		field: "comprobante",},
				{	label: "Fecha",				field: "fecha",}
			],
			itemsComision: [],		
			caja_venta_turno: '',
			liquidacion: '',
			liquidacion_id: '',
			formRefrenda: '',			
			fieldsComisionMedico: [
				{ key: "index", 	label: "N°",		sortable: false },
				{ key: "medico", 	label: "Medico",	sortable: true },
				{ key: "moneda", 	label: "Moneda", 	sortable: true },
				{ key: "total", 	label: "Total", 	sortable: true },
			],
			itemsComisionMedico: [],
			fieldsTotal: [
				{ key: "index", 	 label: "N°",			sortable: false },
				{ key: "acciones", 	 label: "Acciones",		sortable: false },
				{ key: "medio_pago", label: "Medio Pago",	sortable: true },
				{ key: "moneda", 	 label: "Moneda", 		sortable: true },
				{ key: "total", 	 label: "Total", 		sortable: true },
				{ key: "file", 	 	 label: "Refrenda", 	sortable: true },
			],
			itemsTotales: [],
			itemsTotalesVenta: [],
			caja_venta_turno_id: ''
		};
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
		url() {
			return this.$store.state.url;
		},
	},
	methods: {
		formatMoneda(num){
			return numeral(num).format('0,0.00');
		},
		async inputFile(e){
			this.file = this.$refs.file.files[0];
		},
		async init(){	
			let resp:any = '';
			this.liquidacion_id = this.$route.params.id;
			try {
				resp = await this.axios.get(`liquidacions/${this.liquidacion_id}`);
				this.liquidacion = resp.data;
				this.caja_venta_turno_id = this.liquidacion.caja_venta_turno_id;

				resp = await this.axios.get(`cajaVentaTurnos/${this.liquidacion.caja_venta_turno_id}`);
				this.caja_venta_turno = resp.data;

				this.listar();
				this.listarVentaComision();
			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		async listar(){
			let resp:any = '';
			this.isBusy = true;

			try {
				resp = await this.axios.get(`cajaVentaTurno/comprobantes/${this.caja_venta_turno_id}`);
				this.items = resp.data;	
				this.totalRows = this.items.length;	
				this.isBusy = false;

				resp = await this.axios.get(`cajaVentaTurno/medicos/${this.caja_venta_turno_id}`);
				this.itemsComisionMedico = resp.data;

				resp = await this.axios.get(`liquidacionCajaTurno/liquidacion/${this.liquidacion_id}`);
				this.itemsTotales = resp.data;

				resp = await this.axios.get(`cajaVentaTurno/comisiones/${this.caja_venta_turno_id}`);
				this.itemsComisiones = resp.data;				
				this.totalRowsComisiones = this.itemsComisiones.length;	

			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},		
		async listarVentaComision(){
			try {
				let {data} = await this.axios.get(`liquidacionCajaTurno/liquidacion/${this.liquidacion_id}`);
				this.itemsTotales = data;	
			} catch (error) {
				this.catchAction(error);
			}
		},
		validarVisado(){
			// @ts-ignore
			$("#modalValidar").modal({backdrop: 'static', keyboard: false});
		},
		validarRefrenda(){
			this.$swal.fire({
				title: 'Refrendar?',
				text: this.caja_venta_turno.liquidacion,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Refrendar',
				showLoaderOnConfirm: true,
				preConfirm: (login) => {
					return this.axios.put('liquidacion/refrenda/' + this.liquidacion.id)
					.then((result) => {
						return result.data;
					}).catch((err) => {
						this.$swal.showValidationMessage(
							`Request failed: ${err}`
						)
					});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then(async (result) => {
				if (result.isConfirmed) {	
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					let resp = await this.axios.get(`liquidacions/${this.liquidacion_id}`);
					this.liquidacion = resp.data;
				}
			});
		},
		async submitValidar(e){
			e.preventDefault();
			e.preventDefault();
			let data:any = '';
			let resp:any = '';
			this.cargar = true;
			
			try {
				resp 	= await this.axios.put('liquidacion/aprobacion/' + this.liquidacion.id, this.liquidacion);
				data	= resp.data;

				resp = await this.axios.get(`liquidacions/${this.liquidacion_id}`);
				this.liquidacion = resp.data;
				
				this.cargar = false;
				// @ts-ignore
				$("#modalValidar").modal('hide');
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
			
		},
		modalRefrenda(item){
			this.formRefrenda = item;
			// @ts-ignore
			$("#modalRefrenda").modal({backdrop: 'static', keyboard: false});
		},		
		async submitRefrenda(e){
			e.preventDefault();
			this.cargar = true;
			try {
				let {data} 	= await this.axios.put('liquidacionCajaTurnos/' + this.formRefrenda.id, this.formRefrenda);
								
				if(this.file && data.data.id > 0){
					await this.upload(data.data.id, data.data.file_id, 'file_id', this.file, 15);
				}

				this.listarVentaComision();				
			    this.cargar = false;
				// @ts-ignore
				$("#modalRefrenda").modal('hide');
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargar = false;				
				this.catchAction(error)
			}
		},		
		async upload(id, columna_id, columna, file, model){
			return new Promise(async (resolve, reject) => {
				let formData = new FormData();
				formData.append('id',id);
				formData.append('columna_id',columna_id);
				formData.append('columna',columna);
				formData.append('file',file);
				formData.append('model', model);
				let {data} = await this.axios.post('file', formData);
				resolve(data);
			});
		},
		modalDocumento(url){
			this.documentoUrl = url;
			// @ts-ignore
			$("#modalDocumento").modal({backdrop: 'static', keyboard: false});
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
		onFilteredComisiones(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRowsComisiones = filteredItems.length;
			this.currentPageComisiones = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
