
import PageTitle from "../../components/PageTitle.vue";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar: false,
			title: "Producto o Servicio",
			id: "",
			formulaComponentes: [],
			categorias: [],
			unidadMedidas: [],
			presentacions: [],
			marcas: [],
			grupoMedicamentos: [],
			monedas: [],
			tipoAfectacions: [],
			cod_sunats: [],
			formFormula: {
				formulaComponente: "",
				porcentaje: "",
				presentacion: "",
				medida: "",
				undMedida: "",
			},
			arrFormFormula: [],
			form: {
				categoria_id: "",
				discripcion: "",
				codigo: "",
				grupo_medicamentos: [],
				marca_id: "",
				presentacion_id: "",
				cod_sunat_id: "",
				moneda_id: 1,
				tipo_id: "",
				tipo_afectacion_id: 1,
				unidad_medida_id: 1,
				destacado: "",
				valor_compra: "",
				precio_compra: "",
				valor_venta: "",
				precio_venta: "",
				isDestacado: 0,
			},
		};
	},
	watch: {
		"form.precio_compra": function(newValor, oldValor){
			this.form.valor_compra = (newValor/this.igv).toFixed(4);
		},
		"form.precio_venta": function(newValor, oldValor){
			this.form.valor_venta = (newValor/this.igv).toFixed(4);
		}
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key }; 
				});
		},
		igv(){
			let tipo_afectacion = this.tipoAfectacions.find(item => item.id == this.form.tipo_afectacion_id);
			console.log(tipo_afectacion);
			return (tipo_afectacion.porcentaje + 100) / 100;
		}
	},
	methods: {
		showCategoria() {
			if (this.form.categoria_id == 1) {
				this.formFormula = {
					formulaComponente: "",
					porcentaje: "",
					presentacion: "",
					medida: "",
					undMedida: "",
				};
				// @ts-ignore
				$(".modal").modal({ backdrop: "static", keyboard: false });
			}
		},
		submitFormula() {
			if (!this.formFormula.formulaComponente || !this.formFormula.porcentaje) {
				return false;
			}
			let item =
				this.formFormula.formulaComponente.nombre +
				" " +
				this.formFormula.porcentaje +
				"%";

			this.arrFormFormula.push(item);

			this.arrFormFormula = this.arrFormFormula.sort((a, b) => {
				if (a > b) {
					return 1;
				}
				if (a < b) {
					return -1;
				}
				return 0;
			});
			this.formFormula = { formulaComponente: "", porcentaje: "" };
		},
		submitFormulaProceso() {
			let unidadMedidas = this.unidadMedidas.find(
				(item) => item.id == this.formFormula.undMedida
			);
			let text =
				this.arrFormFormula.join(" ") +
				" " + this.formFormula.medida +
				" " +
				unidadMedidas.abrev;

			this.form.discripcion = text;
			this.form.unidad_medida_id = 1
			this.form.presentacion_id = this.formFormula.presentacion;

			// @ts-ignore
			$(".modal").modal("hide");
		},		
		eliminarComponente(index){
			let arrFormFormula = JSON.parse(JSON.stringify(this.arrFormFormula));
			arrFormFormula.splice(index, 1);
			this.arrFormFormula = arrFormFormula;
		},
		async fetchOptionsCodSunats(search, loading){
			if(search.length > 3){
				let {data} = await this.axios.get('select/codSunats/'+search);
				this.cod_sunats = data;
			}
		},
		async init() {
			let resp: any = "";
			this.cargar = true;
			try {
				resp = await this.axios.get("select/grupoMedicamentos");
				this.grupoMedicamentos = resp.data;

				resp = await this.axios.get("select/categorias");
				this.categorias = resp.data;

				resp = await this.axios.get("select/formulaComponentes");
				this.formulaComponentes = resp.data;

				resp = await this.axios.get("select/unidadMedidas");
				this.unidadMedidas = resp.data;

				resp = await this.axios.get("select/presentacions");
				this.presentacions = resp.data;

				resp = await this.axios.get("select/marcas");
				this.marcas = resp.data;

				resp = await this.axios.get("select/monedas");
				this.monedas = resp.data;

				resp = await this.axios.get("select/tipoAfectacions");
				this.tipoAfectacions = resp.data;

				if(this.$route.params.id > 0){
					this.id = this.$route.params.id;
					this.obtener();
				}
				this.cargar = false;

			} catch (error) {	
				this.cargar = false;
				this.catchAction(error);
			}			
		},
		async obtener(){
			try {
				let {data} = await this.axios.get('productos/' + this.id);
				this.form = data;				
			} catch (error) {
				this.catchAction(error);				
			}
		},
		async submit(e){
			e.preventDefault();
			let data:any = '';
			let resp:any = '';
			this.cargar = true;
			try {
				if(this.form.id > 0){
					resp 	= await this.axios.put('productos/' + this.form.id, this.form);
					data	= resp.data;
				}else{
					resp 	= await this.axios.post('productos', this.form);
					data	= resp.data;
				}
				this.id = data.data.id;	
				// @ts-ignore
				new PNotify({
						text: data.msg,
						type: 'success',
						styling: 'bootstrap3'
					});		
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
			this.cargar = false;
		}
	},
	mounted() {
		// Set the initial number of items
		this.init();		
	},
};
