
import PageTitle from "../../components/PageTitle.vue";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar:false,
			title: "Examen Clinico",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "acciones", 				label: "Acciones",				sortable: false },
				{ key: "nombre", 				label: "Examen Clinico", 		sortable: true },
				{ key: "grupoExamenClinico", 	label: "Grupo Examen Clinico", 	sortable: true },
				{ key: "detalle", 				label: "Detalle", 				sortable: true },
				{ key: "estado", 				label: "Estado", 				sortable: true }],
			items: [],
			filter: null,
			grupoExamenClinicos: [],
			form: {
				nombre: "",
				grupo_examen_clinico_id: "",
				detalle: 0
			},
		};
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	methods: {
		async init(){
			try {
				this.listar();
				let {data} = await this.axios.get('select/grupoExamenClinicos');
				this.grupoExamenClinicos = data;				
			} catch (error) {				
				this.catchAction(error);	
			}
		},
		async listar(){
			try {
				let {data} = await this.axios.get('examenClinicos');
				this.items = data;	
				this.totalRows = this.items.length;	
			} catch (error) {
				this.catchAction(error);
			}
		},
		async show(id = 0){
			this.cargar = false;
			try {
				this.reset();
				if(id > 0){
					let {data} = await this.axios.get('examenClinicos/' + id);
					this.form = data;
				}
				// @ts-ignore
				$(".modal").modal({backdrop: 'static', keyboard: false})
			} catch (error) {
				this.catchAction(error);
			}	
		},
		async state(item, state){
			let title = 'Activar?';
			let confirmButtonText = 'Si, Activar.';
			if(state == 0){
				title = 'Desactivar?';
				confirmButtonText = 'Si, Desactivar.';
			}

			this.$swal.fire({
				title: title,
				text: item.nombre,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: confirmButtonText,
				showLoaderOnConfirm: true,
				preConfirm: (login) => {
					return this.axios.patch('examenClinicos/' + item.id+'/state',{isActive: state})
					.then((result) => {
						return result.data;
					}).catch((err) => {
						this.$swal.showValidationMessage(
							`Request failed: ${err}`
						)
					});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {	
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.listar();
				}
			});
		},
		reset(){
			this.form = {
				nombre: "",
				grupo_examen_clinico_id: "",
				detalle: 0
			}
		},
		async submit(e){
			e.preventDefault();
			let data:any = '';
			let resp:any = '';
			this.cargar = true;
			try {
				if(this.form.id > 0){
					resp 	= await this.axios.put('examenClinicos/' + this.form.id, this.form);
					data	= resp.data;
				}else{
					resp 	= await this.axios.post('examenClinicos', this.form);
					data	= resp.data;
				}
				
				this.listar();
				// @ts-ignore
				$(".modal").modal('hide');
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
			this.cargar = false;
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
