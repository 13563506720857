
import PageTitle from "../../components/PageTitle.vue";
import SetFecha from "../../components/SetFecha.vue";
import Cderma2021 from "../../components/Atencion/Cderma2021.vue";
import Historia from "../../components/Atencion/Historia.vue";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
	components: { PageTitle, SetFecha, Historia, Cderma2021, vueDropzone: vue2Dropzone },
	data() {
		return {
			cargar: false,
			cargartabs: false,
			cargarHistoria: false,
			inicioPagina: false,
			cargarFormulario: false,
			id: 0,
			slide: 0,
			acto_medico_id: 0,
			historia_clinica_id: 0,
			title: "Historia Clínica",
			acto_medico: "",
			exLaboratorios: [],
			exImagenes: [],
			especialidads: [],
			cies: [],
			ubicacionCorporals: [],
			categorias: [],
			grupoMedicamentos: [],
			medicamentos: [],
			unidadMedidas: [],
			presentacions: [],
			hcTipoArchivos: [],
			tratamientos: [],
			srcdocumentoPdf: "#",
			condiciones: [],
			paciente: "",
			form: "",
			diagnosticos: [],
			formDiagnostico: {
				cie_id: "",
				cie: "",
				ubicacion_corporal_id: "",
				historia_clinica_id: "",
				cod_cie: "",
				diagnostico: "",
				especifico: "-",
				severidad: "",
				ubicacion: "",
			},
			alergias: [],
			pacAlergias: [],
			formAlergia: {
				alergia: "",
				fecha: "",
				detalle: ""
			},
			antecedentes: [],
			formAntecedente: {
				cie_id: "",
				cie: "",
				ubicacion_corporal_id: "",
				cod_cie: "",
				diagnostico: "",
				especifico: "-",
				severidad: "",
				ubicacion: "",
				detalle: ""
			},
			recetaMedicas: [],
			formRecetaMedica: {
				diagnostico_id: "",
				diagnostico: "",
				categoria_id: "",
				grupo_medicamento_id: "",
				medicamento: "",
				indicaciones: "",
			},
			formFormula: {
				formulaComponente: "",
				porcentaje: "",
				presentacion: "",
				medida: "",
				undMedida: "",
			},
			formulaComponentes: [],
			arrFormFormula: [],
			formInterconsulta: {
				especialidad_id: "",
				motivo: "",
				observaciones: "",
			},
			formExImagenes: {
				examen_clinicos: [],
				otros: "",
				zonaAnatomica: "",
				propositoExamen: "",
			},
			formBiopsia: {
				diagnosticoClinico: "",
				antecedentesPersonales: "",
				observaciones: "",
			},
			relatoCronologicos: [],
			formRelatoCronologicos: {
				detalle: "",
			},
			parametros: [],
			formParametro: {
				detalle: "",
			},
			examenPreferencials: [],
			formExamenPreferencials: {
				detalle: "",
			},
			hcdocumentos: [],
			formRecurso: {
				hc_tipo_archivo_id: "",
				file: "",
			},
			documentoUrl: "",
			formLabClinicos: {
				examenes: [],
				exLaboratorios: [],
			},
			traUbiCorporals: [],
			protocolos: [],
			diametros: [],
			formProtocolo: {
				diagnostico_id: "",
				tratamiento_id: "",
				ubicacion_id: "",
				diametro_id: "",
				moneda_id: "",
				sesion: "",
				descuento: 0,
			},	
			protocolosSeguimiento: [],
			fieldsProtocolosSeguimiento: [
				{ key: "index", label: "N°" },
				{ key: 'numero', label: 'Acto Medico' },
				{ key: 'fecha', label: 'Fecha' },
				{ key: 'tratamiento', label: 'Tratamiento' },
				{ key: 'sesion', label: 'Sesion' },
				{ key: 'realizado', label: 'Realizado' },
				{ key: 'estado', label: 'Estado' },
			],
			PStotalRows: 1,
			PScurrentPage: 1,
			PSperPage: 10,
			formPS: {
				sesion: '',
				comentario: '',
			},
			itemProtocolo: '',
			seguimientoProtocolos: [],
			tabs: {
				tab1: false,
				tab2: false,
				tab3: false,
				tab4: false,
				tab6: false,
				tab7: false,
				tab8: false,
				tab9: false,
				tab10: false,
				tab11: false,
				tab12: false,
				tab13: false,
				tab14: false,
			},
		};
	},
	watch: {
		async "formProtocolo.tratamiento_id"(newValor, oldValor) {
			if (newValor > 0) {
				let { data } = await this.axios.get(
					"select/tratamiento_ubicacion/" + newValor
				);
				this.traUbiCorporals = data;
			}
		},
		async "formProtocolo.ubicacion_id"(newValor, oldValor) {
			if (newValor > 0) {
				let { data } = await this.axios.get(
					"select/selectDiametro/" + newValor
				);
				this.diametros = data;
			}
		},
	},
	computed: {
		totalProtocolo() {
			let ubicacion = this.diametros.find(
				(item) => item.id == this.formProtocolo.diametro_id
			);
			if (!ubicacion || !this.formProtocolo.sesion) {
				return 0.0;
			}
			let total = this.formProtocolo.sesion * ubicacion.importe;
			this.formProtocolo.subtotal = total;
			total = total - (total * this.formProtocolo.descuento) / 100;
			this.formProtocolo.total = total;
			this.formProtocolo.moneda = ubicacion.moneda;
			this.formProtocolo.moneda_id = ubicacion.moneda_id;
			return ubicacion.moneda + " " + total;
		},
		url() {
			return this.$store.state.url;
		},
		montoProtocolo() {
			let total = [];
			this.protocolos.forEach((item) => {
				if (total[item.moneda_id]) {
					let suma = total[item.moneda_id].total * 1 + item.total * 1;
					let descuento =
						total[item.moneda_id].descuento * 1 + item.descuento * 1;
					total[item.moneda_id]["descuento"] = descuento;
					total[item.moneda_id]["total"] = suma;
				} else {
					total[item.moneda_id] = {
						moneda: item.moneda,
						descuento: item.descuento * 1,
						total: item.total * 1,
					};
				}
			});
			let array = [];
			total.forEach((item) => {
				if (item) {
					array.push(item);
				}
			});
			return array;
		},
	},
	methods: {
		viewPdf(nombre) {
			this.srcdocumentoPdf =
				this.url +
				"pdf/" +
				nombre +
				"/" +
				this.historia_clinica_id +
				"?doc=" +
				Math.random();
			// @ts-ignore
			$("#modalPdf").modal({ backdrop: "static", keyboard: false });
		},
		modalDocumento(url) {
			this.documentoUrl = url;
			// @ts-ignore
			$("#modalDocumento").modal({ backdrop: "static", keyboard: false });
		},
		async inputFile(e) {
			this.formRecurso.file = this.$refs.fileRecursoDocumento.files[0];
		},
		async preCarga(num) {
			let resp: any = "";
			try {
				if (num == 2) {
					//historia clinica
					if (this.tabs.tab2) {
						return false;
					}
					this.cargar = true;
					resp = await this.axios.get(
						"relatoCronologicos/cita/" + this.historia_clinica_id
					);
					this.relatoCronologicos = resp.data;

					resp = await this.axios.get(
						"parametros/cita/" + this.historia_clinica_id
					);
					this.parametros = resp.data;

					resp = await this.axios.get(
						"examenPreferencials/cita/" + this.historia_clinica_id
					);
					this.examenPreferencials = resp.data;

					resp = await this.axios.get(
						"diagnosticos/historiaClinica/" + this.historia_clinica_id
					);
					this.diagnosticos = resp.data;
					//protocolo
					resp = await this.axios.get("select/tratamientos");
					this.tratamientos = resp.data;
					resp = await this.axios.get(
						"protocolos/historiaClinica/" + this.historia_clinica_id
					);
					this.protocolos = resp.data;

					this.tabs.tab2 = true;
					this.cargar = false;
				} else if (num == 3) {
					//adjuntos
					if (this.tabs.tab3) {
						return false;
					}
					this.cargar = true;
					resp = await this.axios.get("select/hcTipoArchivos");
					this.hcTipoArchivos = resp.data;

					resp = await this.axios.get(
						"hcdocumentos/historiaClinica/" + this.historia_clinica_id
					);
					this.hcdocumentos = resp.data;
					this.tabs.tab3 = true;
					this.cargar = false;
				} else if (num == 4) {
					// historial
					if (this.tabs.tab4) {
						return false;
					}
					this.$refs.Historia.init();
					this.tabs.tab4 = true;
				} else if (num == 6) {
					// Receta
					if (this.tabs.tab6) {
						return false;
					}
					this.cargartabs = true;
					resp = await this.axios.get(
						"recetaMedicas/historiaClinica/" + this.historia_clinica_id
					);
					this.recetaMedicas = resp.data;
					resp = await this.axios.get("select/categorias");
					this.categorias = resp.data;
					resp = await this.axios.get("select/grupoMedicamentos");
					this.grupoMedicamentos = resp.data;
					resp = await this.axios.get("select/formulaComponentes");
					this.formulaComponentes = resp.data;
					resp = await this.axios.get("select/unidadMedidas");
					this.unidadMedidas = resp.data;
					resp = await this.axios.get("select/presentacions");
					this.presentacions = resp.data;
					this.tabs.tab6 = true;
					this.cargartabs = false;
				} else if (num == 7) {
					// Ex. Laboratorio
					if (this.tabs.tab7) {
						return false;
					}
					this.cargartabs = true;

					resp = await this.axios.get("atencion/exLaboratorios");
					this.exLaboratorios = resp.data;

					resp = await this.axios.get(
						"laboratorioClinicos/historiaClinica/" + this.historia_clinica_id
					);

					this.formLabClinicos.exLaboratorios = resp.data;
					this.formLabClinicos.examenes = [];
					this.exLaboratorios.forEach((laboratorios) => {
						laboratorios.examenes.forEach((item) => {
							let examen = resp.data.find(
								(examen) => examen.examen_clinico_id == item.id
							);
							if (examen) {
								item.id = examen.examen_clinico_id;
								item.text = examen.text;
								this.formLabClinicos.examenes.push(examen.examen_clinico_id);
							}
						});
					});

					this.tabs.tab7 = true;
					this.cargartabs = false;
				} else if (num == 8) {
					// Ex. Imagenes
					if (this.tabs.tab8) {
						return false;
					}
					this.cargartabs = true;

					resp = await this.axios.get("atencion/exImagenes");
					this.exImagenes = resp.data[0].examenes;
					resp = await this.axios.get(
						"examenesImagens/historiaClinica/" + this.historia_clinica_id
					);
					if (resp.data) {
						this.formExImagenes = resp.data;
					}

					this.tabs.tab8 = true;
					this.cargartabs = false;
				} else if (num == 9) {
					// Estudio Anomapatologico
					if (this.tabs.tab9) {
						return false;
					}
					this.cargartabs = true;

					resp = await this.axios.get(
						"estudioAnomapatologicos/historiaClinica/" +
						this.historia_clinica_id
					);
					if (resp.data) {
						this.formBiopsia = resp.data;
					}

					this.tabs.tab9 = true;
					this.cargartabs = false;
				} else if (num == 10) {
					// Interconsulta
					if (this.tabs.tab10) {
						return false;
					}
					this.cargartabs = true;

					resp = await this.axios.get("select/especialidads");
					this.especialidads = resp.data;
					resp = await this.axios.get(
						"interconsultas/historiaClinica/" + this.historia_clinica_id
					);
					if (resp.data) {
						this.formInterconsulta = resp.data;
					}

					this.tabs.tab10 = true;
					this.cargartabs = false;
				} else if (num == 11) {
					//adjuntos
					if (this.tabs.tab11) {
						return false;
					}
					this.cargar = true;

					resp = await this.axios.get("select/alergias");
					this.alergias = resp.data;

					resp = await this.axios.get(
						"pacientes/" + this.form.paciente_id + "/alergias"
					);
					this.pacAlergias = resp.data;

					resp = await this.axios.get(
						"pacientes/" + this.form.paciente_id + "/antecedentes"
					);
					this.antecedentes = resp.data;
					this.tabs.tab11 = true;
					this.cargar = false;
				} else if (num == 12) {
					if (this.tabs.tab12) {
						return false;
					}
					this.cargar = true;

					resp = await this.axios.get("protocolos/paciente/" + this.form.paciente_id);
					this.protocolosSeguimiento = resp.data;

					this.tabs.tab12 = true;
					this.cargar = false;
				} else if (num == 13) {
					if (this.tabs.tab13) {
						return false;
					}
					this.$refs.Cderma2021.init();
					this.tabs.tab13 = true;
				} else {
					this.cargartabs = false;
					this.cargar = false;
					console.log("");
				}
				this.cargartabs = false;
				this.cargar = false;
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async init() {
			this.cargar = true;
			let resp: any = "";

			try {
				resp = await this.axios.get(
					"historiaClinicas/" + this.historia_clinica_id
				);
				this.form = resp.data;

				resp = await this.axios.get("pacientes/" + this.form.paciente_id);
				this.paciente = resp.data;

				resp = await this.axios.get(
					"actoMedicos/historiaClinica/" + this.acto_medico_id
				);
				this.acto_medico = resp.data;

				resp = await this.axios.get("select/ubicacionCorporals");
				this.ubicacionCorporals = resp.data;

				resp = await this.axios.get("select/condicion_historia_clinicas");
				this.condiciones = resp.data;

				this.cargar = false;
			} catch (error) {
				this.catchAction(error);
			}
			this.inicioPagina = true;
		},
		modalDocumentoActoMedico() {
			// @ts-ignore
			$("#documentoActoMedico").modal({ backdrop: "static", keyboard: false });
		},
		modalGaleriaActoMedico() {
			// @ts-ignore
			$("#galeriaActoMedico").modal({ backdrop: "static", keyboard: false });
		},
		async changeMedicamentos() {
			let resp: any = "";
			this.medicamentos = [];
			let form = {
				categoria: this.formRecetaMedica.categoria_id,
				grupoMedicamento: this.formRecetaMedica.grupo_id,
			};

			resp = await this.axios.post("select/recetaMedica/productos", form);
			this.medicamentos = resp.data;
		},
		async fetchOptionsCies(search, loading) {
			if (search.length > 2) {
				let { data } = await this.axios.get("select/cies/" + search);
				this.cies = data;
			}
		},
		async showDiagnostico(id = 0) {
			this.limpiarDiagnostico();

			let { data } = await this.axios.get("diagnosticos/" + id);
			// obetemos el ajax del select
			let resp = await this.axios.get("select/cies/" + data.diagnostico);
			this.cies = resp.data;
			this.formDiagnostico = data;
			this.formDiagnostico.cie = resp.data[0];
		},
		async eliminarDiagnostico(id = 0) {
			await this.axios.delete("diagnosticos/" + id);
			this.listarDiagnostico();
		},
		eliminarComponente(index) {
			let arrFormFormula = JSON.parse(JSON.stringify(this.arrFormFormula));
			arrFormFormula.splice(index, 1);
			this.arrFormFormula = arrFormFormula;
		},
		limpiarDiagnostico() {
			this.formDiagnostico = {
				cie_id: "",
				cie: "",
				ubicacion_corporal_id: "",
				historia_clinica_id: "",
				cod_cie: "",
				diagnostico: "",
				especifico: "-",
				severidad: "",
				ubicacion: "",
			};
		},
		async listarDiagnostico() {
			let { data } = await this.axios.get(
				"diagnosticos/historiaClinica/" + this.historia_clinica_id
			);
			this.diagnosticos = data;
		},
		async submitDiagnostico(e) {
			e.preventDefault();
			let data: any = "";
			let resp: any = "";

			if (this.formDiagnostico.ubicacion_corporal_id > 0) {
				let ubicacion = this.ubicacionCorporals.find(
					(item) => item.id == this.formDiagnostico.ubicacion_corporal_id
				);
				this.formDiagnostico.ubicacion = ubicacion.label;
			} else {
				this.formDiagnostico.ubicacion = "";
			}

			this.formDiagnostico.cie_id = this.formDiagnostico.cie.id;
			this.formDiagnostico.cod_cie = this.formDiagnostico.cie.codigo;
			this.formDiagnostico.diagnostico = this.formDiagnostico.cie.nombre;
			this.formDiagnostico.historia_clinica_id = this.historia_clinica_id;

			try {
				if (this.formDiagnostico.id > 0) {
					resp = await this.axios.put(
						"diagnosticos/" + this.formDiagnostico.id,
						this.formDiagnostico
					);
					data = resp.data;
				} else {
					resp = await this.axios.post("diagnosticos", this.formDiagnostico);
					data = resp.data;
				}
				this.listarDiagnostico();
				this.limpiarDiagnostico();
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.catchAction(error);
			}
		},
		limpiarAntecedente() {
			this.formAntecedente = {
				cie_id: "",
				cie: "",
				ubicacion_corporal_id: "",
				cod_cie: "",
				diagnostico: "",
				especifico: "-",
				severidad: "",
				ubicacion: "",
				detalle: ""
			};
		},
		async listarAntecedente() {
			let { data } = await this.axios.get(
				"pacientes/" + this.form.paciente_id + "/antecedentes"
			);
			this.antecedentes = data;
		},
		async eliminarAntecedente(id) {
			this.cargar = true;
			await this.axios.delete("antecedentes/" + id);
			this.listarAntecedente();
			this.cargar = false;
		},
		async submitAntecedente(e) {
			e.preventDefault();
			let data: any = "";
			let resp: any = "";

			if (this.formAntecedente.ubicacion_corporal_id > 0) {
				let ubicacion = this.ubicacionCorporals.find(
					(item) => item.id == this.formAntecedente.ubicacion_corporal_id
				);
				this.formAntecedente.ubicacion = ubicacion.label;
			} else {
				this.formAntecedente.ubicacion = "";
			}
			this.formAntecedente.paciente_id = this.form.paciente_id;
			this.formAntecedente.cie_id = this.formAntecedente.cie.id;
			this.formAntecedente.cod_cie = this.formAntecedente.cie.codigo;
			this.formAntecedente.diagnostico = this.formAntecedente.cie.nombre;

			try {
				resp = await this.axios.post("antecedentes", this.formAntecedente);
				data = resp.data;

				this.listarAntecedente();
				this.limpiarAntecedente();
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.catchAction(error);
			}
		},
		async listarAlergias() {
			let { data } = await this.axios.get(
				"pacientes/" + this.form.paciente_id + "/alergias"
			);
			this.pacAlergias = data;
		},
		async eliminarAlergia(id) {
			this.cargar = true;
			await this.axios.delete("alergia_pacientes/" + id);
			this.listarAlergias();
			this.cargar = false;
		},
		async submitAlergias(e) {
			let resp: any = "";
			if (!this.formAlergia.alergia) {
				return false;
			}
			this.cargar = true;

			try {
				this.formAlergia.paciente_id = this.form.paciente_id;
				this.formAlergia.alergia_id = this.formAlergia.alergia.id;

				let { data } = await this.axios.post(
					"paciente/alergia",
					this.formAlergia
				);

				this.formAlergia.alergia = "";
				this.formAlergia.fecha = "";
				this.formAlergia.detalle = "";				
				this.listarAlergias();

				if (data.alergia) {
					resp = await this.axios.get("select/alergias");
					this.alergias = resp.data;
				}
				this.cargar = false;
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async listarRelatoCronologico() {
			let { data } = await this.axios.get(
				"relatoCronologicos/cita/" + this.historia_clinica_id
			);
			this.relatoCronologicos = data;
		},
		async showRelatoCronologico(id = 0) {
			this.limpiarRelatoCronologico();
			let { data } = await this.axios.get("relatoCronologicos/" + id);
			this.formRelatoCronologicos = data;
		},
		async eliminarRelatoCronologico(id = 0) {
			await this.axios.delete("relatoCronologicos/" + id);
			this.listarRelatoCronologico();
		},
		limpiarRelatoCronologico() {
			this.formRelatoCronologicos = {
				detalle: "",
			};
		},
		async submitRelatoCronologicos(e) {
			e.preventDefault();
			let data: any = "";
			let resp: any = "";

			this.formRelatoCronologicos.historia_clinica_id =
				this.historia_clinica_id;

			try {
				if (this.formRelatoCronologicos.id > 0) {
					resp = await this.axios.put(
						"relatoCronologicos/" + this.formRelatoCronologicos.id,
						this.formRelatoCronologicos
					);
					data = resp.data;
				} else {
					resp = await this.axios.post(
						"relatoCronologicos",
						this.formRelatoCronologicos
					);
					data = resp.data;
				}
				this.listarRelatoCronologico();
				this.limpiarRelatoCronologico();
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.catchAction(error);
			}
		},
		async listarParametro() {
			let { data } = await this.axios.get(
				"parametros/cita/" + this.historia_clinica_id
			);
			this.parametros = data;
		},
		async showParametro(id = 0) {
			this.limpiarRelatoCronologico();
			let { data } = await this.axios.get("parametros/" + id);
			this.formParametro = data;
		},
		async eliminarParametro(id = 0) {
			await this.axios.delete("parametros/" + id);
			this.listarParametro();
		},
		limpiarParametro() {
			this.formParametro = {
				detalle: "",
			};
		},
		async submitParametros(e) {
			e.preventDefault();
			let data: any = "";
			let resp: any = "";

			this.formParametro.historia_clinica_id = this.historia_clinica_id;

			try {
				if (this.formParametro.id > 0) {
					resp = await this.axios.put(
						"parametros/" + this.formParametro.id,
						this.formParametro
					);
					data = resp.data;
				} else {
					resp = await this.axios.post("parametros", this.formParametro);
					data = resp.data;
				}
				this.listarParametro();
				this.limpiarParametro();
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.catchAction(error);
			}
		},
		async listarExamenPreferencial() {
			let { data } = await this.axios.get(
				"examenPreferencials/cita/" + this.historia_clinica_id
			);
			this.examenPreferencials = data;
		},
		async showExamenPreferencial(id = 0) {
			this.limpiarExamenPreferencial();
			let { data } = await this.axios.get("examenPreferencials/" + id);
			this.formExamenPreferencials = data;
		},
		async eliminarExamenPreferencial(id = 0) {
			await this.axios.delete("examenPreferencials/" + id);
			this.listarExamenPreferencial();
		},
		limpiarExamenPreferencial() {
			this.formExamenPreferencials = {
				detalle: "",
			};
		},
		async submitExamenPreferencials(e) {
			e.preventDefault();
			let data: any = "";
			let resp: any = "";
			this.cargar = true;

			this.formExamenPreferencials.historia_clinica_id =
				this.historia_clinica_id;

			try {
				if (this.formExamenPreferencials.id > 0) {
					resp = await this.axios.put(
						"examenPreferencials/" + this.formExamenPreferencials.id,
						this.formExamenPreferencials
					);
					data = resp.data;
				} else {
					resp = await this.axios.post(
						"examenPreferencials",
						this.formExamenPreferencials
					);
					data = resp.data;
				}
				this.listarExamenPreferencial();
				this.limpiarExamenPreferencial();
				this.cargar = false;
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		showFormula() {
			this.formFormula = {
				formulaComponente: "",
				porcentaje: "",
				presentacion: "",
				medida: "",
				undMedida: "",
			};
			// @ts-ignore
			$("#modalFormula").modal({ backdrop: "static", keyboard: false });
		},
		submitFormula() {
			if (!this.formFormula.formulaComponente || !this.formFormula.porcentaje) {
				return false;
			}
			let item =
				this.formFormula.formulaComponente.nombre +
				" " +
				this.formFormula.porcentaje +
				"%";

			this.arrFormFormula.push(item);

			this.arrFormFormula = this.arrFormFormula.sort((a, b) => {
				if (a > b) {
					return 1;
				}
				if (a < b) {
					return -1;
				}
				return 0;
			});
			this.formFormula = { formulaComponente: "", porcentaje: "" };
		},
		submitFormulaProceso() {
			let unidadMedidas = this.unidadMedidas.find(
				(item) => item.id == this.formFormula.undMedida
			);
			let presentacions = this.presentacions.find(
				(item) => item.id == this.formFormula.presentacion
			);

			let text =
				this.arrFormFormula.join(" ") +
				" " +
				this.formFormula.medida +
				" " +
				unidadMedidas.abrev +
				" - " +
				presentacions.nombre;

			this.form.discripcion = text;
			this.medicamentos = [
				{
					id: 0,
					value: text,
				},
			];

			this.formRecetaMedica.medicamento = {
				id: 0,
				value: text,
			};
			// @ts-ignore
			$("#modalFormula").modal("hide");
		},
		limpiarRecetaMedica() {
			this.formRecetaMedica.diagnostico = "";
			this.formRecetaMedica.categoria_id = "";
			this.formRecetaMedica.grupo_medicamento_id = "";
			this.formRecetaMedica.medicamento = "";
			this.formRecetaMedica.indicaciones = "";
			this.formRecetaMedica.getmedicamento = "";
		},
		async listarRecetaMedica() {
			let { data } = await this.axios.get(
				"recetaMedicas/historiaClinica/" + this.historia_clinica_id
			);
			this.recetaMedicas = data;
		},
		async submitRecetaMedica(e) {
			e.preventDefault();
			let data: any = "";
			let resp: any = "";

			let diagnostico = this.diagnosticos.find(
				(item) => item.id == this.formRecetaMedica.diagnostico_id
			);
			this.formRecetaMedica.historia_clinica_id = this.historia_clinica_id;
			this.formRecetaMedica.diagnostico =
				diagnostico.cod_cie + " - " + diagnostico.diagnostico;
			this.formRecetaMedica.medicamento =
				this.formRecetaMedica.getmedicamento.value;
			this.formRecetaMedica.medicamento_id =
				this.formRecetaMedica.getmedicamento.id;

			try {
				if (this.formRecetaMedica.id > 0) {
					resp = await this.axios.put(
						"recetaMedicas/" + this.formRecetaMedica.id,
						this.formRecetaMedica
					);
					data = resp.data;
				} else {
					resp = await this.axios.post("recetaMedicas", this.formRecetaMedica);
					data = resp.data;
				}
				this.listarRecetaMedica();
				this.limpiarRecetaMedica();
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.catchAction(error);
			}
		},
		async showRecetaMedica(id = 0) {
			this.limpiarRecetaMedica();
			let { data } = await this.axios.get("recetaMedicas/" + id);

			let getmedicamento = {
				id: data.producto_id ?? 0,
				value: data.medicamento,
			};

			this.formRecetaMedica = data;
			this.formRecetaMedica.getmedicamento = getmedicamento;
		},
		async eliminarRecetaMedica(id = 0) {
			await this.axios.delete("recetaMedicas/" + id);
			this.listarRecetaMedica();
		},
		async submitRecursos() {
			this.cargar = true;
			let formData = new FormData();
			formData.append(
				"hc_tipo_archivo_id",
				this.formRecurso.hc_tipo_archivo_id
			);
			formData.append("file", this.formRecurso.file);
			formData.append("historia_clinica_id", this.historia_clinica_id);
			await this.axios.post("hcdocumentos", formData);

			let file: any = document.getElementById("fileRecursoDocumento");
			file.value = "";
			this.listarRecursos();
			this.cargar = false;
		},
		async listarRecursos() {
			let { data } = await this.axios.get(
				"hcdocumentos/historiaClinica/" + this.historia_clinica_id
			);
			this.hcdocumentos = data;
		},
		async deleteRecursos(url) {
			await this.axios.post("hcdocumentos/galeria_eliminar", { url });
			this.listarRecursos();
		},
		async obtenerInterconsulta() {
			let { data } = await this.axios.get(
				"interconsultas/historiaClinica/" + this.historia_clinica_id
			);
			if (data) {
				this.formInterconsulta = data;
			}
		},
		async submitInterconsulta(e) {
			e.preventDefault();
			let resp: any = "";
			this.cargartabs = true;
			this.formInterconsulta.historia_clinica_id = this.historia_clinica_id;
			try {
				if (this.formInterconsulta.id > 0) {
					resp = await this.axios.put(
						"interconsultas/" + this.formInterconsulta.id,
						this.formInterconsulta
					);
				} else {
					resp = await this.axios.post(
						"interconsultas",
						this.formInterconsulta
					);
				}
				this.formInterconsulta = resp.data.data;
				this.cargartabs = false;
				// @ts-ignore
				new PNotify({
					text: "registrado exitosamente",
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.cargartabs = false;
				this.catchAction(error);
			}
		},
		async obtenerBiopsia() {
			let { data } = await this.axios.get(
				"estudioAnomapatologicos/historiaClinica/" + this.historia_clinica_id
			);
			if (data) {
				this.formBiopsia = data;
			}
		},
		async submitBiopsia(e) {
			e.preventDefault();
			let resp: any = "";
			this.cargartabs = true;
			this.formBiopsia.historia_clinica_id = this.historia_clinica_id;
			try {
				if (this.formBiopsia.id > 0) {
					resp = await this.axios.put(
						"estudioAnomapatologicos/" + this.formBiopsia.id,
						this.formBiopsia
					);
				} else {
					resp = await this.axios.post(
						"estudioAnomapatologicos",
						this.formBiopsia
					);
				}
				this.formBiopsia = resp.data.data;
				this.cargartabs = false;
				// @ts-ignore
				new PNotify({
					text: "registrado exitosamente",
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.cargartabs = false;
				this.catchAction(error);
			}
		},
		async obtenerExImagenes() {
			let { data } = await this.axios.get(
				"examenesImagens/historiaClinica/" + this.historia_clinica_id
			);
			if (data) {
				this.formExImagenes = data;
			}
		},
		async submitExImagenes(e) {
			e.preventDefault();
			let resp: any = "";
			this.cargartabs = true;
			this.formExImagenes.historia_clinica_id = this.historia_clinica_id;
			try {
				if (this.formExImagenes.id > 0) {
					resp = await this.axios.put(
						"examenesImagens/" + this.formExImagenes.id,
						this.formExImagenes
					);
				} else {
					resp = await this.axios.post("examenesImagens", this.formExImagenes);
				}
				this.formExImagenes = resp.data.data;
				this.cargartabs = false;
				// @ts-ignore
				new PNotify({
					text: "registrado exitosamente",
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.cargartabs = false;
				this.catchAction(error);
			}
		},
		async listarLabClinicos() {
			let { data } = await this.axios.get(
				"laboratorioClinicos/historiaClinica/" + this.historia_clinica_id
			);
			this.formLabClinicos.exLaboratorios = data;
			this.formLabClinicos.examenes = [];
			this.exLaboratorios.forEach((laboratorios) => {
				laboratorios.examenes.forEach((item) => {
					let examen = data.find(
						(examen) => examen.examen_clinico_id == item.id
					);
					if (examen) {
						item.id = examen.examen_clinico_id;
						item.text = examen.text;
						this.formLabClinicos.examenes.push(examen.examen_clinico_id);
					}
				});
			});
		},
		async submitLabClinicos() {
			this.cargartabs = true;
			this.formLabClinicos.historia_clinica_id = this.historia_clinica_id;
			this.formLabClinicos.exLaboratorios = [];

			this.exLaboratorios.forEach((laboratorios) => {
				laboratorios.examenes.forEach((item) => {
					let data = this.formLabClinicos.examenes.find(
						(examen) => examen == item.id
					);
					if (data) {
						this.formLabClinicos.exLaboratorios.push({
							id: data,
							text: item.text,
						});
					}
				});
			});
			try {
				await this.axios.post(
					"laboratorioClinicos/historiaClinica",
					this.formLabClinicos
				);
				this.cargartabs = false;
				// @ts-ignore
				new PNotify({
					text: "registrado exitosamente",
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.cargartabs = false;
				this.catchAction(error);
			}
		},
		limpiarProtocolo() {
			this.formProtocolo.id = "";
			this.formProtocolo.tratamiento_id = "";
			this.formProtocolo.ubicacion_id = "";
			this.formProtocolo.diametro_id = "";
			this.formProtocolo.sesion = "";
			this.formProtocolo.descuento = "0.00";
		},
		async listarProtocolo() {
			let { data } = await this.axios.get(
				"protocolos/historiaClinica/" + this.historia_clinica_id
			);
			this.protocolos = data;
		},
		async showProtocolo(id) {
			this.limpiarProtocolo();
			let { data } = await this.axios.get("protocolos/" + id);
			this.formProtocolo = data;
		},
		async eliminarProtocolo(id) {
			this.cargartabs = true;
			let { data } = await this.axios.delete("protocolos/" + id);
			this.listarProtocolo();
			this.cargartabs = false;
		},
		async submitProtocolo(e) {
			e.preventDefault();
			let data: any = "";
			let resp: any = "";
			let diagnostico: any = "";
			this.formProtocolo.diagnostico = "";

			if (this.formProtocolo.diagnostico_id) {
				diagnostico = this.diagnosticos.find(
					(item) => item.id == this.formProtocolo.diagnostico_id
				);
				this.formProtocolo.diagnostico =
					diagnostico.cod_cie + " - " + diagnostico.diagnostico;
			}

			let tratamiento = this.tratamientos.find(
				(item) => item.id == this.formProtocolo.tratamiento_id
			);
			let diametro = this.diametros.find(
				(item) => item.id == this.formProtocolo.diametro_id
			);
			let ubicacion = this.traUbiCorporals.find(
				(item) => item.id == this.formProtocolo.ubicacion_id
			);

			this.formProtocolo.tratamiento = tratamiento.nombre;
			this.formProtocolo.ubicacion = ubicacion.label;
			this.formProtocolo.diametro = diametro.diametro;
			this.formProtocolo.historia_clinica_id = this.historia_clinica_id;
			this.formProtocolo.paciente_id = this.form.paciente_id;

			this.cargartabs = true;
			try {
				if (this.formProtocolo.id > 0) {
					resp = await this.axios.put(
						"protocolos/" + this.formProtocolo.id,
						this.formProtocolo
					);
					data = resp.data;
				} else {
					resp = await this.axios.post("protocolos", this.formProtocolo);
					data = resp.data;
				}
				this.listarProtocolo();
				this.limpiarProtocolo();
				this.cargartabs = false;
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.cargartabs = false;
				this.catchAction(error);
			}
		},
		async submit() {
			this.cargar = false;

			try {
				let { data } = await this.axios.put(
					"historiaClinicas/" + this.form.id,
					this.form
				);
				this.form = data.data;

				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: "success",
					styling: "bootstrap3",
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		cerrarHistoria() {
			let diagnostico = "";
			let protocolo = "";
			let receta = "";
			let exImagen = "";
			let exClinico = "";
			let laboratorio = "";
			let interconsulta = "";

			this.diagnosticos.forEach((item) => {
				diagnostico += item.diagnostico + " " + item.cod_cie + "<br>";
			});
			this.protocolos.forEach((item) => {
				protocolo +=
					item.tratamiento + " " + item.ubicacion + " " + item.sesion + "<br>";
			});
			this.recetaMedicas.forEach((item) => {
				receta += item.medicamento + " " + item.indicaciones + "<br>";
			});
			this.formLabClinicos.examenes.forEach((item) => {
				this.exLaboratorios.forEach((examen) => {
					examen.examenes.forEach((item2) => {
						if (item2.id == item) {
							exImagen += item2.nombre + "<br>";
						}
					});
				});
			});
			this.formExImagenes.examen_clinicos.forEach((item) => {
				let item2 = this.exImagenes.find((item3) => item3.id == item);
				exClinico += item2.nombre + "<br>";
			});
			if (this.formExImagenes.otros) {
				exClinico += this.formExImagenes.otros;
			}
			if (this.formBiopsia.diagnosticoClinico) {
				laboratorio = this.formBiopsia.diagnosticoClinico;
			}
			if (this.formInterconsulta.especialidad_id) {
				let item = this.especialidads.find(
					(item) => item.id == this.formInterconsulta.especialidad_id
				);
				interconsulta = item.nombre + ": " + this.formInterconsulta.motivo;
			}

			if (diagnostico == "") {
				diagnostico = '<p class="text-danger mb-1">No hay datos</p>';
			}
			if (protocolo == "") {
				protocolo = '<p class="text-danger mb-1">No hay datos</p>';
			}
			if (receta == "") {
				receta = '<p class="text-danger mb-1">No hay datos</p>';
			}
			if (exImagen == "") {
				exImagen = '<p class="text-danger mb-1">No hay datos</p>';
			}
			if (exClinico == "") {
				exClinico = '<p class="text-danger mb-1">No hay datos</p>';
			}
			if (laboratorio == "") {
				laboratorio = '<p class="text-danger mb-1">No hay datos</p>';
			}
			if (interconsulta == "") {
				interconsulta = '<p class="text-danger mb-1">No hay datos</p>';
			}

			let html = `
				<table>
					<tr>
						<th> Paciente </th>
						<td> ${this.form.nomPaciente}</td>
						<th> Edad </th>
						<td> ${this.form.edad} años </td>
						<th> Tipo Atención </th>
						<td> ${this.form.tipo_atencion} </td>
					</tr>
					<tr>
						<th> Médico	</th>
						<td> ${this.form.nomMedico} </td>
						<th> Especialidad </th>
						<td> ${this.form.especialidad} </td>
					</tr>	
				</table>
				<hr>
				<table>
					<tr>
						<th>Diagnostico:</th>
						<td>${diagnostico}</td>
					</tr>
					<tr>
						<th>Protocolo:</th>
						<td>${protocolo}</td>
					</tr>
					<tr>
						<th>Receta:</th>
						<td>${receta}</td>
					</tr>
					<tr>
						<th>Ex Laboratorio:</th>
						<td>${exImagen}</td>
					</tr>
					<tr>
						<th>Ex Imagenes:</th>
						<td>${exClinico}</td>
					</tr>
					<tr>
						<th>Estudio anomo:</th>
						<td>${laboratorio}</td>
					</tr>
					<tr>
						<th>Interconsulta:</th>
						<td>${interconsulta}</td>
					</tr>
				</table>
				<hr>
				<h4 class="text-center">Confirma que desea cerrar la historia?</h4>`;
			this.$swal
				.fire({
					title: "Confirmar el cierre de la historia?",
					text: "Estas seguro de cerrar la historia clinica",
					html: html,
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					cancelButtonText: "Cancelar",
					confirmButtonText: "Si, Cerrar Historia",
					showLoaderOnConfirm: true,
					preConfirm: (data) => {
						return this.axios
							.patch("historiaClinicas/" + this.form.id + "/cerrar")
							.then((result) => {
								return result.data;
							})
							.catch((err) => {
								this.$swal.showValidationMessage(`Request failed: ${err}`);
							});
					},
					allowOutsideClick: () => !this.$swal.isLoading(),
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.$swal.fire("Estado", result.value.msg, "success");
						this.$router.push({ name: "Atencion" });
					}
				});
		},
		async modalPSeguimiento(item) {
			this.itemProtocolo = item;
			this.formPS = {
				sesion: 1,
				comentario: '',
			}
			this.listarPS(item);
			// @ts-ignore
			$("#modalProtocoloSeguimiento").modal({ backdrop: "static", keyboard: false });
		},
		async showPS(id) {
			let {data} = await this.axios.get('protocoloSeguimientos/' + id);
			this.formPS = data;
		},
		async listarPS() {
			try {
				let { data } = await this.axios.get(`protocoloSeguimiento/protocolo/${this.itemProtocolo.id}`);
				this.seguimientoProtocolos = data;
			} catch (error) {
				this.catchAction(error);
			}
		},
		async submitProtocoloSeguimiento(e) {
			e.preventDefault();
			let data: any = '';
			let resp: any = '';

			this.cargarFormulario = true;

			try {
				this.formPS.protocolo_id = this.itemProtocolo.id;
				this.formPS.historia_clinica_id = this.historia_clinica_id;
				this.formPS.paciente_id = this.form.paciente_id;
				this.formPS.fecAtencion = this.form.fecha;

				if (this.formPS.id > 0) {
					resp = await this.axios.put('protocoloSeguimientos/' + this.formPS.id, this.formPS);
					data = resp.data;
				} else {
					resp = await this.axios.post('protocoloSeguimientos', this.formPS);
					data = resp.data;
				}

				this.cargarFormulario = false;
				this.listarPS();

				/* obtenermos los paciente */
				resp = await this.axios.get("protocolos/paciente/" + this.form.paciente_id);
				this.protocolosSeguimiento = resp.data;

				this.formPS = {
					sesion: 1,
					detalle: '',
				}
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargarFormulario = false;
				this.catchAction(error);
			}
		},
	},
	filters: {
		moneda: function (value) {
			if (!value) return "";
			value = parseFloat(value);
			return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
		},
		monedaDes: function (value) {
			if (!value) return "";
			value = parseFloat(value);
			return value.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, "$&,");
		},
	},
	mounted() {
		// Set the initial number of items
		if (this.$route.params.id) {
			this.id = this.$route.params.id;
			this.acto_medico_id = this.$route.params.acto_medico_id;
			this.historia_clinica_id = this.$route.params.historia_clinica_id;
			this.init();
		}
	},
	destroyed() {
		if (!this.inicioPagina) {
			window.location.reload();
		}
	},
};
