
import PageTitle from "../../components/PageTitle.vue";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar: false,
			consultaTable: true,
			title: "Cliente",
			titleForm: "Cliente",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "index", label: "N°", sortable: false },
				{ key: "acciones", label: "Acciones", sortable: false },
				{ key: "tipo_documento", label: "Tipo Doc", sortable: true },
				{ key: "numDocumento", label: "Num°", sortable: true },
				{ key: "razonSocial", label: "Razon Social o Nombre Completos", sortable: true },
				{ key: "email", label: "Correo", sortable: true },
				{ key: "movil", label: "Movil", sortable: true },
				{ key: "fijo", label: "Fijo", sortable: true },
				{ key: "ubigeo", label: "Ubigeo", sortable: true },
				{ key: "direccionFiscal", label: "Direccion", sortable: true },
				{ key: "estado", label: "Estado", sortable: true }],
			items: [],
			filter: null,
			departamentos: [],
			provincias: [],
			distritos: [],
			tipoDocumentos: [],
			form: {
				tipo_documento_id: "",
				numDocumento: "",
				razonSocial: "",
				razonComercial: "",
				direccionFiscal: "",
				email: "",
				emailOne: "",
				emailDo: "",
				movil: "",
				fijo: "",
				detraccion: "",
				detalle: "",
				department_id: "",
				province_id: "",
				district_id: "",
			},
			isBusy: false,
		};
	},
	watch: {
		async "form.department_id"(newValor, oldValor) {
			let { data } = await this.axios.get('provincias/' + newValor);
			this.provincias = data;
		},
		async "form.province_id"(newValor, oldValor) {
			let { data } = await this.axios.get('distritos/' + newValor);
			this.distritos = data;
		}
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
		numUltimo() {
			let end = this.totalRows;
			let itemsPerPage = this.currentPage * this.perPage;

			if (itemsPerPage < this.totalRows) {
				end = itemsPerPage;
				if (end > this.totalRows) {
					end = this.totalRows;
				}
			}
			return end;
		},

	},
	methods: {
		async init() {
			this.listar();
			let resp: any = '';

			resp = await this.axios.get('departamentos');
			this.departamentos = resp.data;

			resp = await this.axios.get('select/tipoDocumentos');
			this.tipoDocumentos = resp.data;
		},
		async listar() {
			this.isBusy = true;
			try {
				let url = 'dt_clientes';
				if (this.filter) {
					url = url + '/' + this.filter;
				}
				let { data } = await this.axios.get(url);
				this.items = data;
				this.totalRows = this.items.length;
				if (this.totalRows > 0) {
					this.consultaTable = true;
				}
				this.isBusy = false;
			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		ir(id = 0) {
			this.$router.push({ name: 'ShowPaciente', params: { id: id } });
		},
		async show(id = 0) {
			this.cargar = false;
			try {
				this.reset();
				if (id > 0) {
					let { data } = await this.axios.get('clientes/' + id);
					this.form = data;
				}
				// @ts-ignore
				$(".modal").modal({ backdrop: 'static', keyboard: false })
			} catch (error) {
				if (error.response) {
					let resp = error.response;
					if (resp.status == 404) {
						// @ts-ignore
						return new PNotify({
							text: 'Error 404',
							type: 'error',
							styling: 'bootstrap3'
						});
					}
				}
				// @ts-ignore
				return new PNotify({
					text: error.message,
					type: 'error',
					styling: 'bootstrap3'
				});
			}
		},
		async state(item, state) {
			let title = 'Activar?';
			let confirmButtonText = 'Si, Activar.';
			if (state == 0) {
				title = 'Desactivar?';
				confirmButtonText = 'Si, Desactivar.';
			}

			this.$swal.fire({
				title: title,
				text: item.fullname,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: confirmButtonText,
				showLoaderOnConfirm: true,
				preConfirm: (login) => {
					return this.axios.patch('clientes/' + item.id + '/state', { isActive: state })
						.then((result) => {
							return result.data;
						}).catch((err) => {
							this.$swal.showValidationMessage(
								`Request failed: ${err}`
							)
						});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.listar();
				}
			});
		},
		reset() {
			this.form = {
				tipo_documento_id: "",
				numDocumento: "",
				razonSocial: "",
				razonComercial: "",
				direccionFiscal: "",
				email: "",
				emailOne: "",
				emailDo: "",
				movil: "",
				fijo: "",
				detraccion: "",
				detalle: "",
				department_id: "",
				province_id: "",
				district_id: "",
			}
		},
		async submit(e) {
			e.preventDefault();
			let data: any = '';
			this.cargar = true;
			try {
				if (this.form.id > 0) {
					let resp = await this.axios.put('clientes/' + this.form.id, this.form);
					data = resp.data;
				} else {
					let resp = await this.axios.post('clientes', this.form);
					data = resp.data;
				}
				this.listar();
				this.cargar = false;
				// @ts-ignore
				$(".modal").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
			if (this.totalRows == 0 && this.consultaTable) {
				this.consultaTable = false;
				this.listar();
			}
			if (this.filter == '') {
				this.listar();
			}
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
