import { render, staticRenderFns } from "./Enfermeria.vue?vue&type=template&id=11217edd&scoped=true"
import script from "./Enfermeria.vue?vue&type=script&lang=ts"
export * from "./Enfermeria.vue?vue&type=script&lang=ts"
import style0 from "./Enfermeria.vue?vue&type=style&index=0&id=11217edd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11217edd",
  null
  
)

export default component.exports