
import PageTitle from "../../components/PageTitle.vue";
import { DateTime } from "luxon";

export default {
	components: { PageTitle },
	data() {
		return {
			cargarLiberacion: false,
			cargarAnulacion: false,
			cargarCita: false,
			cargarAnular: false,
			title: "Cita",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "acciones", 			label: "Acciones",		sortable: false },
				{ key: "fechaHora", 		label: "Fecha - Hora", 	sortable: true },
				{ key: "numDocumento", 		label: "Num. Doc", 		sortable: true },
				{ key: "paciente", 			label: "Paciente", 		sortable: true },
				{ key: "doctor", 			label: "Medico", 		sortable: true },
				{ key: "especialidad", 		label: "Especialidad", 	sortable: true },
				{ key: "consultorio", 		label: "Consultorio", 	sortable: true },
				{ key: "detalleAtencion", 	label: "Atencion", 		sortable: true },
				{ key: "estado", 			label: "Estado", 		sortable: true }],
			items: [],
			filter: null,
			tipoDocumentos:[],
			formFecha: {
				sede_id:'',
				finicio:DateTime.now().toISODate(),
				ffin:'',
			},			
			pacienteDisabled: true,
			tipoAtencions: [],
			tratamiento_ubicacion_corporals: [],
			formCita:{
				id: '',
				doctor: '',
				fecha: '',
				hora: '',
				consultorio: '',
				tipo_documento_id: '',
				numDocumento: '',
				apellidoPaterno: '',
				apellidoMaterno: '',
				nombres: '',
				telefono: '',
				correo: '',
				tratamientoUbicacionCorporal: '',
				tratamiento_ubicacion_corporal_id: '',
				indCitaTelefonica: 0,
				proximo: '',
				interesado: '',
				observacion: '',
				paciente_id: '',
				estado: '',
			},
			formLiberacion:{
				programacion_cita_id: '',
				fechaHora: '',
				paciente: '',
				detalle: ''
			},
			formAnular:{
				programacion_cita_id: '',
				fechaHora: '',
				paciente: '',
				detalle: ''
			},
		};
	},
	computed: {
		user(){
			return this.$store.state.user;  
		},
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	methods: {
		colorCita(estado, disponible, web) {
			let clase = '';
			switch (estado) {
				case 1:
					clase = 'bg-Atendido text-white';
					break;
				case 2:
					clase = 'bg-Espera text-white';
					break;
				case 3:
					clase = 'bg-Confirmado text-white';
					if (web) {
						clase = 'bg-Reserva-Web text-white';
					}
					break;
				case 4:
					clase = 'bg-Reservado text-white';
					if (disponible == 0) {
						clase = 'bg-No-Disponible text-white';
					}
					break;
				case 5:
					clase = 'bg-Disponible';
					if (disponible == 0) {
						clase = 'bg-No-Disponible text-white';
					}
					break;
				case 6:
					clase = 'bg-Bloqueado text-white';
					break;
				default:
					clase = 'bg-No-Disponible text-white';
					break;
			}
			return clase;
		},
		citaTiempo(){
			if(this.formCita.tratamientoUbicacionCorporal.duracion){
				this.formCita.intervalo = this.formCita.tratamientoUbicacionCorporal.duracion;
			}			 
		},
		async init(){
			this.formFecha.sede_id = this.user.sede_id;
			let resp:any = '';

			resp = await this.axios.get('select/tipoDocumentos');
			this.tipoDocumentos = resp.data;

			resp = await this.axios.get('select/tipoAtencions');
			this.tipoAtencions = resp.data;
			
			resp = await this.axios.get('select/tratamientoUbicacionCorporals');
			this.tratamiento_ubicacion_corporals = resp.data;

			this.listar();
		},
		resetCita() {
			this.formCita = {
				id: '',
				doctor: '',
				fecha: '',
				hora: '',
				consultorio: '',
				tipo_documento_id: '',
				numDocumento: '',
				apellidoPaterno: '',
				apellidoMaterno: '',
				nombres: '',
				telefono: '',
				correo: '',
				tratamientoUbicacionCorporal: '',
				tratamiento_ubicacion_corporal_id: '',
				indCitaTelefonica: 0,
				proximo: '',
				interesado: '',
				observacion: '',
				paciente_id: '',
				estado: '',
			}
		},
		async modificarCita(id = 0){
			try {
				this.resetCita();
				if(id > 0){
					let {data} = await this.axios.get('programacionCitas/' + id);
					this.formCita = data;

					if(this.formCita.tratamiento_ubicacion_corporal_id){
						let tratamiento = this.tratamiento_ubicacion_corporals.find(item =>  item.id == this.formCita.tratamiento_ubicacion_corporal_id);
						this.formCita.tratamientoUbicacionCorporal = tratamiento;
					}

				}
				// @ts-ignore
				$("#modalCrearCita").modal({backdrop: 'static', keyboard: false})
			} catch (error) {
				this.catchAction(error);
			}	
		},
		async listar(){
			try {
				let {data} = await this.axios.post('citas/consulta', this.formFecha);
				this.items = data;	
				this.totalRows = this.items.length;	
			} catch (error) {
				this.catchAction(error);
			}
		},
		async submitConsulta(e){
			e.preventDefault();
			this.listar();
		},
		async consultaDoc(){
			if(this.formCita.tipo_documento_id && this.formCita.numDocumento.length > 7){
				let { data } = await this.axios.get('paciente/consulta/'+this.formCita.tipo_documento_id+'/'+this.formCita.numDocumento);
				if(data.id > 0){
					this.formCita.apellidoPaterno 	= data.apellidoPaterno;
					this.formCita.apellidoMaterno 	= data.apellidoMaterno;
					this.formCita.nombres 			= data.nombres;
					this.formCita.telefono 			= data.telefono;
					this.formCita.correo 			= data.correo;
					this.formCita.paciente_id		= data.id;
				}else{
					this.formCita.paciente_id		= '';
				}
			}
		},
		crearCita(item){
			this.formCita.id 				= item.id;
			this.formCita.doctor 			= item.doctor;
			this.formCita.consultorio 		= item.consultorio;
			this.formCita.fecha 			= item.fecha;
			this.formCita.hora 				= item.hora;
			this.formCita.tipo_documento_id = '';
			this.formCita.numDocumento 		= '';
			this.formCita.apellidoPaterno 	= '';
			this.formCita.apellidoMaterno 	= '';
			this.formCita.nombres 			= '';
			this.formCita.telefono 			= '';
			this.formCita.correo 			= '';
			// @ts-ignore
			$("#modalCrearCita").modal({backdrop: 'static', keyboard: false})
		},
		liberarCita(item){
			this.formLiberacion.detalle = '';
			this.formLiberacion.fechaHora = item.fechaHora;
			this.formLiberacion.programacion_cita_id = item.id;
			this.formLiberacion.paciente = item.paciente;
			// @ts-ignore
			$("#modalLiberar").modal({backdrop: 'static', keyboard: false})
		},
		anularCita(item){
			this.formAnular.detalle = '';
			this.formAnular.fechaHora = item.fechaHora;
			this.formAnular.programacion_cita_id = item.id;
			this.formAnular.paciente = item.paciente;
			// @ts-ignore
			$("#modalAnular").modal({backdrop: 'static', keyboard: false})
		},
		async submitCita(e){
			e.preventDefault();
			
			if (!this.formCita.tipo_documento_id || !this.formCita.numDocumento || !this.formCita.apellidoPaterno || !this.formCita.apellidoMaterno || !this.formCita.nombres) {
				// @ts-ignore
				return new PNotify({
					text: 'Faltan datos del paciente',
					type: 'warning',
					styling: 'bootstrap3'
				});
			}

			if (this.formCita.tipo_atencion_id == 3) {
				if (!this.formCita.tratamientoUbicacionCorporal) {
					// @ts-ignore
					return new PNotify({
						text: 'Faltan seleccionar tratamiento',
						type: 'warning',
						styling: 'bootstrap3'
					});
				}
				this.formCita.detalleAtencion 	= this.formCita.tratamientoUbicacionCorporal.name;
				this.formCita.tratamiento_id 	= this.formCita.tratamientoUbicacionCorporal.tratamiento_id;
				this.formCita.tratamiento_ubicacion_corporal_id = this.formCita.tratamientoUbicacionCorporal.id;				
			} else {
				let item = this.tipoAtencions.find(item => item.id == this.formCita.tipo_atencion_id);
				this.formCita.detalleAtencion = item.nombre;
				this.formCita.tratamiento_id  = '';
				this.formCita.tratamiento_ubicacion_corporal_id  = '';
			}

			this.cargarCita = true;
			try {
				let {data} 	= await this.axios.patch('citas/updateCita/'+this.formCita.id , this.formCita);
				// @ts-ignore
				$("#modalCrearCita").modal('hide');
				this.listar();
				this.cargarCita = false;
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargarCita = false;				
				this.catchAction(error);	
			} 
		},
		async submitLiberacion(e){
			e.preventDefault();
			this.cargarLiberacion = true;
			try {				
				let {data} 	= await this.axios.post('programacionObservacions', this.formLiberacion);	
				this.listar();				
				this.cargarLiberacion = false;
				// @ts-ignore
				$("#modalLiberar").modal('hide');
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargarLiberacion = false;
				this.catchAction(error);				
			}
		},
		async submitAnular(e){
			e.preventDefault();
			this.cargarAnulacion = true;
			try {				
				let {data} 	= await this.axios.post('programacionAnular', this.formAnular);	
				this.listar();				
				this.cargarAnulacion = false;
				// @ts-ignore
				$("#modalAnular").modal('hide');
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargarAnulacion = false;
				this.catchAction(error);				
			}
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
