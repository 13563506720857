
import PageTitle from "../../components/PageTitle.vue";
import SetFecha from "../../components/SetFecha.vue";

export default {
	components: { PageTitle, SetFecha },
	data() {
		return {
			cargar: false,
			cargarHistoria: false,
			title: "Paciente",
			paises: [],
			departamentos: [],
			provincias: [],
			distritos: [],
			sexos: [],
			estadoCivils: [],
			tipoDocumentos: [],
			origenClientes: [],
			medicos: [],
			exLaboratorios: [],
			especialidads: [],
			condiciones: [],
			exImagenes: [],
			paciente_id:'',
			form: {
				tipo_documento_id: 1,
				numDocumento: "",
				nombres: "",
				apellidoPaterno: "",
				apellidoMaterno: "",
				fechaNacimiento: "",
				correo: "",
				telefono: "",
				fijo: "",
				direccion: "",
				ocupacion: "",
				department_id: "",
				province_id: "",
				district_id: "",
				estado_civil_id: "",
				sexo_id: "",
				country_id: 1,
				origin_id:"",
				medico_id:"",
			},	
			tabs: {
				tab1: false,
				tab2: false,
				tab3: false
			},		
			HSperPage: 10,
			HSfilter: null,
			HStotalRows: 1,
			HScurrentPage: 1,
			HSitems: [],
			HSfields: [
				{ key: "index", label: "N°" },
				{ key: "numero", label: "Ac.Médico" },
				{ key: "medico", label: "Medico" },
				{ key: "fecha", label: "Fec.Atención" },
				{ key: "tipo_atencion", label: "T.Atención" },
				{ key: "protocolo", label: "Cotización" },
				{ key: "receta_medica", label: "Receta" },
				{ key: "laboratorios_clinico", label: "Ex.Laboratorio" },
				{ key: "examenes_imagen", label: "Ex.Imagenes" },
				{ key: "estudio_anomapatologico", label: "Est.Anomapatologico" },
				{ key: "interconsulta", label: "Interconsulta" },
				{ key: "documentos", label: "Galería" },,
			],
			FTperPage: 10,
			FTfilter: null,
			FTtotalRows: 1,
			FTcurrentPage: 1,
			FTitems: [],
			FTfields: [
				{ key: "index", 				label: "N°",		sortable: false },
				{ key: "fechaEmision", 			label: "Emision", 	sortable: true },
				{ key: "cliente", 				label: "Cliente", 	sortable: true },
				{ key: "numero", 				label: "Numero", 	sortable: true },
				{ key: "notasCD", 				label: "Notas", 	sortable: true },
				{ key: "moneda", 				label: "Moneda", 	sortable: true },
				{ key: "mtoOperGravadas", 		label: "T.Gravado", sortable: true },
				{ key: "mtoImpVenta", 			label: "Total", 	sortable: true },
				{ key: "rutaXml", 				label: "XML", 		sortable: true },
				{ key: "rutaCDR", 				label: "CDR", 		sortable: true },
				{ key: "urlComprobante", 		label: "PDF", 		sortable: true },
				{ key: "respSunat", 			label: "Sunat",     sortable: true },
				{ key: "estado", 				label: "Estado",     sortable: true },
			],
			slide: 0,
			historiashow: {
				acto_medico: "",
				relatoCronologicos: "",
				parametros: "",
				examenPreferencials: "",
				diagnosticos: "",
				protocolos: "",
				recetaMedicas: "",
				exLaboratorios: "",
				formLabClinicos: "",
				formExImagenes: "",
				formBiopsia: "",
				formInterconsulta: "",
			},
		};
	},
	watch: {
		async "form.department_id"(newValor, oldValor) {
			let { data } = await this.axios.get('provincias/' + newValor);
			this.provincias = data;
		},
		async "form.province_id"(newValor, oldValor) {
			let { data } = await this.axios.get('distritos/' + newValor);
			this.distritos = data;
		},
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	methods: {
		async init() {
			let resp: any = '';

			this.cargar = true;

			resp = await this.axios.get('pacientes/' + this.paciente_id);
			this.form = resp.data;

			resp = await this.axios.get('departamentos');
			this.departamentos = resp.data;

			resp = await this.axios.get('countries');
			this.paises = resp.data;

			resp = await this.axios.get('select/sexos');
			this.sexos = resp.data;

			resp = await this.axios.get('select/estadoCivils');
			this.estadoCivils = resp.data;

			resp = await this.axios.get('select/tipoDocumentos');
			this.tipoDocumentos = resp.data;

			resp = await this.axios.get('select/origenCliente');
			this.origenClientes = resp.data;

			resp = await this.axios.get('select/medicos');
			this.medicos = resp.data;

			this.cargar = false;
		},
		async preCarga(num) {
			let resp: any = "";
			if(num == 2) {
				// historial
				if (this.tabs.tab2) {
					return false;
				}
				this.cargar = true;
				resp = await this.axios.get(
					"paciente/historiasClinicas/" + this.paciente_id
				);
				this.HSitems = resp.data;
				this.HStotalRows = resp.data.length;
				this.tabs.tab2 = true;
				this.cargar = false;
			}else if(num == 3) {
				// historial
				if (this.tabs.tab3) {
					//return false;
				}
				this.cargar = true;
				resp = await this.axios.get(
					"paciente/facturacion/" + this.paciente_id
				);
				this.FTitems = resp.data;
				this.FTtotalRows = resp.data.length;
				this.tabs.tab3 = true;
				this.cargar = false;
			}
		},
		async submit(e) {
			e.preventDefault();

			this.cargar = true;
			try {
				let {data} = await this.axios.put('pacientes/' + this.form.id, this.form);
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				if (error.response) {
					let resp = error.response;
					if (resp.status == 404) {
						// @ts-ignore
						return new PNotify({
							text: 'Error 404',
							type: 'error',
							styling: 'bootstrap3'
						});
					}
					if (resp.status == 422) {
						// @ts-ignore
						return new PNotify({
							text: 'Faltan datos para el registro',
							type: 'warning',
							styling: 'bootstrap3'
						});
					}
				}
				// @ts-ignore
				return new PNotify({
					text: error.message,
					type: 'error',
					styling: 'bootstrap3'
				});
			}
			this.cargar = false;
		},
		HSonFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.HStotalRows = filteredItems.length;
			this.HScurrentPage = 1;
		},
		FTonFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.FTtotalRows = filteredItems.length;
			this.FTcurrentPage = 1;
		},
		async showHistoria(item) {
			let resp: any;
			let id = item.id;

			this.historiashow = {
				id: id,
				numero: item.numero,
				medico: item.medico,
				fecha: item.fecha,
				tipo_atencion: item.tipo_atencion,
				paciente: item.paciente,
				edad: item.edad,
				especialidad: item.especialidad,
				detalleAtencion: item.detalleAtencion,
				acto_medico: "",
				relatoCronologicos: "",
				examenPreferencials: "",
				diagnosticos: "",
				protocolos: "",
				recetaMedicas: "",
				formLabClinicos: { examenes: [] },
				formExImagenes: { examen_clinicos: [] },
				formBiopsia: "",
				formInterconsulta: "",
			};
			this.cargarHistoria = true;
			if (item.acto_medico_id) {
				resp = await this.axios.get(
					"actoMedicos/historiaClinica/" + item.acto_medico_id
				);
				this.historiashow.acto_medico = resp.data;
			}

			// @ts-ignore
			$("#modalDetalle").modal({ backdrop: "static", keyboard: false });

			resp = await this.axios.get("relatoCronologicos/cita/" + id);
			this.historiashow.relatoCronologicos = resp.data;
			await this.delaySistema(0.4);

			resp = await this.axios.get("examenPreferencials/cita/" + id);
			this.historiashow.examenPreferencials = resp.data;
			await this.delaySistema(0.4);

			resp = await this.axios.get("diagnosticos/historiaClinica/" + id);
			this.historiashow.diagnosticos = resp.data;
			await this.delaySistema(0.4);

			resp = await this.axios.get("protocolos/historiaClinica/" + id);
			this.historiashow.protocolos = resp.data ? resp.data : "";
			await this.delaySistema(0.4);

			resp = await this.axios.get("recetaMedicas/historiaClinica/" + id);
			this.historiashow.recetaMedicas = resp.data ? resp.data : "";
			await this.delaySistema(0.4);

			resp = await this.axios.get("interconsultas/historiaClinica/" + id);
			this.formInterconsulta = resp.data ? resp.data : "";
			await this.delaySistema(0.4);

			resp = await this.axios.get(
				"estudioAnomapatologicos/historiaClinica/" + id
			);
			this.historiashow.formBiopsia = resp.data ? resp.data : "";

			resp = await this.axios.get("examenesImagens/historiaClinica/" + id);
			this.historiashow.formExImagenes = resp.data
				? resp.data
				: { examen_clinicos: [] };
			await this.delaySistema(0.4);

			resp = await this.axios.get("laboratorioClinicos/historiaClinica/" + id);
			if (resp.data) {
				this.exLaboratorios.forEach((laboratorios) => {
					laboratorios.examenes.forEach((item) => {
						let examen = resp.data.find(
							(examen) => examen.examen_clinico_id == item.id
						);
						if (examen) {
							item.id = examen.examen_clinico_id;
							item.text = examen.text;
							this.historiashow.formLabClinicos.examenes.push(
								examen.examen_clinico_id
							);
						}
					});
				});
			}

			this.cargarHistoria = false;
		},
	},
	mounted() {
		this.paciente_id = this.$route.params.id;
		this.init();
	},
};
