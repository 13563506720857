
import PageTitle from "../../components/PageTitle.vue";
import { DateTime } from "luxon";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar: false,
			title: "Pacientes con protocolos",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			isBusy: false,
			fields: [
				{ key: "index", label: "N°", sortable: false },
				{ key: "medico", label: "Médico", sortable: true },
				{ key: "paciente", label: "Paciente", sortable: true },
				{ key: "correo", label: "Contacto", sortable: true },
				{ key: "sesion", label: "Sesión", sortable: true },
				{ key: "fecha", label: "Fecha", sortable: true },
				{ key: "diagnostico", label: "Diagnóstico", sortable: true },
				{ key: "tratamiento", label: "Tratamiento", sortable: true },
				{ key: "cotizacion", label: "Cotización", sortable: true },
				{ key: "estado", label: "Estado", sortable: true },
			],
			items: [],
			itemDetalles: [],
			filter: null,
			form: {
				fullName: '',
				origen_cliente_id: '',
				telefono: '',
				correo: '',
				producto_id: '',
				interes: '',
				fecLlamar: '',
				comentario: ''
			},
			formDetalle: {
				fecLlamar: '',
				comentario: ''
			},
			origenClientes: [],
			producto: '',
			productos: [],
			diagnosticos: [],
			medicos: [],
			pacientes: [],
			sesions: [],
			fecha: DateTime.now().minus({ days: 1 }).toISODate(),
			filterForm: {
				diagnostico: '',
				medico: '',
				paciente: ''
			},
			protocolos: []
		};
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
		itemsFiltro() {
			let items = this.items;
			/* Diagnosticos */
			if (this.filterForm.diagnostico) {
				items = items.filter(item => { if (item.diagnostico) { return item.diagnostico.includes(this.filterForm.diagnostico) } });
			}
			/* Medicos */
			if (this.filterForm.medico) {
				items = items.filter(item => item.medico.includes(this.filterForm.medico));
			}
			/* Pacientes */
			if (this.filterForm.paciente) {
				items = items.filter(item => item.paciente.includes(this.filterForm.paciente));
			}
			/* Sesiones */
			if (this.filterForm.sesion) {
				items = items.filter(item => item.sesion.toString().includes(this.filterForm.sesion * 1));
			}

			this.totalRows = items.length;

			let diagnosticos = [];
			let pacientes = [];
			let medicos = [];
			let sesions = [];

			items.forEach(element => {
				diagnosticos.push(element.diagnostico);
				medicos.push(element.medico);
				sesions.push(element.sesion);
				pacientes.push(element.paciente);
			});

			sesions = sesions.map((item) => { return item.toString().padStart(2, "0") })
			this.diagnosticos = [...new Set(diagnosticos.sort())];
			this.medicos = [...new Set(medicos.sort())];
			this.sesions = [...new Set(sesions.sort())];
			this.pacientes = [...new Set(pacientes.sort())];

			return items;
		}
	},
	methods: {
		async inputFile(e) {
			this.file = this.$refs.file.files[0];
		},
		async fetchProductos(search, loading) {
			if (search.length > 2) {
				let { data } = await this.axios.get('select/productos/' + search);
				this.productos = data;
			}
		},
		async init() {
			let resp: any;
			try {
				resp = await this.axios.get('select/origenCliente');
				this.origenClientes = resp.data;
				this.listar();
			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		async listar() {
			this.isBusy = true;
			try {
				let url = 'protocoloSeguimiento'
				if (this.fecha) {
					url = `protocoloSeguimiento/${this.fecha}`
				}
				let { data } = await this.axios.get(url);
				this.items = data;
				this.isBusy = false;
			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		async listarDetalle() {
			try {
				let { data } = await this.axios.get(`pacienteSeguimientoDetalle/${this.form.id}`);
				this.itemDetalles = data;
			} catch (error) {
				this.catchAction(error);
			}
		},
		reset() {
			this.form = {
				fullName: '',
				telefono: '',
				correo: '',
				origen_cliente_id: '',
			}
			this.producto = '';
		},
		resetDetalle() {
			this.formDetalle = {
				fecLlamar: '',
				comentario: '',
			}
		},
		async show(item) {
			let resp: any = '';
			
			try {
				resp = await this.axios.get('seguimientoPacienteShow/' + item.paciente_id);
				if (!resp.data) {
					if (confirm('Programar un seguimiento al paciente?')) {
						resp = await this.axios.post('protocoloPaciente/' + item.id);
						this.form = resp.data.data;
						this.listarDetalle();
						this.listar();
						// @ts-ignore
						$("#modalSeguimientoDetalle").modal({ backdrop: 'static', keyboard: false });
					}
				} else {
					this.form = resp.data;
					this.listarDetalle();
					// @ts-ignore
					$("#modalSeguimientoDetalle").modal({ backdrop: 'static', keyboard: false });
				}
			} catch (error) {
				this.catchAction(error);
			}
		},
		async showDetalle(id) {
			try {
				let { data } = await this.axios.get('pacienteSeguimientoDetalles/' + id);
				this.formDetalle = data;
			} catch (error) {
				this.catchAction(error);
			}
		},
		async submit(e) {
			e.preventDefault();
			let data: any = '';
			let resp: any = '';
			this.cargar = true;

			try {
				this.form.interes = this.producto.label;
				this.form.producto_id = this.producto.id;

				if (this.form.id > 0) {
					resp = await this.axios.put('pacienteSeguimientos/' + this.form.id, this.form);
					data = resp.data;
				} else {
					resp = await this.axios.post('pacienteSeguimientos', this.form);
					data = resp.data;
				}

				this.cargar = false;
				// @ts-ignore
				$("#modalSeguimiento").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async submitDetalle(e) {
			e.preventDefault();
			let data: any = '';
			let resp: any = '';
			this.cargar = true;

			try {
				this.formDetalle.paciente_seguimiento_id = this.form.id;
				if (this.formDetalle.id > 0) {
					resp = await this.axios.put('pacienteSeguimientoDetalles/' + this.formDetalle.id, this.formDetalle);
					data = resp.data;
				} else {
					resp = await this.axios.post('pacienteSeguimientoDetalles', this.formDetalle);
					data = resp.data;
				}

				this.cargar = false;
				this.listar();
				this.listarDetalle();
				this.resetDetalle();
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async submitCierre(e) {
			e.preventDefault();
			this.cargar = true;
			try {

				let { data } = await this.axios.put('pacienteSeguimiento/cierre/' + this.form.id, this.form);
				this.cargar = false;
				this.listar();
				// @ts-ignore
				$("#modalSeguimientoCierre").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async showCotizaciones(paciente_id) {
			try {
				let { data } = await this.axios.get('protocolos/paciente/' + paciente_id);
				this.protocolos = data;
				// @ts-ignore
				$("#modalCotizacion").modal({ backdrop: 'static', keyboard: false })
			} catch (error) {
				this.catchAction(error);
			}
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	filters: {
		moneda: function (value) {
			if (!value) return '';
			value = parseFloat(value);
			return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
		},
		monedaDes: function (value) {
			if (!value) return '';
			value = parseFloat(value);
			return value.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
		}
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
