
export default {
	data() {
		return {
			cargar: false,
			sedes: [],
			form: {
				user: "",
				password: "",
				sede:'',
			},
		};
	},
	methods: {
		async selectSede(){
			let { data } = await this.axios.get('select/sedes_user/'+ this.form.user);
			this.sedes = data;
			if(this.sedes.length == 1){
				this.form.sede = this.sedes[0].id;
			}
		},
		async submit() {
			if (!this.form.user || !this.form.password) {
				return false;
			}
			this.cargar = true;
			let resp:any = '';
			
			try {
				let {data} = await this.axios.post("login", this.form);
				await this.$store.dispatch('setUser', data.user);

				resp = await this.axios.get('cajaVentas/apertura');
				await this.$store.dispatch('setCaja', resp.data);

				window.location.replace('/dashboard');
				this.cargar = false;
			} catch (error) {
				this.cargar = false;
				if(error.response){
					let resp = error.response;
					if(resp.status == 401){						
						this.form.password = '';
						// @ts-ignore
						return new PNotify({
								text: 'Credenciales invalidas',
								type: 'warning',
								styling: 'bootstrap3'
							});
					}
				}
				// @ts-ignore
				return new PNotify({
								text: error.message,
								type: 'danger',
								styling: 'bootstrap3'
							});
			}
		},
	},
	created() {
		let body = document.querySelector("body");
		body.className = "login";
	},
};
