
import PageTitle from "../../components/PageTitle.vue";
import { DateTime } from "luxon";

export default {
	components: { PageTitle },
	data() {
		return {
		cargar:false,
		title: "Listado de Personal",
		titleForm: "Personal",
		totalRows: 1,
		currentPage: 1,
		perPage: 20,
		fecha_hoy:DateTime.now().toISODate(),
		fields: [
			{ key: "index", 	label: "N°",		sortable: false },
			{ key: "acciones", 	label: "Acciones",	sortable: false },
			{ key: "fullname", 	label: "Personal", 	sortable: true },
			{ key: "especialidad", 	label: "Especialidad", 	sortable: true },
			{ key: "correo", 	label: "Correo", 	sortable: true },
			{ key: "telefono", 	label: "Telefono", 	sortable: true },
			{ key: "ubigeo", 	label: "Ubigeo", 	sortable: true },
			{ key: "direccion", label: "Direccion", sortable: true },
			{ key: "tipo_contrato", label: "Tipo Contrato", sortable: true },
			{ key: "estado", 	label: "Estado", 	sortable: true }],
		items: [],
		filter: null,
		departamentos:[],
		provincias:[],
		distritos:[],
		sexos:[],
		estadoCivils:[],
		tipoDocumentos:[],
		especialidads:[],
		tipoProfesionals:[],
		sedes:[],
		tipoContratos:[],
		rols:[],
		areas:[],
		form: {
			tipo_documento_id: 1,
			numDocumento: "",
			abrev_personal: "",
			nombres: "",
			apellidoPaterno: "",
			apellidoMaterno: "",
			fechaNacimiento: "",
			correo: "",
			telefono: "",
			fijo: "",
			direccion: "",
			cm:	"",
			fechaIngreso: "",
			department_id: "",
			sede_id:"",
			province_id: "",
			district_id: "",
			estado_civil_id: "",
			especialidad_id: "",
			tipo_profesional_id:"",
			sexo_id: "",
			area_id: "",
			rol_id: "",
			tipo_contrato_id: ""
		},
		formCard:{},
		file:'',
		img_personal:'',
		pdf_dni:'',
		pdf_certiadulto:'',
		pdf_curriculum:'',
		pdf_policial:'',
		pdf_domiciliaria:'',
		pdf_servicio:'',
		pdf_imagen:'',
		img_firma:'',
		document:''
		};
	},
	watch: {
		async "form.department_id"(newValor, oldValor){		
			if(newValor){
				let {data} = await this.axios.get('provincias/'+newValor);
				this.provincias = data;	
			}	
		},
		async "form.province_id"(newValor, oldValor){		
			if(newValor){	
				let {data} = await this.axios.get('distritos/'+newValor);
				this.distritos = data;	
			}
		},
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
		numUltimo(){
			let end = this.totalRows;
			let itemsPerPage = this.currentPage * this.perPage;

			if (itemsPerPage < this.totalRows) {
				end = itemsPerPage;
				if (end > this.totalRows) {
				end = this.totalRows;
				}
			}
			return end;
		}
	},
	methods: {
		async inputPersonal(e){
			this.img_personal = this.$refs.img_personal.files[0];
		},
		async inputDNI(e){
			this.pdf_dni = this.$refs.pdf_dni.files[0];
		},
		async inputCertiadulto(e){
			this.pdf_certiadulto = this.$refs.pdf_certiadulto.files[0];
		},
		async inputCurriculum(e){
			this.pdf_curriculum = this.$refs.pdf_curriculum.files[0];
		},
		async inputPolicial(e){
			this.pdf_policial = this.$refs.pdf_policial.files[0];
		},
		async inputDomiciliaria(e){
			this.pdf_domiciliaria = this.$refs.pdf_domiciliaria.files[0];
		},
		async inputServicio(e){
			this.pdf_servicio = this.$refs.pdf_servicio.files[0];
		},
		async inputImagen(e){
			this.pdf_imagen = this.$refs.pdf_imagen.files[0];
		},
		async inputFileFirma(e){
			this.img_firma = this.$refs.img_firma.files[0];
		},
		async init(){
			this.listar();
			let resp = {data:null};

			resp = await this.axios.get('departamentos');
			this.departamentos = resp.data;	

			resp = await this.axios.get('select/sexos');
			this.sexos = resp.data;	

			resp = await this.axios.get('select/estadoCivils');
			this.estadoCivils = resp.data;	

			resp = await this.axios.get('select/tipoDocumentos');
			this.tipoDocumentos = resp.data;

         	resp = await this.axios.get('select/especialidads');
			this.especialidads = resp.data;

			resp = await this.axios.get('select/tipoProfesionals');
			this.tipoProfesionals = resp.data;			

			resp = await this.axios.get('select/sedes');
			this.sedes = resp.data;	

			resp = await this.axios.get('select/areas');
			this.areas = resp.data;	

			resp = await this.axios.get('select/rols');
			this.rols = resp.data;				

			resp = await this.axios.get('select/tipoContratos');
			this.tipoContratos = resp.data;	
		},
		async listar(){
			try {
				let {data} = await this.axios.get('personals');
				this.items = data;	
				this.totalRows = this.items.length;	
			} catch (error) {				
				this.catchAction(error);
			}
		},
		async getData(id){
			let {data} = await this.axios.get('personals/' + id);
			this.formCard = data;
		},
		async showCard(id = 0){  
			this.getData(id); 
			// @ts-ignore
			$("#modalCard").modal({backdrop: 'static', keyboard: false});
		},
		async show(id = 0){         
			this.cargar = false;
			try {
				this.reset();
				if(id > 0){
					let {data} = await this.axios.get('personals/' + id);
					this.form = data;
				}
				// @ts-ignore
				$("#modalPersonal").modal({backdrop: 'static', keyboard: false})
			} catch (error) {				
				this.catchAction(error);
			}	
		},
		async state(item, state){
			let title = 'Activar?';
			let confirmButtonText = 'Si, Activar.';
			if(state == 0){
				title = 'Desactivar?';
				confirmButtonText = 'Si, Desactivar.';
			}

			this.$swal.fire({
				title: title,
				text: item.fullname,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: confirmButtonText,
				showLoaderOnConfirm: true,
				preConfirm: (login) => {
					return this.axios.patch('personals/' + item.id+'/state',{isActive: state})
					.then((result) => {
						return result.data;
					}).catch((err) => {
						this.$swal.showValidationMessage(
							`Request failed: ${err}`
						)
					});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {	
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.listar();
				}
			});
		},
		reset(){
			// @ts-ignore
			document.getElementById("form").reset();
			
         	this.file = '';
         	this.img_personal = '';
         	this.pdf_dni = '';
         	this.pdf_certiadulto = '';
         	this.pdf_curriculum = '';
         	this.pdf_policial = '';
         	this.pdf_domiciliaria = '';
         	this.pdf_servicio = '';
         	this.pdf_imagen = '';
         	this.img_firma = '';

			this.form = {
				tipo_documento_id: 1,
				numDocumento: "",
				abrev_personal: "",
				nombres: "",
				apellidoPaterno: "",
				apellidoMaterno: "",
				fechaNacimiento: "",
				correo: "",
				telefono: "",
				fijo: "",
				direccion: "",
				department_id: "",
				sede_id:"",
				province_id: "",
				district_id: "",
				estado_civil_id: "",
				especialidad_id: "",
				tipo_profesional_id:"",
				sexo_id: "",
			}
			
		},
		async submit(e){
			e.preventDefault();
			let data:any = '';
         	let resp:any = '';
			this.cargar = true;

			try {
				if(this.form.id > 0){
					resp  = await this.axios.put('personals/' + this.form.id, this.form);
					data  = resp.data;
				}else{
					resp  = await this.axios.post('personals', this.form);
					data  = resp.data;
				}
				
				if(this.img_firma && data.data.id > 0){
					await this.upload(data.data.id, data.data.firma_id, 'firma_id', this.img_firma, 2);
				}
				if(this.img_personal && data.data.id > 0){
					await this.upload(data.data.id, data.data.imagen_id, 'imagen_id', this.img_personal, 3);
				}
				if(this.pdf_dni && data.data.id > 0){
					await this.upload(data.data.id, data.data.dni_id, 'dni_id', this.pdf_dni, 7);
				}
				if(this.pdf_certiadulto && data.data.id > 0){
					await this.upload(data.data.id, data.data.certiadulto_id, 'certiadulto_id', this.pdf_certiadulto, 8);
				}
				if(this.pdf_curriculum && data.data.id > 0){
					await this.upload(data.data.id, data.data.curriculum_id, 'curriculum_id', this.pdf_curriculum, 9);
				}
				if(this.pdf_policial && data.data.id > 0){
					await this.upload(data.data.id, data.data.policial_id, 'policial_id', this.pdf_policial, 10);
				}
				if(this.pdf_domiciliaria && data.data.id > 0){
					await this.upload(data.data.id, data.data.domiciliaria_id, 'domiciliaria_id', this.pdf_domiciliaria, 11);
				}				
				if(this.pdf_servicio && data.data.id > 0){
					await this.upload(data.data.id, data.data.servicio_id, 'servicio_id', this.pdf_servicio, 12);
				}				
				if(this.pdf_imagen && data.data.id > 0){
					await this.upload(data.data.id, data.data.imagen_pdf_id, 'imagen_pdf_id', this.pdf_imagen, 13);
				}
				this.listar();
				this.cargar = true;
				// @ts-ignore
				$("#modalPersonal").modal('hide');
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargar = false;				
				this.catchAction(error);
			} 
			this.cargar = false;
		},
		async upload(id, columna_id, columna, file, model){
			return new Promise(async (resolve, reject) => {
				let formData = new FormData();
				formData.append('id',id);
				formData.append('columna_id',columna_id);
				formData.append('columna',columna);
				formData.append('file',file);
				formData.append('model', model);
				let {data} = await this.axios.post('file', formData);
				resolve(data);
			});
			
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
