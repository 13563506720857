
import PageTitle from "../../components/PageTitle.vue";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar: false,
			cargarSerie: false,
			title: "Sede",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "acciones", label: "Acciones", sortable: false },
				{ key: "nombre", label: "Sede", sortable: true },
				{ key: "correo", label: "Correo", sortable: true },
				{ key: "telefono", label: "Telefono", sortable: true },
				{ key: "ubigeo", label: "Ubigeo", sortable: true },
				{ key: "direccion", label: "Direccion", sortable: true },
				{ key: "company", label: "Empresa", sortable: true },
				{ key: "estado", label: "Estado", sortable: true }],
			items: [],
			filter: null,
			departamentos: [],
			provincias: [],
			distritos: [],
			personals: [],
			companies: [],
			form: {
				nombre: "",
				direccion: "",
				telefono: "",
				correo: "",
				department_id: "",
				province_id: "",
				district_id: "",
				encargado_id: "",
				company_id: "",
				maps: "",
			},
			comprobanteTipos: [],
			sede_id: '',
			series: [],
		};
	},
	watch: {
		async "form.department_id"(newValor, oldValor) {
			let { data } = await this.axios.get('provincias/' + newValor);
			this.provincias = data;
		},
		async "form.province_id"(newValor, oldValor) {
			let { data } = await this.axios.get('distritos/' + newValor);
			this.distritos = data;
		},
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	methods: {
		async init() {
			let resp: any = '';
			this.listar();

			resp = await this.axios.get('departamentos');
			this.departamentos = resp.data;

			resp = await this.axios.get('select/companies');
			this.companies = resp.data;

			resp = await this.axios.get('select/personals/encargado');
			this.personals = resp.data;

			resp = await this.axios.get("select/comprobanteTipos");
			this.comprobanteTipos = resp.data;
		},
		async listar() {
			try {
				let { data } = await this.axios.get('sedes');
				this.items = data;
				this.totalRows = this.items.length;
			} catch (error) {
				this.catchAction(error);
			}
		},
		async show(id = 0) {
			this.cargar = true;
			try {
				this.reset();
				if (id > 0) {
					let { data } = await this.axios.get('sedes/' + id);
					this.form = data;
				}
				this.cargar = false;
				// @ts-ignore
				$("#modalSede").modal({ backdrop: 'static', keyboard: false })
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async addSerie(){
			let serie = {
				comprobante_tipo_id: '',
				serie: '',
				numero: '',
				isContigencia: '',
			};
			this.series.push(serie);
		},
		async showSerie(id = 0) {
			this.series = [];
			this.sede_id = id;
			let {data} = await this.axios.get('comprobanteSeries/sede/'+id);
			if(data.length == 0){
				this.series = [{
				comprobante_tipo_id: '',
				serie: '',
				numero: '',
				isContigencia: '',
			}];
			}else{
				this.series = data;
			}
			// @ts-ignore
			$("#modalSerie").modal({ backdrop: 'static', keyboard: false });
		},
		async eliminarSerie(item,index){
			let series = JSON.parse(JSON.stringify(this.series));
			if(series[index].id > 0){
				await this.axios.delete('comprobanteSeries/'+series[index].id);
			}
			series.splice(index, 1);
			this.series = series;
		},
		async state(item, state) {
			let title = 'Activar?';
			let confirmButtonText = 'Si, Activar.';
			if (state == 0) {
				title = 'Desactivar?';
				confirmButtonText = 'Si, Desactivar.';
			}

			this.$swal.fire({
				title: title,
				text: item.nombre,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: confirmButtonText,
				showLoaderOnConfirm: true,
				preConfirm: (login) => {
					return this.axios.patch('sedes/' + item.id + '/state', { isActive: state })
						.then((result) => {
							return result.data;
						}).catch((err) => {
							this.$swal.showValidationMessage(
								`Request failed: ${err}`
							)
						});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.listar();
				}
			});
		},
		reset() {
			this.cargar = false;
			this.form = {
				nombre: "",
				direccion: "",
				telefono: "",
				correo: "",
				department_id: 15,
				province_id: 1501,
				district_id: "",
				encargado_id: "",
				company_id: "",
				maps: "",
			}
		},
		async submit(e) {
			e.preventDefault();
			let data: any = '';
			let resp: any = '';
			this.cargar = true;

			try {
				if (this.form.id > 0) {
					resp = await this.axios.put('sedes/' + this.form.id, this.form);
					data = resp.data;
				} else {
					resp = await this.axios.post('sedes', this.form);
					data = resp.data;
				}
				this.listar();
				this.cargar = false;
				// @ts-ignore
				$("#modalSede").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async submitSeries(e){
			e.preventDefault();	
			let data: any = '';
			let resp: any = '';
			
			this.cargarSerie = true;
			try {
				await this.series.forEach(async item => {
					item.sede_id = this.sede_id;
					if (item.id > 0) {
						resp = await this.axios.put('comprobanteSeries/' + item.id, item);
						data = resp.data;
					} else {
						resp = await this.axios.post('comprobanteSeries', item);
						data = resp.data;
					}
				});
				this.cargarSerie = false;
				// @ts-ignore
				$("#modalSerie").modal('hide');
				// @ts-ignore
				new PNotify({
					text: 'Datos actualizados',
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargarSerie = false;
				this.catchAction(error);
			}
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
