
import PageTitle from "../../components/PageTitle.vue";
import ModalAtencion from "../../components/Paciente/ModalAtencion.vue";

export default {
	components: { PageTitle, ModalAtencion },
	data() {
		return {
			cargar: false,
			consultaTable: true,
			title: "Listado de Pacientes",
			titleForm: "Paciente",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "index", 			label: "N°",		sortable: false },
				{ key: "acciones", 		label: "Acciones", 	sortable: false },
				{ key: "numDocumento", 	label: "Num°", 		sortable: true },
				{ key: "fullName", 		label: "Paciente", 	sortable: true },
				{ key: "correo", 			label: "Correo", 	sortable: true },
				{ key: "telefono", 		label: "Telefono", 	sortable: true },
				{ key: "ubigeo", 			label: "Ubigeo", 	sortable: true },
				{ key: "direccion", 		label: "Direccion", sortable: true },
				{ key: "estado", 			label: "Estado", 	sortable: true },
				{ key: "fRegistro", 		label: "Registro", 	sortable: true },
			],
			items: [],
			filter: null,
			paises: [],
			departamentos: [],
			provincias: [],
			distritos: [],
			sexos: [],
			estadoCivils: [],
			tipoDocumentos: [],
			origenClientes: [],
			medicos: [],
			form: {
				tipo_documento_id: 1,
				numDocumento: "",
				nombres: "",
				apellidoPaterno: "",
				apellidoMaterno: "",
				fechaNacimiento: "",
				correo: "",
				telefono: "",
				fijo: "",
				direccion: "",
				ocupacion: "",
				department_id: "",
				province_id: "",
				district_id: "",
				estado_civil_id: "",
				sexo_id: "",
				country_id: 1,
				origin_id:"",
				medico_id:"",
			},
			consulta:{
				inicio: '',
				fin: '',
			},
			isBusy: false,
		};
	},
	watch: {
		async "form.department_id"(newValor, oldValor) {
			let { data } = await this.axios.get('provincias/' + newValor);
			this.provincias = data;
		},
		async "form.province_id"(newValor, oldValor) {
			let { data } = await this.axios.get('distritos/' + newValor);
			this.distritos = data;
		},
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
		numUltimo(){
			let end = this.totalRows;
			let itemsPerPage = this.currentPage * this.perPage;

			if (itemsPerPage < this.totalRows) {
				end = itemsPerPage;
				if (end > this.totalRows) {
				end = this.totalRows;
				}
			}
			return end;
		},
		url() {
			return this.$store.state.url;
		},
	},
	methods: {
		async init() {
			this.listar();
			let resp: any = '';

			resp = await this.axios.get('departamentos');
			this.departamentos = resp.data;

			resp = await this.axios.get('countries');
			this.paises = resp.data;

			resp = await this.axios.get('select/sexos');
			this.sexos = resp.data;

			resp = await this.axios.get('select/estadoCivils');
			this.estadoCivils = resp.data;

			resp = await this.axios.get('select/tipoDocumentos');
			this.tipoDocumentos = resp.data;

			resp = await this.axios.get('select/origenCliente');
			this.origenClientes = resp.data;

			resp = await this.axios.get('select/medicos');
			this.medicos = resp.data;
		},
		async listar(tipo = 1) {
			this.isBusy = true;
			this.consulta.tipo = tipo;
			try {
				let url = 'dt_pacientes';
				if(this.filter){
					url = url+'/'+this.filter;
				}

				if(tipo == 1){
					let { data } = await this.axios.get(url, this.consulta);
					this.items = data;
					this.totalRows = this.items.length;
					if(this.totalRows > 0){
						this.consultaTable = true;
					}					
				}

				if(tipo == 2){
					url = this.url+'dt_pacienteExport/'+this.consulta.inicio+'/'+this.consulta.fin;
					console.log(url);
					var fileLink = document.createElement('a');
					fileLink.href = url;
					fileLink.setAttribute('download', 'pacientes.xlsx');
					document.body.appendChild(fileLink);
					fileLink.click();
				}
				this.isBusy = false;

			} catch (error) {				
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		ir(id = 0){
			this.$router.push({name:'ShowPaciente', params: { id: id}});
		},
		async show(id = 0) {
			this.cargar = true;
			try {
				this.reset();				
				// @ts-ignore
				$("#modalEditar").modal({ backdrop: 'static', keyboard: false })

				if (id > 0) {
					let { data } = await this.axios.get('pacientes/' + id);
					this.form = data;
				}	

				this.cargar = false;
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async state(item, state) {
			let title = 'Activar?';
			let confirmButtonText = 'Si, Activar.';
			if (state == 0) {
				title = 'Desactivar?';
				confirmButtonText = 'Si, Desactivar.';
			}

			this.$swal.fire({
				title: title,
				text: item.fullName,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: confirmButtonText,
				showLoaderOnConfirm: true,
				preConfirm: (login) => {
					return this.axios.patch('pacientes/' + item.id + '/state', { isActive: state })
						.then((result) => {
							return result.data;
						}).catch((err) => {
							this.$swal.showValidationMessage(
								`Request failed: ${err}`
							)
						});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.listar();
				}
			});
		},
		reset() {
			this.form = {
				tipo_documento_id: 1,
				numDocumento: "",
				nombres: "",
				apellidoPaterno: "",
				apellidoMaterno: "",
				fechaNacimiento: "",
				correo: "",
				telefono: "",
				fijo: "",
				direccion: "",
				ocupacion: "",
				department_id: 15,
				province_id: 1501,
				district_id: "",
				estado_civil_id: "",
				sexo_id: "",
				country_id: 1,
				origin_id:"",
				medico_id:"",
			}
		},
		async submit(e) {
			e.preventDefault();
			let data: any = '';
			this.cargar = true;
			try {
				if (this.form.id > 0) {
					let resp = await this.axios.put('pacientes/' + this.form.id, this.form);
					data = resp.data;
				} else {
					let resp = await this.axios.post('pacientes', this.form);
					data = resp.data;
				}
				this.listar();
				// @ts-ignore
				$("#modalEditar").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
			this.cargar = false;
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
			if(this.totalRows == 0 && this.consultaTable){				
				this.consultaTable = false;			
				this.listar();
			}
			if(this.filter==''){
				this.listar();
			}
		},
		historiaPaciente(paciente){
			this.$refs.modalAtencion.historiaPaciente(paciente);
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
