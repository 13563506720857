
import PageTitle from "../../components/PageTitle.vue";

export default {
	components: { PageTitle },
	data() {
		return {
			title: "Pacientes - Atención",
			annio: 2022,
			pacientes: [],
			origens: [],
		}
	},
	methods: {
		async listar(){
			try {
				let {data} = await this.axios.get(`reportepacienteMedico/${this.annio}`);
				let pacientes = data.pacientes;		
				let origens = data.origens;

				let origenTotal = {
					nombre: 'Total',
					1: [0],
					2: [0],
					3: [0],
					4: [0],
					5: [0],
					6: [0],
					7: [0],
					8: [0],
					9: [0],
					10: [0],
					11: [0],
					12: [0],
				}
				
				origens.forEach(origen => {
					for (let i = 1; i <= 12; i++) {
						origenTotal[i][0] += (origen[i])?origen[i][0] * 1  : 0;
					}
				});

				let pacienteTotal = {
					fullName: 'Total',
					1: [0,0],
					2: [0,0],
					3: [0,0],
					4: [0,0],
					5: [0,0],
					6: [0,0],
					7: [0,0],
					8: [0,0],
					9: [0,0],
					10: [0,0],
					11: [0,0],
					12: [0,0],
				}

				pacientes.forEach(paciente => {
					for (let i = 1; i <= 12; i++) {
						pacienteTotal[i][0] += (paciente[i])?paciente[i][0] * 1 : 0;
						pacienteTotal[i][1] += (paciente[i])?paciente[i][1] * 1 : 0;
					}
				});
				
				origens.push(origenTotal);
				pacientes.push(pacienteTotal);
				
				this.pacientes = pacientes;
				this.origens = origens;
			} catch (error) {
				this.catchAction(error);
			}
		}
	},
}
