
export default {
	data() {
		return {
			cargarHistoria: false,
			historiaMedica: [],
			recetaMedica: [],
			pacienteConsulta: ''
		}
	},
	computed: {
		user() {
			return this.$store.state.user;
		},
		url() {
			return this.$store.state.url;
		},
		totals() {
			let total = [];
			this.historiaMedica.forEach(item => {
				if (item.comprobante_detalle) {
					let moneda = item.comprobante_detalle.moneda;
					let mtoImpVenta = parseFloat(item.comprobante_detalle.mtoImpVenta);
					let index = total.findIndex(item => item.moneda == moneda);
					if (index >= 0) {
						total[index]['mtoImpVenta'] = total[index]['mtoImpVenta'] + mtoImpVenta;
					} else {
						let data = {
							moneda: moneda,
							mtoImpVenta: mtoImpVenta,
						}
						total.push(data);
					}
				}
			});
			return total;
		}
	},
	methods: {
		async historiaPaciente(paciente) {
			this.historia_clinica_id = '';
			this.recetaMedica = [];
			this.pacienteConsulta = paciente;
			// @ts-ignore
			$("#modalHistoriaMedico").modal({ backdrop: 'static', keyboard: false });
			this.cargarHistoria = true;
			const { data } = await this.axios.get('paciente/historiaCita/' + paciente.paciente_id);
			this.historiaMedica = data;
			this.cargarHistoria = false;
		},
		async getRecetaMedica(id) {
			this.cargarHistoria = true;
			this.recetaMedica = [];
			if (id > 0) {
				const { data } = await this.axios.get('recetaMedicas/historiaClinica/' + id);
				this.recetaMedica = data;
				this.pacienteConsulta.historia_clinica_id = id;
			}
			this.cargarHistoria = false;
		},
	},
}
