
import { DateTime } from "luxon";

export default {
	data() {
		return {
			cargar: false,
			apertura: false,
			spinnerCliente: false,
			cargarCliente: false,
			cargarPago: false,
			cargarCierre: false,
			tipoCambios: [],
			cajas: [],
			productos: [],
			clientes: [],
			medicos: [],
			monedas: [],
			comprobanteTipos: [],
			medioPagos: [],
			series: [],
			producto: '',
			codigo: '',
			cita: '',
			igv: '',
			fechaActual: DateTime.now().toISODate(),
			tpCambio: 0.00,
			venta: {
				comprobante_tipo_nota_credito_id: '',
				comprobante_tipo_nota_debito_id: '',
				cliente: '',
				fechaEmision: DateTime.now().toISODate(),
				fechaVencimiento: DateTime.now().toISODate(),
				moneda_id: '1',
				comprobante_tipo_id: '',
				serie_id: '',
				medico_id: '',
				total:  0.00,
				pagado: 0.00,				
				vuelto: 0.00,
				MtoIGV: 0,
				MtoOperGravadas: 0,
				ValorVenta: 0,
				SubTotal: 0,
				MtoImpVenta: 0,
				mtoDescuento: 0,
				TotalImpuestos: 0,
				detalle: [],
				indComisionMedico:'',
				pagos: [
					{
						medio_id: '',
						importe: '',
						fechaVencimiento: '',
					}
				],
				pagoComision: [
					{
						medio_id: '',
						importe: 0,
						fechaVencimiento: '',
					}
				],				
			},
			productoDetalle: '',
			formApertura: {
				caja_id: '',
				inicial: ''
			},
			departamentos: [],
			provincias: [],
			distritos: [],
			tipoDocumentos: [],
			formCliente: {
				tipo_documento_id: "",
				numDocumento: "",
				razonSocial: "",
				razonComercial: "",
				direccionFiscal: "",
				email: "",
				emailOne: "",
				emailDo: "",
				movil: "",
				fijo: "",
				detraccion: "",
				detalle: "",
				department_id: "",
				province_id: "",
				district_id: "",
			},
			documentoUrl: '',
			comisionMedicos: [],
			cierreMovimientos: [],
			numComprobante: '',
			tipo_contrato_id: '',
			comprobanteSeleccionado: ''
		}
	},
	watch: {
		async "venta.comprobante_tipo_id"(newValor, oldValor) {
			if (this.caja) {
				this.series = [];
				let { data } = await this.axios.get('select/comprobanteSeries/' + newValor + '/' + this.caja.sede_id);
				this.series = data;
				if (data.length > 0) {
					this.venta.serie_id = data[0].id;
				}
			}
		},
		async "formCliente.department_id"(newValor, oldValor) {
			let { data } = await this.axios.get('provincias/' + newValor);
			this.provincias = data;
		},
		async "formCliente.province_id"(newValor, oldValor) {
			let { data } = await this.axios.get('distritos/' + newValor);
			this.distritos = data;
		},
		async "venta.detalle"(newValor, oldValor) {
			this.calcular();
		},
		async "venta.indComisionMedico"(newValor, oldValor) {
			this.calcular();
		},
		async "venta.pagos"(newValor, oldValor){
			this.calcularPago();
		}
	},
	computed: {
		caja() {
			return this.$store.state.caja;
		},
		sibMoneda() {
			let moneda = this.monedas.find(item => item.id == this.venta.moneda_id);
			if (moneda) {
				return moneda.abrev;
			}
		},
		serie() {
			if (this.series.length > 0) {
				return this.series.find(item => item.id == this.venta.serie_id);
			} else {
				return '';
			}
		}
	},
	methods: {
		async calcularPago(){			
			let total = 0;
			this.venta.pagos.forEach(item => {
				let suma = item.importe*1;
				total += suma;
			});
			this.venta.pagado = total;
			this.venta.vuelto = total - this.venta.MtoImpVenta;
		},
		async calcular() {
			let MtoImpVenta = 0;
			let ValorVenta = 0;
			let TotalImpuestos = 0;
			let MtoIGV = 0;
			let mtoComision = 0;
			let mtoDescuento = 0;
			let mtoValorInicial = 0;

			this.venta.detalle.forEach(item => {
				/* iniciamos desde cero */
				let basePrecioVenta = item.basePrecioVenta;
				let igv = ((100 + item.porcentaje_igv) / 100);
				
				/* si modifica el precio */
				if(item.basePrecioUnitario != basePrecioVenta){
					basePrecioVenta = item.basePrecioUnitario;
				}

				/* descuento medico */
				if (item.desMedico > 0) {
					basePrecioVenta = basePrecioVenta - item.desMedico;					
				}
				
				/* pasamos a la moneda principal*/
				item.mtoTipoCambio = 1;
				if (item.baseMoneda_id != this.venta.moneda_id) {
					if(this.venta.moneda_id != 1){
						let monedaCambio = this.tipoCambios.find(tipoCambio => tipoCambio.id == this.venta.moneda_id);
						item.mtoTipoCambio = monedaCambio.valorCambio;
						basePrecioVenta = (basePrecioVenta / item.mtoTipoCambio).toFixed(2);
					}else{
						let monedaCambio = this.tipoCambios.find(tipoCambio => tipoCambio.id == item.baseMoneda_id);
						item.mtoTipoCambio = monedaCambio.valorCambio;
						basePrecioVenta = (basePrecioVenta * item.mtoTipoCambio * 100 /100).toFixed(2);
					}
					basePrecioVenta = basePrecioVenta * 1;
				}

				item.moneda_id = this.venta.moneda_id;
				item.moneda = this.sibMoneda;
				item.mtoValorUnitarioInicial = basePrecioVenta;

				mtoValorInicial += (item.basePrecioVenta * item.mtoTipoCambio * item.cantidad);			
				
				/* comision medico*/
				let comision = this.comisionMedicos.find(item2 => item2.categoria_id == item.categoria_id);
				item.indComision = 0;
				item.comision = 0;
				item.mtoComision = 0;
				
				if (comision) {
					if(this.tipo_contrato_id == 4 && this.venta.indComisionMedico == 1) {	
						if(comision.tipo == 1){
							item.comision = (basePrecioVenta * (comision.comision / 100));							
						}
						if(comision.tipo == 2){
							item.comision = comision.comision * item.mtoTipoCambio;						
						}

						item.indComision = 1;	
						item.mtoComision = item.comision * item.cantidad;	
						mtoComision += item.mtoComision;
						
						basePrecioVenta =  basePrecioVenta - item.comision;	
						item.desFactor = 0;
					}
				}

				let unitMtoImpVenta = basePrecioVenta*1;
				item.mtoValorUnitario = (basePrecioVenta/igv).toFixed(4);

				let unitMtoImpVentaTotal = unitMtoImpVenta * item.cantidad;
				item.mtoBase = (unitMtoImpVentaTotal/igv).toFixed(2);
				/* ---- montos de precio de venta */				
				item.desMonto = 0;
				if(item.desFactor > 0){
					/* descuento */
					let factor = (item.desFactor / 100);
					let desMonto:any =  (unitMtoImpVentaTotal * factor).toFixed(2);
					item.desMonto = (desMonto/igv).toFixed(2);
					mtoDescuento += item.desMonto;

					/* valor de venta */
					unitMtoImpVenta = unitMtoImpVenta - (unitMtoImpVenta * factor);
				}

				item.mtoPrecioUnitario = unitMtoImpVenta.toFixed(2);
				/* Calculo de operacion */
				item.MtoImpVenta = (unitMtoImpVenta * item.cantidad).toFixed(2);
				item.mtoValorVenta = (item.MtoImpVenta/igv).toFixed(4);
				item.mtoBaseIgv = item.mtoValorVenta;
				item.igv = parseFloat(item.MtoImpVenta) - parseFloat(item.mtoValorVenta);
				item.TotalImpuestos = (item.igv).toFixed(2);		

				ValorVenta += item.mtoValorVenta *1;
				MtoIGV += item.igv*1;
				TotalImpuestos += item.TotalImpuestos*1;
				MtoImpVenta += item.MtoImpVenta*1;
			});

			this.venta.pagoComision[0].importe = (mtoComision*1).toFixed(2);
			this.venta.mtoComision = (mtoComision*1).toFixed(2);
		
			this.venta.mtoDescuento = (mtoDescuento*1).toFixed(2);
			this.venta.mtoValorInicial = (mtoValorInicial*1).toFixed(2);

			this.venta.MtoIGV = (MtoIGV*1).toFixed(2);
			this.venta.MtoOperGravadas = (ValorVenta*1).toFixed(4);
			this.venta.TotalImpuestos = (TotalImpuestos*1).toFixed(2);
			this.venta.ValorVenta = (ValorVenta*1).toFixed(2);
			this.venta.SubTotal = (MtoImpVenta*1).toFixed(2);	
			this.venta.MtoImpVenta = (MtoImpVenta*1).toFixed(2);
		},
		async comisionMedico() {
			this.comisionMedicos = [];
			this.tipo_contrato_id = 0;
			if (this.venta.medico_id > 0) {
				let medico_id = this.venta.medico_id;
				// saber el contrato del medico
				let medico = this.medicos.find(item => item.id == medico_id);
				this.tipo_contrato_id = medico.tipo_contrato_id;
				// traemos comisiones
				let resp = await this.axios.get('comisionPersonals/personal/' + medico_id);
				if (resp.data) {
					this.comisionMedicos = resp.data;
					this.venta.indComisionMedico = 1;
					this.calcular();
				}
			}
		},
		async fetchProductos(search, loading) {
			if (search.length > 2) {
				let { data } = await this.axios.get('select/productos/' + search);
				this.productos = data;
			}
		},
		async initCaja() {
			let resp: any = '';

			resp = await this.axios.get('cajaVentas/tipoCambio');
			this.tipoCambios = resp.data;

			resp = await this.axios.get("select/monedas");
			this.monedas = resp.data;

			resp = await this.axios.get("select/comprobanteTipos/1");
			this.comprobanteTipos = resp.data;

			resp = await this.axios.get('select/medico');
			this.medicos = resp.data;

			resp = await this.axios.get('departamentos');
			this.departamentos = resp.data;

			resp = await this.axios.get('select/tipoDocumentos');
			this.tipoDocumentos = resp.data;

			resp = await this.axios.get('select/medioPagos');
			this.medioPagos = resp.data;

			resp = await this.axios.get('tipoAfectacions/1');
			this.igv = resp.data.porcentaje;

			//obtener el tipo de cambio
			let monedaCambio = this.tipoCambios.find(item => item.moneda_id = 2);
			if(monedaCambio){this.tpCambio = monedaCambio.valorCambio}

			this.venta.comprobante_tipo_id = 2;
		},
		async init() {
			let resp: any = '';
			resp = await this.axios.get('select/cajas_sede_user');
			this.cajas = resp.data;
		},
		async getCaja() {
			let { data } = await this.axios.get('cajaVentas/apertura');
			await this.$store.dispatch('setCaja', data);
			this.initCaja();
		},
		async fetchClientes(search, id) {
			if (search.length >= 3) {
				this.spinnerCliente = true;
				let { data } = await this.axios.get('select/clientes/' + search + '/' + this.venta.comprobante_tipo_id);
				this.clientes = data;
				this.spinnerCliente = false;
			}
			if (id > 0) {
				let cliente = this.clientes.find(item => item.id == id);
				this.venta.cliente = cliente;
			}
		},
		async submitApertura(e) {
			e.preventDefault();
			this.cargar = true;
			try {
				let { data } = await this.axios.post('cajaVentas', this.formApertura);
				this.getCaja();
				this.cargar = false;
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = true;
				this.catchAction(error);
			}
		},
		addCodigo() {

		},
		async addProducto() {
			let { data } = await this.axios.get('venta/producto/' + 1 + '/' + this.producto.id);
			this.addDetalle(data);
			this.producto = '';
		},
		async addProductoCodigo() {
			let { data } = await this.axios.get('venta/producto/' + 2 + '/' + this.codigo);
			this.addDetalle(data);
			this.codigo = '';
		},
		async addProductoCita() {
			let { data } = await this.axios.get('cajaVentas/cita/' + this.cita);

			if (data.producto.id > 0) {
				this.addDetalle(data.producto);
				//medico
				this.venta.medico_id = data.medico_id;				
				this.comisionMedico();
				//seleccionar al cliente
				this.fetchClientes(data.cliente.numDocumento, data.cliente.id);
				this.cita = '';
			} else {
				// @ts-ignore
				return new PNotify({
					text: 'Cita no encontrada',
					type: 'warning',
					styling: 'bootstrap3'
				});
			}
		},
		addDetalle(item) {
			this.venta.detalle.push(item);
		},
		eliminarProducto(index) {
			this.venta.detalle.splice(index, 1);
		},
		showProducto(item) {
			this.productoDetalle = item;
			// @ts-ignore
			$("#modalProducto").modal({ backdrop: 'static', keyboard: false });
		},
		agregarMedioPago() {
			if (this.total_pago >= this.venta.MtoImpVenta) {
				return false;
			}
			let item = {
				medio_id: '',
				importe: '',
				fechaVencimiento: '',
			};

			this.venta.pagos.push(item);
		},
		Pagar() {
			this.calcular();
			let validacion = [];
			//obetemos la serie
			let comprobante = this.comprobanteTipos.find(item => item.id == this.venta.comprobante_tipo_id);
			let serie = this.series.find(item => item.id == this.venta.serie_id);
			this.comprobanteSeleccionado = comprobante.nombre+' - '+ serie.serie;
			//validamos
			if (!this.venta.cliente) {
				validacion.push('Falta Seleccionar al Cliente');
			}
			if (this.venta.detalle.length == 0) {
				validacion.push('No ha ingresado ningun producto o servicio');
			}
			if (validacion.length > 0) {
				// @ts-ignore
				return new PNotify({
					text: validacion.join('<br>'),
					type: 'warning',
					styling: 'bootstrap3'
				});
			}
			// @ts-ignore
			$("#modalPago").modal({ backdrop: 'static', keyboard: false });
		},
		async showCliente() {
			if (this.venta.cliente) {
				let { data } = await this.axios.get('clientes/' + this.venta.cliente.id);
				this.formCliente = data;
			} else {
				this.formCliente = {
					tipo_documento_id: "",
					numDocumento: "",
					razonSocial: "",
					razonComercial: "",
					direccionFiscal: "",
					email: "",
					emailOne: "",
					emailDo: "",
					movil: "",
					fijo: "",
					detraccion: "",
					detalle: "",
					department_id: "",
					province_id: "",
					district_id: "",
				};
			}
			// @ts-ignore
			$("#modalCliente").modal({ backdrop: 'static', keyboard: false });
		},
		async searchCliente() {
			let tipo = 1;

			if (!this.formCliente.tipo_documento_id || !this.formCliente.numDocumento) {
				return false;
			}

			if (this.formCliente.tipo_documento_id != 4) {
				tipo = 2
			}

			let numero = this.formCliente.numDocumento;
			let { data } = await this.axios.get('peru-consult/' + numero + '/' + tipo);
			if (data) {
				this.formCliente.department_id = data.department_id;
				this.formCliente.direccionFiscal = data.direccionFiscal;
				this.formCliente.district_id = data.district_id;
				this.formCliente.province_id = data.province_id;
				this.formCliente.razonComercial = data.razonComercial;
				this.formCliente.razonSocial = data.razonSocial;
			} else {
				this.formCliente.department_id = '';
				this.formCliente.direccionFiscal = '';
				this.formCliente.district_id = '';
				this.formCliente.province_id = '';
				this.formCliente.razonComercial = '';
				this.formCliente.razonSocial = '';
			}
		},
		async submitCliente(e) {
			e.preventDefault();
			let data: any = '';
			this.cargarCliente = true;

			try {
				if (this.formCliente.id > 0) {
					let resp = await this.axios.put('clientes/' + this.formCliente.id, this.formCliente);
					data = resp.data;
				} else {
					let resp = await this.axios.post('clientes', this.formCliente);
					data = resp.data;
					this.fetchClientes(data.data.numDocumento, data.data.id);
				}
				this.cargarCliente = false;
				// @ts-ignore
				$(".modal").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargarCliente = false;
				this.catchAction(error);
			}
		},
		async eliminarPago(index) {
			this.venta.pagos.splice(index, 1);
		},
		limpiarVenta() {
			this.venta = {
				comprobante_tipo_nota_credito_id: '',
				comprobante_tipo_nota_debito_id: '',
				cliente: '',
				fechaEmision: DateTime.now().toISODate(),
				fechaVencimiento: DateTime.now().toISODate(),
				moneda_id: '1',
				comprobante_tipo_id: 2,
				serie_id: '',
				medico_id: '',
				total:  0.00,
				pagado: 0.00,				
				vuelto: 0.00,
				MtoIGV: 0,
				MtoOperGravadas: 0,
				ValorVenta: 0,
				SubTotal: 0,
				MtoImpVenta: 0,
				mtoDescuento: 0,
				TotalImpuestos: 0,
				detalle: [],
				indComisionMedico:'',
				pagos: [
					{
						medio_id: '',
						importe: '',
						fechaVencimiento: '',
					}
				],
				pagoComision: [
					{
						medio_id: '',
						importe: '',
						fechaVencimiento: '',
					}
				]
				
			}
			this.venta.serie_id = this.series[0].id;
		},
		async PagoRapido(importe) {
			let montoCancelar = (this.venta.MtoImpVenta*1) + (this.venta.pagoComision[0].importe*1);
			if (montoCancelar > (importe*1)) {
				return false;
			}
			this.venta.pagos = [];
			let item = {
				medio_id: 1,
				importe: importe,
				fechaVencimiento: '',
			};
			this.venta.pagos.push(item);
			this.venta.vuelto = 0;
			this.PagoComprobante();
		},
		async PagoComprobante() {
			this.cargarPago = true;
			try {
				this.venta.company_id = this.caja.company_id;
				this.venta.caja_id = this.caja.caja_id;
				this.venta.caja_venta_id = this.caja.caja_venta_id;
				this.venta.caja_venta_turno_id = this.caja.id;
				let { data } = await this.axios.post('crearDocumento', this.venta);
				this.cargarPago = false;
				this.limpiarVenta();
				// @ts-ignore
				$("#modalPago").modal('hide');
				this.modalDocumento(data.url);
			} catch (error) {
				this.cargarPago = false;
				this.catchAction(error);
			}

		},
		modalDocumento(url) {
			this.documentoUrl = url + '/1';
			// @ts-ignore
			$("#modalDocumento").modal({ backdrop: 'static', keyboard: false });
		},
		async showCierreCaja() {
			try {
				let { data } = await this.axios.get('cajaVentas/cierre');
				this.cierreMovimientos = data;
				// @ts-ignore
				$("#modalCiere").modal({ backdrop: 'static', keyboard: false });
			} catch (error) {
				this.catchAction(error);

			}
		},
		async cierreCaja() {
			if (!confirm('Confirmar el cierre de caja?')) {
				return false;
			}

			try {
				this.cargarCierre = true;
				let { data } = await this.axios.post('cajaVentas/cierre');
				this.cargarCierre = false;
				// @ts-ignore
				$("#modalCiere").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
				await this.$store.dispatch('setCaja', false);
			} catch (error) {
				this.cargarCierre = false;
				this.catchAction(error);
			}
		},
		async consultaComprobante() {
			if (!this.numComprobante) {
				return false;
			}

			let { data } = await this.axios.get(`comprobanteCaja/${this.caja.ruc}/${this.serie.serie}/${this.numComprobante}`);
			this.venta.detalle = data.detalle;
			this.venta.comprobante_referencia_id = data.id;

		}
	},
	mounted() {
		this.init();
		if (this.caja) {
			this.initCaja();
		}
	},
}
