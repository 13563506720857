
import PageTitle from "../../components/PageTitle.vue";
import { DateTime } from "luxon";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar: false,
			title: "Caja Diarias (Liquidaciones)",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
        	isBusy: false,
			fields: [
				{ key: "index", 		label: "N°",				sortable: false },
				{ key: "numero", 		label: "# Referencia", 		sortable: true },
				{ key: "caja", 			label: "Caja", 				sortable: true },
				{ key: "apertura", 		label: "Apertura", 			sortable: true },
				{ key: "cierre", 		label: "Cierre", 			sortable: true },
				{ key: "inicio", 		label: "Saldo Inicial", 	sortable: true },
				{ key: "fin", 			label: "Saldo Final", 		sortable: true },
				{ key: "caja_turno", 	label: "Codigo Caja", 		sortable: true },
				{ key: "personal_caja", label: "Personal Apertura", sortable: true },
				{ key: "estadoCaja", 	label: "Estado", 			sortable: true },				
				{ key: "estado", 		label: "Liquidación", 		sortable: true },					
			],			
			items: [],
			medicosComision:[],
			filter: null,
			consulta: {
				inicio:DateTime.now().toISODate(),
				fin:DateTime.now().toISODate(),
				personal_id:'',
			},
			documentoUrl: '',
		};
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	methods: {
		async inputFile(e){
			this.file = this.$refs.file.files[0];
		},
		async init(){	
			try {
				this.listar();
			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		async listar(){
			this.isBusy = true;
			try {
				let {data} = await this.axios.get(`liquidacion/cierresCaja/${this.consulta.inicio}/${this.consulta.fin}`);
				this.items = data;	
				this.totalRows = this.items.length;	
				this.isBusy = false;
			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
