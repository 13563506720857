
import PageTitle from "../../components/PageTitle.vue";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar: false,
			consultaTable: true,
			title: "Listado de Pacientes",
			titleForm: "Paciente",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "index", label: "N°", sortable: false },
				{ key: "numDocumento", label: "Num°", sortable: true },
				{ key: "fullname", label: "Paciente", sortable: true },
				{ key: "correo", label: "Correo", sortable: true },
				{ key: "telefono", label: "Telefono", sortable: true },
				{ key: "ubigeo", label: "Ubigeo", sortable: true },
				{ key: "direccion", label: "Direccion", sortable: true },
				{ key: "fechaUltimaVisita", label: "Ult.Atencion", sortable: true },
				{ key: "fechaContactar", label: "Ult.Contactar", sortable: true },
				{ key: "cotizacion", label: "Cotización", sortable: true },
				{ key: "condicion", label: "Condición", sortable: true },
				{ key: "crm", label: "CRM", sortable: true },
				{ key: "reserva", label: "Reserva", sortable: true },],
			items: [],
			filter: null,
			tipoCRMs: [],
			tipoContactos: [],
			protocolos: [],
			formFiltro: {
				tipo: '',
				filtro: '',
			},
			formCRM: {
				id: "",
				paciente_id: "",
				tipo_crm_id: "",
				tipo_contacto_id: "",
				nombres: "",
				telefono: "",
				fecha: "",
				hora: "",
				motivo: "",
				interesado: "",
				volverContactar: "",
				observaciones: "",
			},
			isBusy: false,
		};
	},
	watch: {
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
		numUltimo() {
			let end = this.totalRows;
			let itemsPerPage = this.currentPage * this.perPage;

			if (itemsPerPage < this.totalRows) {
				end = itemsPerPage;
				if (end > this.totalRows) {
					end = this.totalRows;
				}
			}
			return end;
		}
	},
	methods: {
		async init() {
			this.listar();
			let resp: any = '';

			resp = await this.axios.get('select/tipoCRMs');
			this.tipoCRMs = resp.data;

			resp = await this.axios.get('select/tipoContactos');
			this.tipoContactos = resp.data;

		},
		async listar() {
			this.isBusy = true;
			try {
				let url = 'dt_pacientes_marketing';
				if (this.protocolos.filtro) {
					url = url + '/' + this.protocolos.filtro;
				} else {
					url = url + '/0';
				}
				if (this.filter) {
					url = url + '/' + this.filter;
				}
				let { data } = await this.axios.get(url);
				this.items = data;
				this.totalRows = this.items.length;
				if (this.totalRows > 0) {
					this.consultaTable = true;
				}
				this.isBusy = false;
			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		ir(id = 0) {
			this.$router.push({ name: 'ShowPaciente', params: { id: id } });
		},
		resetCRM() {
			this.formCRM = {
				id: "",
				paciente_id: "",
				tipo_crm_id: "",
				tipo_contacto_id: "",
				nombres: "",
				telefono: "",
				fecha: "",
				hora: "",
				motivo: "",
				interesado: "",
				volverContactar: "",
				observaciones: "",
			}
		},
		limpiarCRM() {
			this.formCRM.id = '';
			this.formCRM.fecha = '';
			this.formCRM.hora = '';
			this.formCRM.motivo = '';
			this.formCRM.interesado = '';
			this.formCRM.volverContactar = '';
			this.formCRM.observaciones = '';
		},
		async showCRM(id = 0, item) {
			try {
				this.resetCRM();
				if (id > 0) {
					let { data } = await this.axios.get('crms/paciente/' + id + '/1');
					if (data.id) {
						this.formCRM = data;
					} else {
						this.formCRM.nombres = item.fullname;
						this.formCRM.telefono = item.telefono;
						this.formCRM.paciente_id = id;
					}
				}
				// @ts-ignore
				$("#modalCRM").modal({ backdrop: 'static', keyboard: false })
			} catch (error) {
				this.catchAction(error);
			}
		},
		async submitCRM(e) {
			e.preventDefault();
			let data: any = '';
			this.cargar = true;
			try {
				if (this.formCRM.id > 0) {
					let resp = await this.axios.put('crms/' + this.formCRM.id, this.formCRM);
					data = resp.data;
				} else {
					let resp = await this.axios.post('crms', this.formCRM);
					data = resp.data;
				}
				this.listar();
				this.cargar = false;
				// @ts-ignore
				$("#modalCRM").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
			if (this.totalRows == 0 && this.consultaTable) {
				this.listar();
				this.consultaTable = false;
			}
			if (this.filter == '') {
				this.listar();
			}
		},
		async showCotizaciones(paciente_id) {
			try {
				let { data } = await this.axios.get('protocolos/paciente/' + paciente_id);
				this.protocolos = data;
				// @ts-ignore
				$("#modalCotizacion").modal({ backdrop: 'static', keyboard: false })
			} catch (error) {
				this.catchAction(error);
			}
		},
	},
	filters: {
		moneda: function (value) {
			if (!value) return '';
			value = parseFloat(value);
			return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
		},
		monedaDes: function (value) {
			if (!value) return '';
			value = parseFloat(value);
			return value.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
		}
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
