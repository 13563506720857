
export default {
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
		async logout(){
			this.axios.post('logout');
			await this.$store.dispatch('setUser', null);
			this.$router.push('/');
		}
	},
};
