
import PageTitle from "../../components/PageTitle.vue";
import SetFecha from "../../components/SetFecha.vue";

export default {
	props: ['form'],
	components: { SetFecha },
	data() {
		return {
			cargar: false,
			cargarHistoria: false,
			historias: [],
			HSfields: [
				{ key: "index", label: "N°" },
				{ key: "numero", label: "Acto Médico" },
				{ key: "medico", label: "Medico" },
				{ key: "fecha", label: "Fec.atención" },
				{ key: "tipo_atencion", label: "T.atención" },
				{ key: "protocolo", label: "Cotización" },
				{ key: "receta_medica", label: "Receta" },
				{ key: "laboratorios_clinico", label: "Ex.Laboratorio" },
				{ key: "examenes_imagen", label: "Ex.Imagenes" },
				{ key: "estudio_anomapatologico", label: "Est.Anomapatologico" },
				{ key: "interconsulta", label: "Interconsulta" },
				{ key: "documentos", label: "Galería" },
			],
			HSfilter: '',
			HStotalRows: 1,
			HScurrentPage: 1,
			HSperPage: 10,					
			historiashow: {
				acto_medico: "",
				relatoCronologicos: "",
				parametros: "",
				examenPreferencials: "",
				diagnosticos: "",
				protocolos: "",
				recetaMedicas: "",
				exLaboratorios: "",
				formLabClinicos: "",
				formExImagenes: "",
				formBiopsia: "",
				formInterconsulta: "",
			},
			exLaboratorios: [],
			exImagenes: [],			
			condiciones: [],
			especialidads: [],
			slide: 0,
			srcdocumentoPdf: '',
			documentoUrl: ''
		}
	},
	computed: {
		user() {
			return this.$store.state.user;
		},
		url() {
			return this.$store.state.url;
		},
	},
	methods: {
		async init() {
			this.cargar = true;
			const { data } = await this.axios.get(
				"paciente/historiasClinicas/" + this.form.paciente_id
			);
			this.historias = data;
			this.HStotalRows = this.historias.length;
			this.cargar = false;
		},
		async showHistoria(id, modal) {
			let resp: any;

			if(modal){
				// @ts-ignore
				$("#modalDetalle").modal({ backdrop: "static", keyboard: false });
			}

			resp = await this.axios.get("paciente/historiasClinica/" + id);
			let item = resp.data.data;

			this.historiashow = {
				id: id,
				numero: item.numero,
				medico: item.nomMedico,
				fecha: item.fecha,
				tipo_atencion: item.tipo_atencion,
				tipo_atencion_id: item.tipo_atencion_id,
				paciente: item.nomPaciente,
				edad: item.edad,
				especialidad: item.especialidad,
				detalleAtencion: item.detalleAtencion,
				acto_medico: "",
				relatoCronologicos: "",
				examenPreferencials: "",
				diagnosticos: "",
				protocolos: "",
				recetaMedicas: "",
				formLabClinicos: { examenes: [] },
				formExImagenes: { examen_clinicos: [] },
				formBiopsia: "",
				formInterconsulta: "",
				prev: resp.data.prev,
				next: resp.data.next
			};

			this.cargarHistoria = true;
			if (item.acto_medico_id) {
				resp = await this.axios.get(
					"actoMedicos/historiaClinica/" + item.acto_medico_id
				);
				this.historiashow.acto_medico = resp.data;
			}
	
			resp = await this.axios.get("relatoCronologicos/cita/" + id);
			this.historiashow.relatoCronologicos = resp.data;
			resp = await this.axios.get("parametros/cita/" + id);
			this.historiashow.parametros = resp.data;
			resp = await this.axios.get("examenPreferencials/cita/" + id);
			this.historiashow.examenPreferencials = resp.data;
			resp = await this.axios.get("diagnosticos/historiaClinica/" + id);
			this.historiashow.diagnosticos = resp.data;
			resp = await this.axios.get("protocolos/historiaClinica/" + id);
			this.historiashow.protocolos = resp.data ? resp.data : "";
			resp = await this.axios.get("recetaMedicas/historiaClinica/" + id);
			this.historiashow.recetaMedicas = resp.data ? resp.data : "";
			resp = await this.axios.get("interconsultas/historiaClinica/" + id);
			this.formInterconsulta = resp.data ? resp.data : "";
			resp = await this.axios.get("estudioAnomapatologicos/historiaClinica/" + id);
			this.historiashow.formBiopsia = resp.data ? resp.data : "";
			resp = await this.axios.get("examenesImagens/historiaClinica/" + id);
			this.historiashow.formExImagenes = resp.data ? resp.data : { examen_clinicos: [] };

			resp = await this.axios.get("laboratorioClinicos/historiaClinica/" + id);
			if (resp.data) {
				this.exLaboratorios.forEach((laboratorios) => {
					laboratorios.examenes.forEach((item) => {
						let examen = resp.data.find(
							(examen) => examen.examen_clinico_id == item.id
						);
						if (examen) {
							item.id = examen.examen_clinico_id;
							item.text = examen.text;
							this.historiashow.formLabClinicos.examenes.push(
								examen.examen_clinico_id
							);
						}
					});
				});
			}

			this.cargarHistoria = false;
		},		
		HSonFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.HStotalRows = filteredItems.length;
			this.HScurrentPage = 1;
		},
		modalPdfHistoria(nombre, historia_id) {
			this.srcdocumentoPdf = this.url + "pdf/" + nombre + "/" + historia_id;			
			// @ts-ignore
			$("#modalPdfHistoria").modal({ backdrop: "static", keyboard: false });
		},
		modalDocumentoHistoria(url) {
			this.documentoUrl = url;
			// @ts-ignore
			$("#modalDocumentoHistoria").modal({ backdrop: "static", keyboard: false });
		},
	},
	mounted() {
	}
};
