
import PageTitle from "../../components/PageTitle.vue";
import { DateTime } from "luxon";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar: false,
			title: "Lista de Telemarketing General",
			titleForm: "Registro de Telemarketing General",
			titleFormCierre: "Registro de cierre de seguimiento",
			titleDetalle: "Seguimiento de ingreso a base",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			isBusy: false,
			fecha: '',
			fields: [
				{ key: "index", label: "N°", sortable: false },
				{ key: "codigo", label: "#Ingreso", sortable: true },
				{ key: "fecContacto", label: "F.Ingreso", sortable: true },
				{ key: "tipo", label: "Fuente", sortable: false },
				{ key: "fullName", label: "Paciente", sortable: true },
				{ key: "contacto", label: "Correo + Teléfono", sortable: true },
				{ key: "usuario", label: "Usuario", sortable: true },
				{ key: "fecLlamar", label: "Fec.Contactar", sortable: true },
				{ key: "comentario", label: "Comentarios", sortable: true },
				{ key: "origen", label: "Origen", sortable: true },
				{ key: "estado", label: "Estado", sortable: true },
			],
			items: [],
			itemDetalles: [],
			filter: null,
			form: {
				fullName: '',
				origen_cliente_id: '',
				telefono: '',
				correo: '',
				cie_id:'',
				producto_id: '',
				tipo_id : 1,
				interes: '',
				fecLlamar: '',
				comentario: ''
			},
			formDetalle: {
				fecLlamar: '',
				comentario: ''
			},
			origenClientes: [],
			producto: '',
			productos: [],
			cie: '',
			cies: [],
			fecha_hoy: DateTime.now().toISODate(),
			filterForm: {
				estado: '',
				fuente: '',
			},
			estados: [],
			fuentes: []
		};
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
		itemsFiltro() {
			let items = this.items;
			/* Estado */
			if (this.filterForm.estado) {
				items = items.filter(item => item.estado.includes(this.filterForm.estado));
			}
			/* Fuentes */
			if (this.filterForm.fuente) {
				items = items.filter(item => item.tipo.includes(this.filterForm.fuente));
			}
			this.totalRows = items.length;

			let estados = [];
			let fuentes = [];

			items.forEach(element => {
				estados.push(element.estado);
				fuentes.push(element.tipo);
			});

			this.estados = [...new Set(estados.sort())];
			this.fuentes = [...new Set(fuentes.sort())];

			return items;
		}
	},
	methods: {
		async inputFile(e) {
			this.file = this.$refs.file.files[0];
		},
		async fetchProductos(search, loading) {
			if (search.length > 2) {
				let { data } = await this.axios.get('select/productos/' + search);
				this.productos = data;
			}
		},
		async init() {
			let resp: any;
			try {
				resp = await this.axios.get('select/origenCliente');
				this.origenClientes = resp.data;
				this.listar();
			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		async listar() {
			this.isBusy = true;
			try {
				let url = `pacienteSeguimiento/fecha/2`;

				if(this.fecha){
					url += `/${this.fecha}`;
				}

				let { data } = await this.axios.get(url);
				this.items = data;
				this.totalRows = this.items.length;
				this.isBusy = false;
			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		async listarDetalle() {
			try {
				let { data } = await this.axios.get(`pacienteSeguimientoDetalle/${this.form.id}`);
				this.itemDetalles = data;
			} catch (error) {
				this.catchAction(error);
			}
		},
		reset() {
			this.form = {
				fullName: '',
				origen_cliente_id: '',
				telefono: '',
				correo: '',
				cie_id:'',
				producto_id: '',
				tipo_id : 1,
				interes: '',
				fecLlamar: '',
				comentario: ''
			}
			this.producto = '';
		},
		resetDetalle() {
			this.formDetalle = {
				fecLlamar: '',
				comentario: '',
			}
		},
		async show(id = 0, type = 1) {
			try {
				this.reset();
				if (id > 0) {
					let { data } = await this.axios.get('pacienteSeguimientos/' + id);
					this.form = data;

					if(type == 1){
						this.producto = { id: data.producto_id, label: data.interes };
						this.listarDetalle();
						// @ts-ignore
						$("#modalSeguimientoDetalle").modal({ backdrop: 'static', keyboard: false });
						return true;
					}

					if(type == 2){
						this.form.comentario = '';
						this.form.estado = this.form.isActive; 
						// @ts-ignore
						$("#modalSeguimientoCierre").modal({ backdrop: 'static', keyboard: false });
					}					
				}else{
					// @ts-ignore
					$("#modalSeguimiento").modal({ backdrop: 'static', keyboard: false });
				}
			} catch (error) {
				this.catchAction(error);
			}
		},
		async showDetalle(id){
			try {
				let {data} = await this.axios.get('pacienteSeguimientoDetalles/' + id);
				this.formDetalle = data;				
			} catch (error) {				
				this.catchAction(error);
			}	
		},
		async submit(e) {
			e.preventDefault();
			let data: any = '';
			let resp: any = '';
			this.cargar = true;

			try {
				this.form.interes = this.producto.discripcion;
				this.form.producto_id = this.producto.id;
				this.form.cie_id = this.cie.id;
				this.form.tipo_id = 1;
				
				if (this.form.id > 0) {
					resp = await this.axios.put('pacienteSeguimientos/' + this.form.id, this.form);
					data = resp.data;
				} else {
					resp = await this.axios.post('pacienteSeguimientos', this.form);
					data = resp.data;
				}

				this.cargar = false;
				this.listar();
				// @ts-ignore
				$("#modalSeguimiento").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async submitDetalle(e) {
			e.preventDefault();
			let data: any = '';
			let resp: any = '';
			this.cargar = true;

			try {
				this.formDetalle.paciente_seguimiento_id = this.form.id;
				if (this.formDetalle.id > 0) {
					resp = await this.axios.put('pacienteSeguimientoDetalles/' + this.formDetalle.id, this.formDetalle);
					data = resp.data;
				} else {
					resp = await this.axios.post('pacienteSeguimientoDetalles', this.formDetalle);
					data = resp.data;
				}

				this.cargar = false;
				this.listar();
				this.listarDetalle();
				this.resetDetalle();
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async submitCierre(e) {
			e.preventDefault();			
			this.cargar = true;
			try {
				
				let { data } = await this.axios.put('pacienteSeguimiento/cierre/' + this.form.id, this.form);	
				this.cargar = false;
				this.listar();
				// @ts-ignore
				$("#modalSeguimientoCierre").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},	
		async irCita(){		
			// @ts-ignore	
			$("#modalSeguimientoCierre").modal('hide');
			await this.delaySistema(0.5);
			/* ir a citas */		
			this.$router.push({ name: 'CitaCrear', params: { 
				pacienteSeguimientoId: this.form.id,
				pacienteSeguimientoRoute: 'Telemarketing'
			}})
		},
		async fetchOptionsCies(search, loading) {
			if (search.length > 2) {
				let { data } = await this.axios.get("select/cies/" + search);
				this.cies = data;
			}
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},	
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
