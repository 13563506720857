
import PageTitle from "../../components/PageTitle.vue";
import { DateTime } from "luxon";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar:false,
			title: "Enfermería - Tópico",		
			items: [],
			filter: null,
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "index", 			label: "N°",			sortable: false },
				{ key: "fechaLlegada", 		label: "LLegada",		sortable: false },
				{ key: "paciente", 			label: "Paciente", 		sortable: true },
				{ key: "edad", 				label: "Edad", 			sortable: true },
				{ key: "detalleAtencion", 	label: "Tipo Consulta", sortable: true },
				{ key: "doctor", 			label: "Medico", 		sortable: true },
				{ key: "estado", 			label: "Estado", 		sortable: true }],
			fecha: DateTime.now().toISODate(),
			sede_id:'',
		};
	},
	computed: {
		user() {
			return this.$store.state.user;
		},
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	methods: {
		async init(){
			this.sede_id = this.user.sede_id;
			this.consulta();
		},
		async consulta() {
			try {
				if(!this.fecha){
					return false;
				}
				let url = 'enfermeria/' + this.sede_id + '/' + this.fecha;			
				let { data } = await this.axios.get(url);
				this.items = data;
				this.totalRows = this.items.length;					
			} catch (error) {
				this.catchAction(error);				
			}
		},
		colorCita(estado, disponible, enfermeria, web) {
			let clase = '';
			switch (estado) {
				case 1:
					clase = 'bg-Atendido text-white';
					break;
				case 2:
					clase = 'bg-Espera text-white';
					break;
				case 3:
					clase = 'bg-Confirmado text-white';
					if (web) {
						clase = 'bg-Reserva-Web text-white';
					}
					break;
				case 4:
					clase = 'bg-Reservado text-white';
					if (disponible == 0) {
						clase = 'bg-No-Disponible text-white';
					}
					break;
				case 5:
					clase = 'bg-Disponible';
					if (disponible == 0) {
						clase = 'bg-No-Disponible text-white';
					}
					break;
				case 6:
					clase = 'bg-Bloqueado text-white';
					break;
				default:
					clase = 'bg-No-Disponible text-white';
					break;
			}
			return clase;
		},
		estadoCita(estado, enfermeria, web) {
			let text = '';
			switch (estado) {
				case 1:
					text = 'Atendido';
					break;
				case 2:
					text = 'Espera';
					if (enfermeria) {
						text = 'Espera - Enfermeria';
					}
					break;
				case 3:
					text = 'Confirmado';
					if (web) {
						text = 'Reserva Web';
					}
					break;
				default:
					text = 'error';
					break;
			}
			return text;
		},
		show(id){
			this.$router.push({name:'EnfermeriaShow', params: { id: id }});
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
