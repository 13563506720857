
export default {
  data() {
    return {

    }
  },
  methods: {
    async goToDashboard() {
      let resp: any = '';
      try {

        let { data } = await this.axios.get('user');
        await this.$store.dispatch('setUser', data);

        resp = await this.axios.get('cajaVentas/apertura');
        await this.$store.dispatch('setCaja', resp.data);

      } catch (error) {
        if (error.response) {
          if (error.response.status === 401 && this.$route.path != '/') {
            this.$router.push('/')
          }
        }
      }
    },
  },
  created() {
    if (this.$route.path != '/dashboard') {
      //this.$router.push('/dashboard');
    }
    this.goToDashboard();
  },
  mounted() {
    // Developer
    var baseURL = 'http://127.0.0.1:8000/api/';
    // QA
    if (window.location.origin == 'http://adminclinic.projdevs.com') {
      baseURL = 'http://apiclinic.projdevs.com/api/';
    }
    // Produccion de emergencia
    if (window.location.origin == 'http://admincderma.projdevs.com' || window.location.origin == 'http://www.admincderma.projdevs.com') {
      baseURL = 'http://apicderma.projdevs.com/api/';
    }
    // Produccion
    if (window.location.origin == 'https://admin.cdermaonline.net.pe' || window.location.origin == 'https://www.admin.cdermaonline.net.pe') {
      baseURL = 'https://api.cdermaonline.net.pe/api/';
    }
    // Link Produccion
    if (window.location.origin == 'https://10980085.cdermaonline.net.pe' || window.location.origin == 'https://www.10980085.cdermaonline.net.pe') {
      baseURL = 'https://api.cdermaonline.net.pe/api/';
    }

    this.$store.dispatch('setUrl', baseURL);
  },
};
