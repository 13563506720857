
import PageTitle from "../../components/PageTitle.vue";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar:false,
			title: "Especialidad",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "acciones", 		label: "Acciones",	sortable: false },
				{ key: "nombre", 		label: "Especialidad", 		sortable: true },
				{ key: "importe", 		label: "Importe", 		sortable: true },
				{ key: "importeOnline", label: "Importe Online", 		sortable: true },
				{ key: "estado", 		label: "Estado", 	sortable: true }],
			items: [],
			filter: null,
			monedas: [],
			form: {
				nombre: "",
				file: "",
			},
			file:'',
		};
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	methods: {
		async inputFile(e){
			this.file = this.$refs.file.files[0];
		},
		async init(){
			let resp: any = '';
			try {
				this.listar();

				resp = await this.axios.get('select/monedas');
				this.monedas = resp.data;
			} catch (error) {				
				this.catchAction(error);
			}	
		},
		async listar(){
			try {
				let {data} = await this.axios.get('especialidads');
				this.items = data;	
				this.totalRows = this.items.length;	
			} catch (error) {				
				this.catchAction(error)
			}
		},
		async show(id = 0){
			this.cargar = false;
			try {
				this.reset();
				if(id > 0){
					let {data} = await this.axios.get('especialidads/' + id);
					this.form = data;
				}
				// @ts-ignore
				$(".modal").modal({backdrop: 'static', keyboard: false})
			} catch (error) {				
				this.catchAction(error)
			}	
		},
		async state(item, state){
			let title = 'Activar?';
			let confirmButtonText = 'Si, Activar.';
			if(state == 0){
				title = 'Desactivar?';
				confirmButtonText = 'Si, Desactivar.';
			}

			this.$swal.fire({
				title: title,
				text: item.nombre,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: confirmButtonText,
				showLoaderOnConfirm: true,
				preConfirm: (login) => {
					return this.axios.patch('especialidads/' + item.id+'/state',{isActive: state})
					.then((result) => {
						return result.data;
					}).catch((err) => {
						this.$swal.showValidationMessage(
							`Request failed: ${err}`
						)
					});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {	
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.listar();
				}
			});
		},
		reset(){
			this.file = '';
			this.form = {
				nombre: "",
			}
			let file:any = document.getElementById('file');
			file.value  = '';
		},
		async submit(e){
			e.preventDefault();
			let data:any = '';
			let resp:any = '';
			this.cargar = true;
			try {
				if(this.form.id > 0){
					resp 	= await this.axios.put('especialidads/' + this.form.id, this.form);
					data	= resp.data;
				}else{
					resp 	= await this.axios.post('especialidads', this.form);
					data	= resp.data;
				}
				
				if(this.file && data.data.id > 0){
					this.upload(data.data.id, data.data.imagen_id, 'imagen_id', this.file, 1);
				}

				this.listar();				
			    this.cargar = false;
				// @ts-ignore
				$(".modal").modal('hide');
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargar = false;				
				this.catchAction(error)
			}
		},
		async upload(id, columna_id, columna, file, model){
			let formData = new FormData();
			formData.append('id',id);
			formData.append('columna_id',columna_id);
			formData.append('columna',columna);
			formData.append('file',file);
			formData.append('model', model);
			await this.axios.post('file', formData);
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
