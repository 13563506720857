import { render, staticRenderFns } from "./Notas.vue?vue&type=template&id=ad392cd4&scoped=true"
import script from "./Notas.vue?vue&type=script&lang=ts"
export * from "./Notas.vue?vue&type=script&lang=ts"
import style0 from "./Notas.vue?vue&type=style&index=0&id=ad392cd4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad392cd4",
  null
  
)

export default component.exports