
import PageTitle from "../../components/PageTitle.vue";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar:false,
			title: "Producto",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "index", 		label: "N°",			sortable: false },
				{ key: "acciones", 		label: "Acciones",		sortable: false },
				{ key: "codigo", 		label: "Codigo",		sortable: false },
				{ key: "discripcion", 	label: "Producto", 		sortable: true },
				{ key: "categoria", 	label: "Categoria", 	sortable: true },
				{ key: "presentacion", 	label: "Presentacion", 	sortable: true },
				{ key: "marca", 		label: "Marca", 		sortable: true },
				{ key: "moneda", 		label: "Moneda", 		sortable: true },				
				{ key: "precio_venta", 	label: "Precio Venta", 	sortable: true },				
				{ key: "estado", 		label: "Estado", 		sortable: true }],
			items: [],
			filter: null,
		};
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	methods: {
		async init(){
			this.listar();
		},
		async listar(){
			try {
				let {data} = await this.axios.get('productos');
				this.items = data;	
				this.totalRows = this.items.length;	
			} catch (error) {
				this.catchAction(error);
			}
		},
		async state(item, state){
			let title = 'Activar?';
			let confirmButtonText = 'Si, Activar.';
			if(state == 0){
				title = 'Desactivar?';
				confirmButtonText = 'Si, Desactivar.';
			}

			this.$swal.fire({
				title: title,
				text: item.nombre,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: confirmButtonText,
				showLoaderOnConfirm: true,
				preConfirm: (login) => {
					return this.axios.patch('productos/' + item.id+'/state',{isActive: state})
					.then((result) => {
						return result.data;
					}).catch((err) => {
						this.$swal.showValidationMessage(
							`Request failed: ${err}`
						)
					});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {	
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.listar();
				}
			});
		},
		ir(id){
			this.$router.push({
				name:'ShowProducto', 
				params: { 
					id: id
					}
				});
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();		
	},
};
