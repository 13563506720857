
import PageTitle from "../../components/PageTitle.vue";
import { DateTime } from "luxon";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar: false,
			title: "Base de estado de Citas: RECUPERACIÓN",
			titleForm: "Seguimiento de Recuperación",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "index", 				label: "N°",				sortable: false },
				{ key: "fechaHora", 			label: "Fecha - Hora", 	sortable: true },
				{ key: "paciente", 			label: "Paciente", 		sortable: true },
				{ key: "doctor", 				label: "Médico", 			sortable: true },
				{ key: "especialidad", 		label: "Especialidad", 	sortable: true },
				{ key: "usuarioCita", 		label: "Usuario", 		sortable: true },
				{ key: "detalleAtencion", 	label: "Atencion", 		sortable: true },
				{ key: "estado", 				label: "Estado", 			sortable: true },			
				{ key: "acciones", 			label: "Acción",			sortable: false },],
			items: [],
			filter: null,
			tipoDocumentos:[],
			formConsulta: {
				sede_id: '',
				finicio: DateTime.now().minus({ days: 1 }).toISODate(),
				ffin: DateTime.now().toISODate(),
				estado_cita_id: ''
			},		
			form: {
				fullName: '',
				origen_cliente_id: '',
				telefono: '',
				correo: '',
				producto_id: '',
				interes: '',
				fecLlamar: '',
				comentario: ''
			},
			itemDetalles: [],
			formDetalle: {
				fecLlamar: '',
				comentario: ''
			},	
			itemAnulado: {
				user_anulo: '',
				detalleAnulado: '',
			}
		};
	},
	computed: {
		user(){
			return this.$store.state.user;  
		},
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
		totales(){
			let total = {
				anulado : 0,
				reservado : 0,
				reservadoWeb : 0,
				confimado  : 0,
				espera : 0,
				atendido : 0,
			}

			this.items.forEach(element => {
				let cita_estado_id = element.cita_estado_id;
				
				switch (cita_estado_id) {
					case 1:
					total.atendido += 1;
						break;
					case 2:
					total.espera += 1;						
						break;
					case 3:
						if(element.isWeb == 1){
							total.reservadoWeb += 1;	
						}else{
							total.confimado += 1;	
						}					
						break;
					case 4:
					total.reservado += 1;							
						break;
					case 0:
					total.anulado += 1;							
						break;
				}
			});

			return total;
		}
	},
	methods: {
		async init(){
			this.formConsulta.sede_id = this.user.sede_id;
			let resp:any = '';

			resp = await this.axios.get('select/tipoDocumentos');
			this.tipoDocumentos = resp.data;

			resp = await this.axios.get('select/tipoAtencions');
			this.tipoAtencions = resp.data;
			
			resp = await this.axios.get('select/tratamientoUbicacionCorporals');
			this.tratamiento_ubicacion_corporals = resp.data;

			this.listar();
		},
		async listar(){
			try {
				let {data} = await this.axios.post('paciente/recuperacion', this.formConsulta);
				this.items = data;	
				this.totalRows = this.items.length;	
			} catch (error) {
				this.catchAction(error);
			}
		},
		async submitConsulta(e){
			e.preventDefault();
			this.listar();
		},	
		resetDetalle() {
			this.formDetalle = {
				fecLlamar: '',
				comentario: '',
			}
		},
		async listarDetalle() {
			try {
				let { data } = await this.axios.get(`pacienteSeguimientoDetalle/${this.form.id}`);
				this.itemDetalles = data;
			} catch (error) {
				this.catchAction(error);
			}
		},
		async showDetalle(id){
			try {
				let {data} = await this.axios.get('pacienteSeguimientoDetalles/' + id);
				this.formDetalle = data;				
			} catch (error) {				
				this.catchAction(error);
			}	
		},
		showAnulado(item){
			this.itemAnulado.user_anulo  = '';
			this.itemAnulado.detalleAnulado  = '';

			this.itemAnulado.user_anulo = item.user_anulo;
			this.itemAnulado.detalleAnulado = item.detalleAnulado;
			// @ts-ignore
			$("#modalAnulado").modal({ backdrop: 'static', keyboard: false });
		},
		async show(id = 0) {
			let resp: any = '';
			let data: any = '';

			try {
				resp = await this.axios.get('seguimientoPacienteShow/' + id);
				if (!resp.data) {
					if (confirm('Programar un seguimiento al paciente?')) {
						resp = await this.axios.post('recuperacionPaciente/' + id);
						this.form = resp.data.data;
						this.listarDetalle();
						// @ts-ignore
						$("#modalSeguimientoDetalle").modal({ backdrop: 'static', keyboard: false });
					}
				} else {
					this.form = resp.data;
					this.listarDetalle();
					// @ts-ignore
					$("#modalSeguimientoDetalle").modal({ backdrop: 'static', keyboard: false });
				}
			} catch (error) {
				this.catchAction(error);
			}
		},
		async submitDetalle(e) {
			e.preventDefault();
			let data: any = '';
			let resp: any = '';
			this.cargar = true;

			try {
				this.formDetalle.paciente_seguimiento_id = this.form.id;

				if (this.formDetalle.id > 0) {
					resp = await this.axios.put('pacienteSeguimientoDetalles/' + this.formDetalle.id, this.formDetalle);
					data = resp.data;
				} else {
					resp = await this.axios.post('pacienteSeguimientoDetalles', this.formDetalle);
					data = resp.data;
				}

				this.cargar = false;
				this.listar();
				this.listarDetalle();
				this.resetDetalle();
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
