
import PageTitle from "../../components/PageTitle.vue";

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
	components: { PageTitle, vueDropzone: vue2Dropzone },
	data() {
		return {
			cargar:false,
			title: "Registro de Tópico / Enfermería",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			ac:'',
			hc:'',
			llegada:'',
			medico:'',
			paciente:'',
			tipo_atencion:'',
			fileDocumento:'',
			galeria: [],
			form: {
				temperatura: "",
				presion: "",
				estatura: "",
				peso: "",
				imc: "",
			},
			enfermeria: 0,
			dropzoneOptions: {
				withCredentials: true,
				url: 'actoMedicos/galeria/',
				thumbnailWidth: 150,
				maxFilesize: 1,
				headers: { "My-Awesome-Header": "header value" },
				dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>Subir Imagen"
			},
			slide: 0,
        	sliding: null
		};
	},
	watch: {
		async "form.estatura"(newValor, oldValor) {
			if(this.form.peso > 0 && this.form.estatura > 0){
				this.form.imc = (this.form.peso/Math.pow(this.form.estatura/100,2)).toFixed(2);
			}else{
				this.form.imc = 0;
			}
		},
		async "form.peso"(newValor, oldValor) {
			if(this.form.peso > 0 && this.form.estatura > 0){
				this.form.imc = (this.form.peso/Math.pow(this.form.estatura/100,2)).toFixed(2);
			}else{
				this.form.imc = 0;
			}
		},
	},
	computed: {
		url(){
			return this.$store.state.url;  
		}
	},
	methods: {
		successImagen(){
			this.getGaleria();
		},
		async init(){
			try {
				// obtememos data del paciente
				let {data} = await this.axios.get('enfermeria/' + this.$route.params.id);
				this.form = data.form;
				this.ac = data.ac;
				this.hc = data.hc;
				this.llegada = data.llegada;
				this.medico = data.medico;
				this.paciente = data.paciente;
				this.tipo_atencion = data.tipo_atencion;
				this.fileDocumento = data.file;	
				this.enfermeria = data.enfermeria;			
				// reload dropzone		
				let url = this.url+'actoMedicos/galeria/'+this.form.id;	
				this.$refs.myVueDropzone.setOption('url',url);

				this.getGaleria();
			} catch (error) {				
				this.catchAction(error);
			}
		
		},
		modalDocumento(){
			// @ts-ignore
			$("#documento").modal({backdrop: 'static', keyboard: false});
		},
		showModal(){
			// @ts-ignore
			$("#galeria").modal({backdrop: 'static', keyboard: false});
		},
		async inputFile(e){
			this.file = this.$refs.file.files[0];		
		},
		async upload(id, columna_id, columna, file, model, eliminar = false){	
			let resp:any = '';		
			try {
				let formData = new FormData();
				formData.append('id',id);
				formData.append('columna_id',columna_id);
				formData.append('columna',columna);
				formData.append('file',file);
				formData.append('model', model);
				if(eliminar){
					resp = await this.axios.post('fileDelete', formData);
				}else{
					resp = await this.axios.post('file', formData);
				}

				// @ts-ignore
				new PNotify({
					text: resp.data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});

				//obtener
				resp = await this.axios.get('enfermeria/' + this.$route.params.id);	
				this.form = resp.data.form;
				this.fileDocumento = resp.data.file;			

			} catch (error) {
				this.catchAction(error);				
			}
		},
		async getGaleria(){
			let {data} = await this.axios.get('actoMedicos/galeria/'+this.form.id);
			this.galeria = data;
		},
		async submit(e){
			e.preventDefault();
			let data:any = '';
			let resp:any = '';
			this.cargar = true;	
					
			try {
				resp 	= await this.axios.put('actoMedicos/' + this.form.id, this.form);
				data	= resp.data;

				if(this.file && data.data.id > 0){
					await this.upload(data.data.id, data.data.documento_id, 'documento_id', this.file, 4);
				}
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
			this.cargar = false;
		},
		atendido(){
			this.$swal.fire({
				title: 'Paciente atendido en enfermeria?',
				text: this.paciente,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Si, confirmar',
				showLoaderOnConfirm: true,
				preConfirm: (data) => {
					return this.axios.patch('citas/atendido/' + this.$route.params.id)
						.then((result) => {
							return result.data;
						}).catch((err) => {
							this.$swal.showValidationMessage(
								`Request failed: ${err}`
							)
						});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					this.$swal.fire(
						'Atendido',
						result.value.msg,
						'success'
					)
					this.$router.push({name:'Enfermeria'});
				}
			});	
		},
		async eliminar(url){
			await this.axios.post('actoMedicos/galeria_eliminar', {url});
			this.getGaleria();
		},
		async eliminarDocumento(id){
			await this.upload(this.form.id, this.form.documento_id, 'documento_id', null, 4, 1);
			//obtener
			let resp = await this.axios.get('enfermeria/' + this.$route.params.id);	
			this.form = resp.data.form;
			this.fileDocumento = resp.data.file;			
		}
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
