
import PageTitle from "../../components/PageTitle.vue";
import { DateTime } from "luxon";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar:false,
			title: "Notas",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
        	isBusy: false,
			fields: [
				{ key: "index", 				label: "N°",		sortable: false },
				{ key: "acciones", 				label: "Acciones",	sortable: false },
				{ key: "fechaEmision", 			label: "Emision", 	sortable: true },
				{ key: "cliente", 				label: "Cliente", 	sortable: true },
				{ key: "numero", 				label: "Numero", 	sortable: true },
				{ key: "moneda", 				label: "Moneda", 	sortable: true },
				{ key: "mtoOperGravadas", 		label: "T.Gravado", sortable: true },
				{ key: "mtoImpVenta", 			label: "Total", 	sortable: true },
				{ key: "rutaXml", 				label: "XML", 		sortable: true },
				{ key: "rutaCDR", 				label: "CDR", 		sortable: true },
				{ key: "urlComprobante", 		label: "PDF", 		sortable: true },
				{ key: "respSunat", 			label: "Sunat",     sortable: true },
				{ key: "estado", 				label: "Estado",     sortable: true },
				],
			columns : [
				{ field: "cliNumDoc", 			label: "Num Doc", 			 },
				{ field: "cliRznSocial", 		label: "Razon Social", 		 },
				{ field: "comprobante_tipo", 	label: "Comprobante Tipo", 	 },
				{ field: "fechaEmision", 		label: "fecha Emision", 	 },
				{ field: "serie", 				label: "Serie", 			 },
				{ field: "correlativo", 		label: "Comprobante", 		 },
				{ field: "moneda", 				label: "Fecha", 			 },
				{ field: "mtoOperGravadas", 	label: "mto Oper Gravadas",  },
				{ field: "mtoImpVenta", 		label: "mto Imp Venta", 	 },
				{ field: "rutaXml", 			label: "XML", 				 },
				{ field: "rutaCDR", 			label: "CDR", 				 },
				{ field: "respSunat", 			label: "resp Sunat", 		 },
			],
			items: [],
			pacienteMedicos:[],
			filter: null,
			documentoUrl: '',
			form: {
				tipoComprobante: '',
				serie: '',
				numero: '',
				fecInicio:'',
				fecFin:'',
				razSocial: '',
				estado:'',
				fecEmision:''
			},
			comprobanteTipos: [],
			series: []
		};
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
		user(){
			return this.$store.state.user;  
		}
	},
	methods: {
		async init(){	
			let resp:any = '';

			resp = await this.axios.get('select/comprobanteTipos/2');
			this.comprobanteTipos = resp.data;

			resp = await this.axios.get('select/series');
			this.series = resp.data;
			

			this.listar();
		},
		async listar(){
			this.isBusy = true;
			try {
				let {data} = await this.axios.post(`comprobantes/2`, this.form);
				this.isBusy = false;
				this.items = data;	
				this.totalRows = this.items.length;				
			} catch (error) {
				this.catchAction(error);
			}
		},
		reset(){
			this.form = {
				nombre: "",
			}
		},
		modalDocumento(url){
			this.documentoUrl = url+'/1';
			// @ts-ignore
			$("#modalDocumento").modal({backdrop: 'static', keyboard: false});
		},
		eliminarComprobante(item) {
			this.$swal.fire({
				title: 'Eliminar?',
				text: item.comprobante,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Si, eliminar',
				showLoaderOnConfirm: true,
				preConfirm: (data) => {
					return this.axios.delete('comprobantes/' + item.id)
						.then((result) => {
							return result.data;
						}).catch((err) => {
							this.$swal.showValidationMessage(
								`Request failed: ${err}`
							)
						});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.listar();
				}
			});
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
