
import PageTitle from "../../components/PageTitle.vue";
import SetFecha from "../../components/SetFecha.vue";

import { DateTime } from "luxon";

export default {
	components: { PageTitle, SetFecha },
	data() {
		return {
			cargar: false,
			title: "Programación Médica",
			doctores: [],
			programaciones: [],
			sedes: [],
			consultorios: [],
			especialidads: [],
			form: {
				sede_id: '',
				doctor_id: '',
				consultorio_id: '',
				especialidad_id: '',
				indProgramacion: 0,
				nombre: '',
				indFecha: 1,
				ignorar: [],
				intervalo: 20,
				finicio: '',
				ffin: '',
				hprogramadaInicio: '',
				hprogramadaFin: '',
				hprogramadaIgnoradoInicio: '',
				hprogramadaIgnoradoFin: '',
				fecha_horarios: []
			},
			formFechaNoLaborable: {
				fecha: ''
			},
			fechaNoLaborables: []
		}
	},
	watch: {
		"form.indProgramacion": function (newValue, oldValue) {
			if (newValue > 0) {
				let data = this.programaciones.find(item => item.id == newValue);
				this.form.nombre = data.nombre;
				this.form.hprogramadaInicio = data.hprogramadaInicio;
				this.form.hprogramadaFin = data.hprogramadaFin;
				this.form.hprogramadaIgnoradoFin = data.hprogramadaIgnoradoFin;
				this.form.hprogramadaIgnoradoInicio = data.hprogramadaIgnoradoInicio;
				this.form.indFecha = data.indFecha;
				this.form.intervalo = data.intervalo;
				this.form.ignorar = JSON.parse(data.ignorar);
			}
		},
		async "form.sede_id"(newValue, oldValue) {
			if (newValue > 0) {
				let { data } = await this.axios.get('select/consultorios/sede_id/' + newValue);
				this.consultorios = data;
			}
		},
		async "form.especialidad_id"(newValue, oldValue) {
			let { data } = await this.axios.get('select/medico/' + this.form.sede_id + '/' + this.form.especialidad_id);
			this.doctores = data;
		}
	},
	methods: {
		async init() {
			let resp: any = '';

			this.listaFechaNoLaborable();
			this.listaProgramacion();

			resp = await this.axios.get('select/sedes_user');
			this.sedes = resp.data;

			resp = await this.axios.get('select/especialidads');
			this.especialidads = resp.data;

		},
		async listaFechaNoLaborable() {
			let { data } = await this.axios.get('fechaNoLaborables');
			this.fechaNoLaborables = data;
		},
		async listaProgramacion() {
			let { data } = await this.axios.get('select/programaciones');
			this.programaciones = data;
		},
		async proceso() {
			let inicio = DateTime.fromISO(this.form.hprogramadaInicio);
			let fin = DateTime.fromISO(this.form.hprogramadaFin);
			let igInicio = DateTime.fromISO(this.form.hprogramadaIgnoradoInicio);
			let igFin = DateTime.fromISO(this.form.hprogramadaIgnoradoFin);

			let intervalo = this.form.intervalo;
			fin = fin.minus({ minutes: intervalo });
			// horarios
			let horarios = [];
			let hora = inicio.toFormat('T');
			horarios.push(hora);
			do {
				inicio = inicio.plus({ minutes: intervalo });
				if (inicio.toFormat('T') == igInicio.toFormat('T')) {
					hora = inicio.toFormat('T');
					horarios.push(hora);
					inicio = igFin;
				}
				// agrega  
				hora = inicio.toFormat('T');
				horarios.push(hora);
			} while (inicio < fin);

			//fechas
			let fechas = [];
			if (this.form.indFecha == 1) {
				fechas.push(this.form.finicio);
			} else {
				let inicio = DateTime.fromISO(this.form.finicio);
				let fin: any = '';
				if (this.form.indFecha == 2) {
					//semana
					let dia = inicio.toFormat('c');
					if (dia < 7) {
						fin = inicio.plus({ days: 7 - dia });
					} else {
						fin = inicio;
					}
				}
				if (this.form.indFecha == 3) {
					//mes
					let mes = inicio.toFormat('L') * 1;
					let year = inicio.toFormat('y') * 1;
					fin = DateTime.local(year, mes + 1, 1).minus({ days: 1 });
				}
				if (this.form.indFecha == 4) {
					// calendario
					fin = DateTime.fromISO(this.form.ffin);
				}

				let fecha = inicio.toISODate();
				fechas.push(fecha);
				do {
					inicio = inicio.plus({ day: 1 });
					fecha = inicio.toISODate();
					// buscar si la fecha esta en los fechas no laborables
					let buscarfechaNoLaborables = this.fechaNoLaborables.filter(item => item.fecha == fecha);
					let buscarDiaFecha = this.form.ignorar.filter(item => item == inicio.toFormat('c'))
					//validar
					if (buscarfechaNoLaborables.length == 0 && buscarDiaFecha.length == 0) {
						fechas.push(inicio.toISODate());
					}
				} while (inicio < fin);
			}
			let fecha_horarios = [];

			fechas.forEach(fecha => {
				let object = {
					fecha: fecha,
					horarios: horarios
				}
				fecha_horarios.push(object);
			});
			this.form.fecha_horarios = fecha_horarios;
		},
		deleteHora(index, index2) {
			let aHorarios = JSON.parse(JSON.stringify(this.form.fecha_horarios[index].horarios));
			let aFecha = JSON.parse(JSON.stringify(this.form.fecha_horarios));

			aHorarios.splice(index2, 1);
			this.form.fecha_horarios[index].horarios = aHorarios;
			if (aHorarios.length == 0) {
				aFecha.splice(index, 1);
				this.form.fecha_horarios = aFecha;
			}
		},
		async submit(e) {
			e.preventDefault();
			let data: any = '';
			this.cargar = true;
			try {
				let resp = await this.axios.post('fechaNoLaborables', this.formFechaNoLaborable);
				data = resp.data;
				this.listaFechaNoLaborable();
				this.formFechaNoLaborable.fecha = '';
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				if (error.response) {
					let resp = error.response;
					if (resp.status == 404) {
						// @ts-ignore
						return new PNotify({
							text: 'Error 404',
							type: 'error',
							styling: 'bootstrap3'
						});
					}
				}
				// @ts-ignore
				return new PNotify({
					text: error.message,
					type: 'error',
					styling: 'bootstrap3'
				});
			}
			this.cargar = false;
		},
		limpiar() {
			this.form = {
				sede_id: '',
				doctor_id: '',
				consultorio_id: '',
				especialidad_id: '',
				indProgramacion: 0,
				nombre: '',
				indFecha: 1,
				ignorar: [],
				intervalo: 20,
				finicio: '',
				ffin: '',
				hprogramadaInicio: '',
				hprogramadaFin: '',
				hprogramadaIgnoradoInicio: '',
				hprogramadaIgnoradoFin: '',
				fecha_horarios: []
			}
		},
		async submitFechaNoLaborable(e) {
			e.preventDefault();
			let data: any = '';
			this.cargar = true;

			try {
				let resp = await this.axios.post('fechaNoLaborables', this.formFechaNoLaborable);
				data = resp.data;
				this.listaFechaNoLaborable();
				this.form.doctor_id = '';
				this.form.fecha_horarios = [];
				this.form.indProgramacion = data.indProgramacion;
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				if (error.response) {
					let resp = error.response;
					if (resp.status == 404) {
						// @ts-ignore
						return new PNotify({
							text: 'Error 404',
							type: 'error',
							styling: 'bootstrap3'
						});
					}
				}
				// @ts-ignore
				return new PNotify({
					text: error.message,
					type: 'error',
					styling: 'bootstrap3'
				});
			}
			this.cargar = false;
		},
		async deleteFechaNoLaborable(item) {
			let data: any = '';
			this.cargar = true;

			try {
				await this.axios.delete('fechaNoLaborables/' + item.id);
				this.listaFechaNoLaborable();
				this.formFechaNoLaborable.fecha = '';
			} catch (error) {
				if (error.response) {
					let resp = error.response;
					if (resp.status == 404) {
						// @ts-ignore
						return new PNotify({
							text: 'Error 404',
							type: 'error',
							styling: 'bootstrap3'
						});
					}
				}
				// @ts-ignore
				return new PNotify({
					text: error.message,
					type: 'error',
					styling: 'bootstrap3'
				});
			}
			this.cargar = false;
		},
		async submitFechas(e) {
			e.preventDefault();
			let medico = this.doctores.find(item => item.id == this.form.doctor_id);

			if(!confirm('Crear la programación para el médico: '+ medico.fullnamedoc)){
				return false;
			}

			let data: any = '';
			this.cargar = true;
			try {
				let resp = await this.axios.post('horarios', this.form);
				data = resp.data;
				this.listaProgramacion();
				this.form.doctor_id = '';
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				if (error.response) {
					let resp = error.response;
					if (resp.status == 404) {
						// @ts-ignore
						return new PNotify({
							text: 'Error 404',
							type: 'error',
							styling: 'bootstrap3'
						});
					}
				}
				// @ts-ignore
				return new PNotify({
					text: error.message,
					type: 'error',
					styling: 'bootstrap3'
				});
			}
			this.cargar = false;
		}
	},
	mounted() {
		this.init();
	},
}
