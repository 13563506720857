
const { DateTime } = require("luxon");

export default {
	props:['fecha','tipo'],
	computed: {
		setFecha(){
			const dt = DateTime.fromSQL(this.fecha);
			if(this.tipo == 1){
				return dt.toFormat('dd/LL/yyyy HH:mm');
			}else{
				return dt.toFormat('dd/LL/yyyy');
			}
		}
	},
}
