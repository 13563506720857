
import PageTitle from "../../components/PageTitle.vue";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar: false,
			title: "Reactivación de pacientes",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			isBusy: false,
			filter: null,
			medicos: [],
			pacientes: [],
			estados: [],
			form: {
				fullName: '',
				origen_cliente_id: '',
				telefono: '',
				correo: '',
				producto_id: '',
				interes: '',
				fecLlamar: '',
				comentario: ''
			},
			filterForm: {
				medico: '',
				paciente: '',
				estado: ''
			},
			itemDetalles: [],
			formDetalle: {
				fecLlamar: '',
				comentario: ''
			},
			fields: [
				{ key: "index", label: "N°", sortable: false },
				{ key: "codigo", label: "Codigo", sortable: false },
				{ key: "medico", label: "Medico", sortable: true },
				{ key: "paciente", label: "Paciente", sortable: true },
				{ key: "contacto", label: "Contacto", sortable: true },
				{ key: "fecha", label: "Fecha", sortable: true },
				{ key: "atencion", label: "Atencion", sortable: true },
				{ key: "estado", label: "Estado", sortable: true },
				{ key: "usuario", label: "Usuario", sortable: true },				
				{ key: "acciones", label: "Acciones", sortable: false },
			],
			items: [],
			formRevision: {
				isActive: '',
				comentario: '',
			},
			itemRevisiones: []
		}
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
		itemsFiltro() {
			let items = this.items;

			/* Medicos */
			if (this.filterForm.medico) {
				items = items.filter(item => item.medico.includes(this.filterForm.medico));
			}
			/* Pacientes */
			if (this.filterForm.paciente) {
				items = items.filter(item => item.paciente.includes(this.filterForm.paciente));
			}
			/* Estado */
			if (this.filterForm.estado) {
				items = items.filter(item => item.estado.includes(this.filterForm.estado));
			}

			this.totalRows = items.length;

			let pacientes = [];
			let medicos = [];
			let estados = [];

			items.forEach(element => {
				medicos.push(element.medico);
				pacientes.push(element.paciente);
				estados.push(element.estado);
			});

			this.medicos = [...new Set(medicos.sort())];
			this.pacientes = [...new Set(pacientes.sort())];
			this.estados = [...new Set(estados.sort())];

			return items;
		}
	},
	methods: {
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
		async listar() {
			this.isBusy = true;
			try {
				let { data } = await this.axios.get(`reactivacionSeguimiento`);
				this.items = data;
				this.isBusy = false;
			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		resetDetalle() {
			this.formDetalle = {
				fecLlamar: '',
				comentario: '',
			}
		},
		async listarDetalle() {
			try {
				let { data } = await this.axios.get(`pacienteSeguimientoDetalle/${this.form.id}`);
				this.itemDetalles = data;
			} catch (error) {
				this.catchAction(error);
			}
		},
		async showDetalle(id) {
			try {
				let { data } = await this.axios.get('pacienteSeguimientoDetalles/' + id);
				this.formDetalle = data;
			} catch (error) {
				this.catchAction(error);
			}
		},
		async show(id = 0) {
			let resp: any = '';
			let data: any = '';

			try {
				resp = await this.axios.get('seguimientoPacienteShow/' + id);
				if (!resp.data) {
					if (confirm('Programar un seguimiento al paciente?')) {
						resp = await this.axios.post('reactivacionPaciente/' + id);
						this.form = resp.data.data;
						this.listarDetalle();
						// @ts-ignore
						$("#modalSeguimientoDetalle").modal({ backdrop: 'static', keyboard: false });
					}
				} else {
					this.form = resp.data;
					this.listarDetalle();
					// @ts-ignore
					$("#modalSeguimientoDetalle").modal({ backdrop: 'static', keyboard: false });
				}
			} catch (error) {
				this.catchAction(error);
			}
		},
		async submitDetalle(e) {
			e.preventDefault();
			let data: any = '';
			let resp: any = '';
			this.cargar = true;

			try {
				this.formDetalle.paciente_seguimiento_id = this.form.id;

				if (this.formDetalle.id > 0) {
					resp = await this.axios.put('pacienteSeguimientoDetalles/' + this.formDetalle.id, this.formDetalle);
					data = resp.data;
				} else {
					resp = await this.axios.post('pacienteSeguimientoDetalles', this.formDetalle);
					data = resp.data;
				}

				this.cargar = false;
				this.listar();
				this.listarDetalle();
				this.resetDetalle();
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async revision(item, isActive) {
			let resp: any = '';

			this.formRevision.paciente = item.paciente;
			this.formRevision.paciente_id = item.id;
			this.formRevision.comentario = '';
			this.formRevision.isActive = isActive;

			let { data } = await this.axios.get(`pacienteRevisados/${item.id}`);
			this.itemRevisiones = data;

			// @ts-ignore
			$("#modalRevisionDetalle").modal({ backdrop: 'static', keyboard: false });
		},
		async submitRevisado(e) {
			e.preventDefault();
			this.cargar = true;
			try {
				let {data} = await this.axios.post('pacienteRevisados', this.formRevision);
				this.cargar = false;
				this.listar();
				// @ts-ignore
				$("#modalRevisionDetalle").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
	},
	mounted() {
		this.listar();
	},
}
