
import PageTitle from "../../components/PageTitle.vue";
import SetFecha from "../../components/SetFecha.vue";

export default {
	props: ['numDocumento'],
	components: { SetFecha },
	data() {
		return {
			cargar: false,
			HSfilter: '',
			HStotalRows: 1,
			HScurrentPage: 1,
			HSperPage: 10,
			historias: [],
			HSfields: [
				{ key: "index", label: "N°" },
				{ key: "numero", label: "Acto Médico" },
				{ key: "medico", label: "Medico" },
				{ key: "fecha", label: "Fec.atención" },
				{ key: "tipo_atencion", label: "T.atención" }
			],
			actoMedico: '',
			Detalle: {
				antecedentes: '',
				cotizacion: '',
				receta: '',
				diagnostico: '',
				examen: '',
				historia: '',
				imagenes: '',
				files: '',
				next: '',
				prev: '',
			},
			cargarHistoria: false
		}
	},
	computed: {
		user() {
			return this.$store.state.user;
		}
	},
	methods: {
		async init() {
			this.cargar = true;
			const { data } = await this.axios.get("bdcderma/historias/" + this.numDocumento);
			this.historias = data;
			this.HStotalRows = data.length;
			this.cargar = false;
		},
		async showHistoria(id_acto_medico, modal = false) {
			this.cargarHistoria = true;
			if (modal) {
				// @ts-ignore
				$("#modalDetalleCderma").modal({ backdrop: "static", keyboard: false });
			}
			const { data } = await this.axios.get("bdcderma/acto_medico/" + id_acto_medico + '/' + this.numDocumento);
			this.Detalle = data;
			this.actoMedico = data.actoMedico;
			this.cargarHistoria = false;
		},
		HSonFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.HStotalRows = filteredItems.length;
			this.HScurrentPage = 1;
		},
	},
	mounted() {
	}
};
