
import PageTitle from "../components/PageTitle.vue";
import BarChart from '../components/charjs/BarChart.vue';

export default {
	components: { PageTitle, BarChart },
	data() {
		return {
			cargar: false,
			title: "Dashboard",
			items: [],
			chartData: {},
			medicos: [],
			medico_id: 0,
			interval: ""
		};
	},
	computed: {},
	methods: {
		async init() {
			let resp;

			resp = await this.axios.get("select/medicos");
			this.medicos = resp.data;
		},
		async consulta() {
			let url = '';
			if (this.medico_id > 0) {
				url = '/' + this.medico_id;
			}
			const { data } = await this.axios.get("dashboard" + url);
			this.items = data;
		},
		async consultaCompare() {
			let url = '';
			if (this.medico_id > 0) {
				url = '/' + this.medico_id;
			}
			const { data } = await this.axios.get("dashboards/comparaAnterior" + url);
			this.chartData = data;
		},
		selectMedico() {
			this.consulta();
			this.consultaCompare();
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
		this.selectMedico();
		this.interval = setInterval(async (self) => {
			let resp: any = '';
			let url = '';
			if (this.medico_id > 0) {
				url = '/' + this.medico_id;
			}
			resp = await this.axios.get("dashboard" + url);
			this.items = resp.data;

			resp = await this.axios.get("dashboards/comparaAnterior" + url);
			this.chartData = resp.data;
		}, 15000);
	},
	destroyed() {
		clearInterval(this.interval);
	},
};
