
import PageTitle from "../../components/PageTitle.vue";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar:false,
			title: "REGISTRO DE INGRESOS Y EGRESOS DE DINERO EN CAJA",
			totalRows: 1,
			currentPage: 1,
			perPage: 10,
			fields: [
				{ key: "caja", 		 	label: "Caja",			sortable: false },
				{ key: "medio_pago", 	label: "Medio Pago", 	sortable: false },
				{ key: "moneda", 	 	label: "Moneda", 		sortable: true },
				{ key: "importe", 	 	label: "Importe", 		sortable: true },
				{ key: "fecRegistro", 	label: "Fec.Registro", 	sortable: true },
				{ key: "detalle", 	 	label: "Detalle", 		sortable: false }],
			items: [],
			itemsTotales: [],
			filter: null,
			sedes: [],
			monedas: [],
			formIngreso: {
				importe: "",
				detalle: ""
			},
			formEgreso: {
				importe: "",
				detalle: ""
			},
		};
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
		caja() {
            return this.$store.state.caja;
        }
	},
	methods: {
		async init(){
			this.listar();
		},
		async listar(){
			let resp:any = '';
			try {
				
				resp = await this.axios.get('select/monedas');
				this.monedas = resp.data;	

				resp = await this.axios.get(`cajaVentaTurno/ingresoEgresos/${this.caja.id}`);
				this.items = resp.data;	
				this.totalRows = this.items.length;	

				resp = await this.axios.get(`cajaVentaTurno/totalIngresoEgresos/${this.caja.id}`);
				this.itemsTotales = resp.data;
			} catch (error) {
				this.catchAction(error);
			}
		},		
		async submitIngreso(e){
			e.preventDefault();	
			this.formIngreso.caja_venta_id = this.caja.caja_venta_id;	
			this.formIngreso.caja_id = this.caja.caja_id;	
			this.formIngreso.caja_venta_turno_id = this.caja.id;	
			this.formIngreso.indIngreso = 1;		

			try {
				let {data} = await this.axios.post('cajaVentas/ingresoEgresos', this.formIngreso);
				this.listar();
				this.formIngreso = {
					importe: "",
					detalle: ""
				}
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		async submitEgreso(e){
			e.preventDefault();
			this.formEgreso.caja_venta_id = this.caja.caja_venta_id;	
			this.formEgreso.caja_id = this.caja.caja_id;	
			this.formEgreso.caja_venta_turno_id = this.caja.id;	
			this.formEgreso.indIngreso = 2;	

			try {
				let {data} = await this.axios.post('cajaVentas/ingresoEgresos', this.formEgreso);
				this.listar();
				this.formEgreso = {
					importe: "",
					detalle: ""
				}
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		//ver carga de caja
		if(!this.caja){
			// @ts-ignore
			new PNotify({
							text: 'Primero debe aperturar su caja',
							type: 'warning',
							styling: 'bootstrap3'
						});
			this.$router.push({name:'Pos'});
		}
		// Set the initial number of items
		this.init();
	},
};
