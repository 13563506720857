
import PageTitle from "../../components/PageTitle.vue";
import ModalAtencion from "../../components/Paciente/ModalAtencion.vue";
import { DateTime } from "luxon";
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/dist/locale'

export default {
	components: { PageTitle, Datepicker, ModalAtencion },
	data() {
		return {
			es: es,
			cargar: false,
			cargarCita: false,
			cargarConsultorio: false,
			cargarReprogramar: false,
			cargarHorario: false,
			cargarCitaProgramacion: true,
			cargarPago: false,
			cargarHistoria: false,
			title: "Reserva de Citas",
			filter: null,
			sedes: [],
			programaciones: [],
			medicos: [],
			reproMedicos: [],
			tipoDocumentos: [],
			especialidads: [],
			consultorios: [],
			reproHorarios: [],
			searchPacientes: [],
			tipoAtencions: [],
			tratamiento_ubicacion_corporals: [],
			medioPagos: [],
			syncHorario: '',
			fecha: DateTime.now().toISODate(),
			srcdocumentoPdf: '',
			tipoCambios: [],
			form: {
				sede_id: '',
				fecha: DateTime.now().setZone('America/Lima').toISO(),
				especialidad_id: '',
				medico_id: '',
			},
			formCita: {
				id: '',
				doctor: '',
				fecha: '',
				hora: '',
				consultorio: '',
				tipo_documento_id: '',
				numDocumento: '',
				apellidoPaterno: '',
				apellidoMaterno: '',
				nombres: '',
				telefono: '',
				correo: '',
				tratamientoUbicacionCorporal: '',
				tratamiento_ubicacion_corporal_id: '',
				indCitaTelefonica: 0,
				intervalo: 20,
				proximo: '',
				interesado: '',
				observacion: '',
				detalleAtencion: ''
			},
			formConsultorio: {
				fechaFormat: '',
				fecha: '',
				doctor: '',
				doctor_id: '',
				consultorio_id: ''
			},
			formHorario: {
				turnos: '',
				fechaFormat: '',
				fecha: '',
				doctor: '',
				doctor_id: '',
				consultorio_id: '',
				especialidad_id: '',
				sede_id: ''
			},
			pacienteDisabled: true,
			formReprogramar: {
				id: '',
				fecha: '',
				medico: '',
				medico_id: '',
				especialidad_id: '',
				paciente_id: '',
				reprogramar_id: ''
			},
			cargarPaciente: false,
			paises: [],
			departamentos: [],
			provincias: [],
			distritos: [],
			sexos: [],
			estadoCivils: [],
			origenClientes: [],
			pacienteMedicos: [],
			formPaciente: {},
			monedas: [],
			comprobanteTipos: [],
			series: [],
			clientes: [],
			venta: {
				comprobante_tipo_nota_credito_id: '',
				comprobante_tipo_nota_debito_id: '',
				cliente: '',
				fechaEmision: DateTime.now().toISODate(),
				fechaVencimiento: DateTime.now().toISODate(),
				moneda_id: '1',
				comprobante_tipo_id: '',
				serie_id: '',
				medico_id: '',
				total: 0.00,
				pagado: 0.00,
				vuelto: 0.00,
				MtoIGV: 0,
				MtoOperGravadas: 0,
				ValorVenta: 0,
				SubTotal: 0,
				MtoImpVenta: 0,
				TotalImpuestos: 0,
				detalle: [],
				pagos: [
					{
						medio_id: '',
						importe: '',
						fechaVencimiento: '',
					}
				],
				pagoComision: [
					{
						medio_id: '',
						importe: 0,
						fechaVencimiento: '',
					}
				],
			},
			spinnerCliente: false,
			cargarCliente: false,
			comisionMedicos: [],
			tipo_contrato_id: '',
			formCliente: {
				tipo_documento_id: "",
				numDocumento: "",
				razonSocial: "",
				razonComercial: "",
				direccionFiscal: "",
				email: "",
				emailOne: "",
				emailDo: "",
				movil: "",
				fijo: "",
				detraccion: "",
				detalle: "",
				department_id: "",
				province_id: "",
				district_id: "",
			},
			cargarDatos: {
				reserva: false,
				paciente: false,
				caja: false,
			},
			historiaMedica: [],
			recetaMedica:[],
			pacienteConsulta:''
		};
	},
	computed: {
		user() {
			return this.$store.state.user;
		},
		url() {
			return this.$store.state.url;
		},
		fechaSeleccionado() {
			return this.convert(this.form.fecha);
		},
		caja() {
			return this.$store.state.caja;
		},
		sibMoneda() {
			let moneda = this.monedas.find(item => item.id == this.venta.moneda_id);
			if (moneda) {
				return moneda.abrev;
			}
		},
	},
	watch: {
		'form.fecha': async function (newValue, oldValue) {
			if (this.form.sede_id && this.form.especialidad_id) {
				let fecha = this.convert(this.form.fecha);
				let { data } = await this.axios.get('select/medico/' + this.form.sede_id + '/' + fecha + '/' + this.form.especialidad_id);
				this.form.medico_id = '';
				this.medicos = data;
			}
			this.SubmitConsulta();
		},
		'form.sede_id': async function (newValue, oldValue) {
			if (this.form.sede_id && this.form.especialidad_id) {
				let fecha = this.convert(this.form.fecha);
				let { data } = await this.axios.get('select/medico/' + this.form.sede_id + '/' + fecha + '/' + this.form.especialidad_id);
				this.form.medico_id = '';
				this.medicos = data;
			}
			let { data } = await this.axios.get('select/consultorios/sede_id/' + newValue);
			this.consultorios = data;
		},
		'form.especialidad_id': async function (newValue, oldValue) {
			if (this.form.sede_id && this.form.especialidad_id) {
				let fecha = this.convert(this.form.fecha);
				let { data } = await this.axios.get('select/medico/' + this.form.sede_id + '/' + fecha + '/' + this.form.especialidad_id);
				this.form.medico_id = '';
				this.medicos = data;
			}
		},
		'formReprogramar.especialidad_id': async function (newValue, oldValue) {
			if (this.form.sede_id && this.formReprogramar.fecha && this.formReprogramar.especialidad_id) {
				let { data } = await this.axios.get('select/medico/' + this.form.sede_id + '/' + this.formReprogramar.fecha + '/' + this.formReprogramar.especialidad_id);
				this.formReprogramar.fecha = '';
				this.formReprogramar.medico_id = '';
				this.reproMedicos = data;
				this.reproHorarios = [];
			}
		},
		'formReprogramar.fecha': async function (newValue, oldValue) {
			if (this.form.sede_id && this.formReprogramar.fecha && this.formReprogramar.especialidad_id) {
				let { data } = await this.axios.get('select/medico/' + this.form.sede_id + '/' + this.formReprogramar.fecha + '/' + this.formReprogramar.especialidad_id);
				this.formReprogramar.medico_id = '';
				this.reproMedicos = data;
				this.reproHorarios = [];
			}
		},
		'formReprogramar.medico_id': async function (newValue, oldValue) {
			if (this.form.sede_id && this.formReprogramar.fecha && this.formReprogramar.especialidad_id && this.formReprogramar.medico_id) {
				let { data } = await this.axios.get('select/horarios/' + this.form.sede_id + '/' + this.formReprogramar.fecha + '/' + this.formReprogramar.especialidad_id + '/' + this.formReprogramar.medico_id);
				this.reproHorarios = data;
			}
		},
		'formPaciente.department_id': async function (newValor, oldValor) {
			let { data } = await this.axios.get('provincias/' + newValor);
			this.provincias = data;
		},
		'formPaciente.province_id': async function (newValor, oldValor) {
			let { data } = await this.axios.get('distritos/' + newValor);
			this.distritos = data;
		},
		"venta.comprobante_tipo_id": async function (newValor, oldValor) {
			let { data } = await this.axios.get('select/comprobanteSeries/' + newValor + '/' + this.caja.sede_id);
			this.series = data;
			this.venta.serie_id = data[0].id;
		},
		async "venta.detalle"(newValor, oldValor) {
			this.calcular();
		}
	},
	methods: {
		async preCarga(num) {
			try {
				let resp: any = '';

				if (num == 1) {
					if (this.cargarDatos.reserva) {
						return false;
					}

					resp = await this.axios.get('select/tipoAtencions');
					this.tipoAtencions = resp.data;
					await this.delaySistema(0.4);

					resp = await this.axios.get('select/tratamientoUbicacionCorporals');
					this.tratamiento_ubicacion_corporals = resp.data;

					this.cargarDatos.reserva = true;
				} else if (num == 2) {
					if (this.cargarDatos.paciente) {
						return false;
					}
					resp = await this.axios.get('departamentos');
					this.departamentos = resp.data;

					resp = await this.axios.get('select/sexos');
					this.sexos = resp.data;

					resp = await this.axios.get('select/estadoCivils');
					this.estadoCivils = resp.data;

					resp = await this.axios.get('countries');
					this.paises = resp.data;

					resp = await this.axios.get('select/origenCliente');
					this.origenClientes = resp.data;

					resp = await this.axios.get('select/medicos');
					this.pacienteMedicos = resp.data;

					this.cargarDatos.paciente = true;
				} else if (num == 3) {
					if (this.cargarDatos.caja) {
						return false;
					}
					resp = await this.axios.get("select/comprobanteTipos");
					this.comprobanteTipos = resp.data;

					this.cargarDatos.caja = true;
				} else {

				}
			} catch (error) {
				this.catchAction(error)
			}
		},
		async init() {
			let resp: any = '';
			this.cargar = true;

			try {
				resp = await this.axios.get('select/sedes_user');
				this.sedes = resp.data;

				resp = await this.axios.get('select/especialidads');
				this.especialidads = resp.data;

				resp = await this.axios.get('select/medioPagos');
				this.medioPagos = resp.data;

				resp = await this.axios.get('select/tipoDocumentos/1');
				this.tipoDocumentos = resp.data;

				resp = await this.axios.get('cajaVentas/tipoCambio');
				this.tipoCambios = resp.data;

				resp = await this.axios.get("select/monedas");
				this.monedas = resp.data;

				this.cargar = false;

				this.form.sede_id = this.user.sede_id;
				this.SubmitConsulta();
			} catch (error) {
				this.catchAction(error)
			}
		},
		citaTiempo() {
			if (this.formCita.tratamientoUbicacionCorporal.duracion) {
				this.formCita.intervalo = this.formCita.tratamientoUbicacionCorporal.duracion;
			}
		},
		async consultaDoc() {
			if (!this.formCita.search) {
				return false;
			}

			let { data } = await this.axios.get('paciente/search/' + this.formCita.search);

			if (data.length == 0) {
				this.formCita.tipo_documento_id = '';
				this.formCita.numDocumento = '';
				this.formCita.apellidoPaterno = '';
				this.formCita.apellidoMaterno = '';
				this.formCita.nombres = '';
				this.formCita.correo = '';
				this.formCita.telefono = '';
				this.formCita.paciente_id = '';
				return false;
			}

			if (data.length == 1) {
				data = data[0];
				this.formCita.tipo_documento_id = data.tipo_documento_id;
				this.formCita.numDocumento = data.numDocumento;
				this.formCita.apellidoPaterno = data.apellidoPaterno;
				this.formCita.apellidoMaterno = data.apellidoMaterno;
				this.formCita.nombres = data.nombres;
				this.formCita.telefono = data.telefono;
				this.formCita.correo = data.correo;
				this.formCita.paciente_id = data.id;
				this.formCita.fullName = data.fullName;
			} else {
				this.searchPacientes = data;
				// @ts-ignore
				$("#modalCrearCitaSearch").modal({ backdrop: 'static', keyboard: false });
			}
		},
		historiaPaciente(paciente){
			this.$refs.modalAtencion.historiaPaciente(paciente);
		},
		async consultaDocSearch() {
			if (!this.formCita.search) {
				return false;
			}
			let { data } = await this.axios.get('paciente/search/' + this.formCita.search);
			this.searchPacientes = data;
		},
		addPacienteSearch(index) {
			let data = this.searchPacientes[index];
			this.formCita.tipo_documento_id = data.tipo_documento_id;
			this.formCita.numDocumento = data.numDocumento;
			this.formCita.apellidoPaterno = data.apellidoPaterno;
			this.formCita.apellidoMaterno = data.apellidoMaterno;
			this.formCita.nombres = data.nombres;
			this.formCita.telefono = data.telefono;
			this.formCita.paciente_id = data.id;
			// @ts-ignore
			$("#modalCrearCitaSearch").modal('hide');
		},
		addPaciente() {
			this.formCita.tipo_documento_id = '';
			this.formCita.numDocumento = '';
			this.formCita.apellidoPaterno = '';
			this.formCita.apellidoMaterno = '';
			this.formCita.nombres = '';
			this.formCita.telefono = '';
			this.formCita.paciente_id = '';
			this.pacienteDisabled = false;
		},
		TooltipContent(item) {
			let text = `#${item.id} \n ${item.paciente} \n  ${item.fechaHora} \n ${item.especialidad} \n ${item.doctor} \n ${item.detalleAtencion} `;
			text += item.pagado == 1 ? ' Pagado' : '';
			return text;
		},
		async eliminarHorarios(item) {
			if (confirm('Eliminar Turno:' + item.hora)) {
				let ruta = 'horarios/eliminar'
				if (item.id) {
					ruta += '/' + item.id;
				}
				await this.axios.patch(ruta, item)
				this.consulta();
			}
		},
		async anularHorarios(item) {
			this.$swal.fire({
				title: 'Anular la cita?',
				input: 'text',
				inputLabel: 'Motivo de anulación',
				inputPlaceholder: 'Ingrese motivo de anulación',
				inputAttributes:{
					required :true
				},
				text: item.fechaHora + ';' + item.paciente,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Si, confirmar',
				showLoaderOnConfirm: true,
				preConfirm: (detalleAnulado) => {
					return this.axios.patch('horarios/anular/' + item.id, {detalleAnulado})
						.then((result) => {
							return result.data;
						}).catch((err) => {
							this.$swal.showValidationMessage(
								`Request failed: ${err}`
							)
						});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.consulta();
				}
			});
		},
		rangoHora(horarios: any) {
			let num = horarios.length;
			let inicio = horarios[0].hora;
			let fin = horarios[num - 1].hora;
			fin = DateTime.fromISO(fin);
			fin = fin.plus({ minutes: horarios[num - 1].intervalo });
			fin = fin.toFormat('T');
			return inicio + '-' + fin;
		},
		colorCita(estado, disponible, web) {
			let clase = '';
			switch (estado) {
				case 0:
					clase = 'bg-Anulado text-white';
					break;
				case 1:
					clase = 'bg-Atendido text-white';
					break;
				case 2:
					clase = 'bg-Espera text-white';
					break;
				case 3:
					clase = 'bg-Confirmado text-white';
					if (web) {
						clase = 'bg-Reserva-Web text-white';
					}
					break;
				case 4:
					clase = 'bg-Reservado text-white';
					if (disponible == 0) {
						clase = 'bg-No-Disponible text-white';
					}
					break;
				case 5:
					clase = 'bg-Disponible';
					if (disponible == 0) {
						clase = 'bg-No-Disponible text-white';
					}
					break;
				case 6:
					clase = 'bg-Bloqueado text-white';
					break;
				default:
					clase = 'bg-No-Disponible text-white';
					break;
			}
			return clase;
		},
		resetCita() {
			this.formCita = {
				id: '',
				doctor: '',
				fecha: '',
				hora: '',
				consultorio: '',
				tipo_documento_id: '',
				numDocumento: '',
				apellidoPaterno: '',
				apellidoMaterno: '',
				nombres: '',
				telefono: '',
				correo: '',
				tratamientoUbicacionCorporal: '',
				tratamiento_ubicacion_corporal_id: '',
				indCitaTelefonica: 0,
				proximo: '',
				interesado: '',
				observacion: '',
				paciente_id: '',
				estado: '',
			}
		},
		async crearCita(item, programacion) {
			this.preCarga(1);
			this.resetCita();
			this.formCita.id = item.id;
			this.formCita.doctor = programacion.doctor;
			this.formCita.consultorio = programacion.consultorio;
			this.formCita.fecha = item.fecha;
			this.formCita.hora = item.hora;
			this.formCita.intervalo = item.intervalo;
			this.formCita.especialidad = item.especialidad;
			this.formCita.isActive = item.isActive;
			this.formCita.estado = item.estado;

			this.pacienteDisabled = true;
			this.formCita.search = '';

			if (this.$route.params.pacienteSeguimientoId > 0) {
				this.formCita.pacienteSeguimientoId = this.$route.params.pacienteSeguimientoId;
			}

			// @ts-ignore
			$("#modalCrearCita").modal({ backdrop: 'static', keyboard: false })
		},
		async showCita(id) {
			this.srcdocumentoPdf = this.url + 'pdf/programacion/' + id;
			// @ts-ignore
			$("#modalPdf").modal({ backdrop: 'static', keyboard: false })
		},
		confirmarCita(item) {
			let text = item.fechaHora + '<br>' + item.paciente;
			if (item.web == 1) {
				text += '<br>' + item.token;
			}
			this.$swal.fire({
				title: 'Confirmar cita?',
				html: text,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Si, confirmar',
				showLoaderOnConfirm: true,
				preConfirm: (data) => {
					return this.axios.patch('citas/confirmar/' + item.id)
						.then((result) => {
							return result.data;
						}).catch((err) => {
							this.$swal.showValidationMessage(
								`Request failed: ${err}`
							)
						});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.consulta();
				}
			});
		},
		async llegadaCita(item) {
			this.preCarga(2);
			try {
				if (item.paciente_id > 0) {
					let { data } = await this.axios.get('pacientes/' + item.paciente_id);
					this.formPaciente = data;
					this.formPaciente.cita_id = item.id;
				}
				// @ts-ignore
				$("#modalPaciente").modal({ backdrop: 'static', keyboard: false })
			} catch (error) {
				this.catchAction(error)
			}
		},
		async submitPacienteEspera(e) {
			e.preventDefault();
			let data: any = '';
			this.cargarPaciente = true;
			this.formPaciente;
			try {
				let resp = await this.axios.patch('citas/espera/' + this.formPaciente.cita_id, this.formPaciente);
				data = resp.data;
				// @ts-ignore
				$("#modalPaciente").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
				this.consulta();
				this.cargarPaciente = false;
			} catch (error) {
				this.cargarPaciente = false;
				this.catchAction(error)
			}
		},
		bloquearCita(item) {
			this.$swal.fire({
				title: 'Bloquear la cita?',
				text: item.fechaHora,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Si, confirmar',
				showLoaderOnConfirm: true,
				preConfirm: (data) => {
					return this.axios.patch('citas/bloqueado/' + item.id)
						.then((result) => {
							return result.data;
						}).catch((err) => {
							this.$swal.showValidationMessage(
								`Request failed: ${err}`
							)
						});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.consulta();
				}
			});
		},
		habilitarCita(item) {
			this.$swal.fire({
				title: 'Habilitar la cita?',
				text: item.fechaHora,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Si, confirmar',
				showLoaderOnConfirm: true,
				preConfirm: (data) => {
					return this.axios.patch('citas/habilitar/' + item.id)
						.then((result) => {
							return result.data;
						}).catch((err) => {
							this.$swal.showValidationMessage(
								`Request failed: ${err}`
							)
						});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.consulta();
				}
			});
		},
		dividirCita(item) {
			this.$swal.fire({
				title: 'Dividir la cita en 10 minutos?',
				text: item.fechaHora,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Si, confirmar',
				showLoaderOnConfirm: true,
				preConfirm: (data) => {
					return this.axios.patch('citas/dividir/' + item.id)
						.then((result) => {
							return result.data;
						}).catch((err) => {
							this.$swal.showValidationMessage(
								`Request failed: ${err}`
							)
						});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.consulta();
				}
			});
		},
		showConsultorio(item) {
			this.formConsultorio.doctor = item.doctor;
			this.formConsultorio.doctor_id = item.doctor_id;
			this.formConsultorio.fechaFormat = item.fechaFormat;
			this.formConsultorio.fecha = item.fecha;
			this.formConsultorio.consultorio_id = item.consultorio_id;
			// @ts-ignore
			$("#modalConsultorio").modal({ backdrop: 'static', keyboard: false })
		},
		showHorario(item) {
			let hora = item.horarios[item.horarios.length - 1].hora;
			hora = DateTime.fromISO(hora);
			hora = hora.plus({ minutes: 20 });

			this.formHorario.doctor = item.doctor;
			this.formHorario.doctor_id = item.doctor_id;
			this.formHorario.fechaFormat = item.fechaFormat;
			this.formHorario.fecha = item.fecha;
			this.formHorario.consultorio_id = item.consultorio_id;
			this.formHorario.sede_id = item.sede_id;
			this.formHorario.especialidad_id = item.especialidad_id;
			this.formHorario.hora = hora.toFormat('T');
			// @ts-ignore
			$("#modalHorario").modal({ backdrop: 'static', keyboard: false })
		},
		async reprogramarCita(item) {
			this.formReprogramar.fecha = '';
			this.formReprogramar.id = item.id;
			this.formReprogramar.especialidad_id = item.especialidad_id;
			this.formReprogramar.medico_id = item.doctor_id;
			this.formReprogramar.paciente_id = item.paciente_id;
			// @ts-ignore
			$("#modalReprogramarCita").modal({ backdrop: 'static', keyboard: false })
		},
		convert(str) {
			let date = new Date(str),
				mnth = ("0" + (date.getMonth() + 1)).slice(-2),
				day = ("0" + date.getDate()).slice(-2);
			return [date.getFullYear(), mnth, day].join("-");
		},
		async SubmitConsulta() {
			let self = this;
			if (this.syncHorario) {
				clearTimeout(this.syncHorario);
				this.syncHorario = '';
			}

			this.consulta();

			this.syncHorario = setInterval((e) => {
				self.consulta();
			}, 60000 * 5);
		},
		async consulta() {
			this.cargarCitaProgramacion = true;
			let fecha = this.convert(this.form.fecha);

			let url = 'horarios/' + this.form.sede_id + '/' + fecha;
			if (this.form.especialidad_id > 0) {
				url += '/' + this.form.especialidad_id;
				if (this.form.medico_id > 0) {
					url += '/' + this.form.medico_id;
				}
			}
			let { data } = await this.axios.get(url);
			this.programaciones = data;
			this.cargarCitaProgramacion = false;
		},
		async submitCita(e) {
			e.preventDefault();
			if (!this.formCita.tipo_documento_id || !this.formCita.numDocumento || !this.formCita.apellidoPaterno || !this.formCita.apellidoMaterno || !this.formCita.nombres) {
				// @ts-ignore
				return new PNotify({
					text: 'Faltan datos del paciente',
					type: 'warning',
					styling: 'bootstrap3'
				});
			}

			if (this.formCita.tipo_atencion_id > 2) {
				if (!this.formCita.tratamientoUbicacionCorporal) {
					// @ts-ignore
					return new PNotify({
						text: 'Faltan seleccionar tratamiento',
						type: 'warning',
						styling: 'bootstrap3'
					});
				}
				this.formCita.detalleAtencion = this.formCita.tratamientoUbicacionCorporal.name;
				this.formCita.tratamiento_id = this.formCita.tratamientoUbicacionCorporal.tratamiento_id;
				this.formCita.tratamiento_ubicacion_corporal_id = this.formCita.tratamientoUbicacionCorporal.id;
			} else {
				let item = this.tipoAtencions.find(item => item.id == this.formCita.tipo_atencion_id);
				this.formCita.detalleAtencion = this.formCita.especialidad + ' - ' + item.nombre;
				this.formCita.tratamiento_id = '';
				this.formCita.tratamiento_ubicacion_corporal_id = '';
			}

			this.cargarCita = true;
			try {
				let { data } = await this.axios.patch('citas/' + this.formCita.id, this.formCita);
				// @ts-ignore
				$("#modalCrearCita").modal('hide');
				this.consulta();
				this.cargarCita = false;
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
				await this.delaySistema(0.5);
				if (this.$route.params.pacienteSeguimientoId > 0) {
					this.$router.push({ name: this.$route.params.pacienteSeguimientoRoute });
				}
			} catch (error) {
				this.cargarCita = false;
				this.catchAction(error)
			}
		},
		async submitConsultorio(e) {
			e.preventDefault();
			this.cargarConsultorio = true;
			try {
				let { data } = await this.axios.patch('citas/consultorio/' + this.formConsultorio.consultorio_id, this.formConsultorio);
				this.consulta();
				this.cargarConsultorio = false;
				// @ts-ignore
				$("#modalConsultorio").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargarConsultorio = false;
				this.catchAction(error)
			}
		},
		async submitReprogramarCita(e) {
			e.preventDefault();
			this.cargarReprogramar = true;
			try {
				let { data } = await this.axios.put('citas/reprogramar/' + this.formReprogramar.id, this.formReprogramar);
				// @ts-ignore
				$("#modalReprogramarCita").modal('hide');
				this.consulta();
				this.cargarReprogramar = false;
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargarReprogramar = false;
				this.catchAction(error)
			}
		},
		async submitHorario(e) {
			e.preventDefault();
			this.cargarHorario = true;
			/* obtener la lista de horarios */
			let inicio = DateTime.fromISO(this.formHorario.hora);
			let horarios = [];
			let num = 1;
			let hora = inicio.toFormat('T');

			horarios.push(hora);
			do {
				inicio = inicio.plus({ minutes: 20 });
				hora = inicio.toFormat('T');
				horarios.push(hora);
				num++;
			} while (num < this.formHorario.turnos);

			this.formHorario.horarios = horarios;

			try {
				let { data } = await this.axios.post('citas/addHorario', this.formHorario);
				// @ts-ignore
				$("#modalHorario").modal('hide');
				this.consulta();
				this.cargarHorario = false;
				this.formHorario.turnos = '';
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargarHorario = false;
				this.catchAction(error)
			}
		},
		async calcular() {
			let MtoImpVenta = 0;
			let ValorVenta = 0;
			let TotalImpuestos = 0;
			let MtoIGV = 0;
			let mtoComision = 0;
			let mtoDescuento = 0;
			let mtoValorInicial = 0;

			this.venta.detalle.forEach(item => {
				/* iniciamos desde cero */
				let basePrecioVenta = item.basePrecioVenta;
				let igv = ((100 + item.porcentaje_igv) / 100);
				
				/* si modifica el precio */
				if(item.basePrecioUnitario != basePrecioVenta){
					basePrecioVenta = item.basePrecioUnitario;
				}

				/* descuento medico */
				if (item.desMedico > 0) {
					basePrecioVenta = basePrecioVenta - item.desMedico;					
				}

				/* pasamos a la moneda principal*/
				item.mtoTipoCambio = 1;
				if (item.baseMoneda_id != this.venta.moneda_id) {
					if(this.venta.moneda_id != 1){
						let monedaCambio = this.tipoCambios.find(tipoCambio => tipoCambio.id == this.venta.moneda_id);
						item.mtoTipoCambio = monedaCambio.valorCambio;
						basePrecioVenta = (basePrecioVenta / item.mtoTipoCambio).toFixed(2);
					}else{
						let monedaCambio = this.tipoCambios.find(tipoCambio => tipoCambio.id == item.baseMoneda_id);
						item.mtoTipoCambio = monedaCambio.valorCambio;
						basePrecioVenta = (basePrecioVenta * item.mtoTipoCambio * 100 /100).toFixed(2);
					}
					basePrecioVenta = basePrecioVenta * 1;
				}

				item.moneda_id = this.venta.moneda_id;
				item.moneda = this.sibMoneda;
				item.mtoValorUnitarioInicial = basePrecioVenta;

				mtoValorInicial += (item.basePrecioVenta * item.mtoTipoCambio * item.cantidad);	

				/* comision medico*/
				let comision = this.comisionMedicos.find(item2 => item2.categoria_id == item.categoria_id);
				item.indComision = 0;
				item.comision = 0;
				item.mtoComision = 0;

				if (comision) {
					if(this.tipo_contrato_id == 4 && this.venta.indComisionMedico == 1) {	
						if(comision.tipo == 1){
							item.comision = (basePrecioVenta * (comision.comision / 100));							
						}
						if(comision.tipo == 2){
							item.comision = comision.comision * item.mtoTipoCambio;						
						}

						item.indComision = 1;	
						item.mtoComision = item.comision * item.cantidad;	
						mtoComision += item.mtoComision;
						
						basePrecioVenta =  basePrecioVenta - item.comision;	
						item.desFactor = 0;
					}
				}

				let unitMtoImpVenta = basePrecioVenta*1;
				item.mtoValorUnitario = (basePrecioVenta/igv).toFixed(4);

				let unitMtoImpVentaTotal = unitMtoImpVenta * item.cantidad;
				item.mtoBase = (unitMtoImpVentaTotal/igv).toFixed(2);
				/* ---- montos de precio de venta */				
				item.desMonto = 0;
				if(item.desFactor > 0){
					/* descuento */
					let factor = (item.desFactor / 100);
					let desMonto:any =  (unitMtoImpVentaTotal * factor).toFixed(2);
					item.desMonto = (desMonto/igv).toFixed(2);
					mtoDescuento += item.desMonto;

					/* valor de venta */
					unitMtoImpVenta = unitMtoImpVenta - (unitMtoImpVenta * factor);
				}

				item.mtoPrecioUnitario = unitMtoImpVenta.toFixed(2);
				/* Calculo de operacion */
				item.MtoImpVenta = (unitMtoImpVenta * item.cantidad).toFixed(2);
				item.mtoValorVenta = (item.MtoImpVenta/igv).toFixed(4);
				item.mtoBaseIgv = item.mtoValorVenta;
				item.igv = parseFloat(item.MtoImpVenta) - parseFloat(item.mtoValorVenta);
				item.TotalImpuestos = (item.igv).toFixed(2);		

				ValorVenta += item.mtoValorVenta *1;
				MtoIGV += item.igv*1;
				TotalImpuestos += item.TotalImpuestos*1;
				MtoImpVenta += item.MtoImpVenta*1;
			});

			this.venta.pagoComision[0].importe = (mtoComision*1).toFixed(2);
			this.venta.mtoComision = (mtoComision*1).toFixed(2);
		
			this.venta.mtoDescuento = (mtoDescuento*1).toFixed(2);
			this.venta.mtoValorInicial = (mtoValorInicial*1).toFixed(2);

			this.venta.MtoIGV = (MtoIGV*1).toFixed(2);
			this.venta.MtoOperGravadas = (ValorVenta*1).toFixed(4);
			this.venta.TotalImpuestos = (TotalImpuestos*1).toFixed(2);
			this.venta.ValorVenta = (ValorVenta*1).toFixed(2);
			this.venta.SubTotal = (MtoImpVenta*1).toFixed(2);	
			this.venta.MtoImpVenta = (MtoImpVenta*1).toFixed(2);
		},
		async pagarCita(item) {
			let resp: any = '';
			this.venta.detalle = [];
			if (!this.caja) {
				// @ts-ignore
				return new PNotify({
					text: 'Caja no aperturada',
					type: 'warning',
					styling: 'bootstrap3'
				});
			}

			this.cargarCitaProgramacion = true;
			this.preCarga(3);
			this.venta.indComisionMedico = '';
			try {

				this.venta.comprobante_tipo_id = 2;
				resp = await this.axios.get('cajaVentas/cita/' + item.id);
				this.venta.detalle.push(resp.data.producto);
				//seleccionar al cliente
				this.fetchClientes(resp.data.cliente.numDocumento, resp.data.cliente.id);
				//obtener comisiones
				this.comisionMedicos = [];
				this.tipo_contrato_id = '';
				resp = await this.axios.get('comisionPersonals/personal/' + item.doctor_id);
				if (resp.data) {
					this.comisionMedicos = resp.data;
					this.tipo_contrato_id = item.tipo_contrato_id;
					this.venta.indComisionMedico = 1;
				}
				this.venta.medico_id = item.doctor_id;

				/* medio de pago */
				let medio_id = 1;
				if (item.web == 1) {
					medio_id = 11;
				}

				this.venta.pagos[0] = {
					medio_id: medio_id,
					importe: this.venta.MtoImpVenta,
					fechaVencimiento: '',
				}

				this.calcular();
				this.venta.pagado = this.venta.MtoImpVenta;
				this.venta.vuelto = 0;
				this.cargarCitaProgramacion = false;
				// @ts-ignore
				$("#modalPago").modal({ backdrop: 'static', keyboard: false });
			} catch (error) {
				this.cargarCitaProgramacion = false;
				this.catchAction(error)
			}
		},
		async PagoRapido(e) {
			e.preventDefault();
			this.cargarPago = true;
			try {
				this.venta.company_id = this.caja.company_id;
				this.venta.caja_id = this.caja.caja_id;
				this.venta.caja_venta_id = this.caja.caja_venta_id;
				this.venta.caja_venta_turno_id = this.caja.id;
				let { data } = await this.axios.post('crearDocumento', this.venta);
				this.consulta();
				this.cargarPago = false;
				// @ts-ignore
				$("#modalPago").modal('hide');
				this.modalDocumento(data.url);
			} catch (error) {
				this.cargarPago = false;
				this.catchAction(error);
			}
		},
		modalDocumento(url) {
			this.srcdocumentoPdf = url + '/1';
			// @ts-ignore
			$("#modalPdf").modal({ backdrop: 'static', keyboard: false });
		},
		async showCliente() {
			if (this.venta.cliente) {
				let { data } = await this.axios.get('clientes/' + this.venta.cliente.id);
				this.formCliente = data;
			} else {
				this.formCliente = {
					tipo_documento_id: "",
					numDocumento: "",
					razonSocial: "",
					razonComercial: "",
					direccionFiscal: "",
					email: "",
					emailOne: "",
					emailDo: "",
					movil: "",
					fijo: "",
					detraccion: "",
					detalle: "",
					department_id: "",
					province_id: "",
					district_id: "",
				};
			}
			// @ts-ignore
			$("#modalCliente").modal({ backdrop: 'static', keyboard: false });
		},
		async fetchClientes(search, id) {
			if (search.length >= 3) {
				this.spinnerCliente = true;
				let { data } = await this.axios.get('select/clientes/' + search + '/' + this.venta.comprobante_tipo_id);
				this.clientes = data;
				this.spinnerCliente = false;
			}
			if (id > 0) {
				let cliente = this.clientes.find(item => item.id == id);
				this.venta.cliente = cliente;
			}
		},
		async searchCliente() {
			let tipo = 1;

			if (!this.formCliente.tipo_documento_id || !this.formCliente.numDocumento) {
				return false;
			}

			if (this.formCliente.tipo_documento_id != 4) {
				tipo = 2
			}

			let numero = this.formCliente.numDocumento;
			let { data } = await this.axios.get('peru-consult/' + numero + '/' + tipo);
			if (data) {
				this.formCliente.department_id = data.department_id;
				this.formCliente.direccionFiscal = data.direccionFiscal;
				this.formCliente.district_id = data.district_id;
				this.formCliente.province_id = data.province_id;
				this.formCliente.razonComercial = data.razonComercial;
				this.formCliente.razonSocial = data.razonSocial;
			} else {
				this.formCliente.department_id = '';
				this.formCliente.direccionFiscal = '';
				this.formCliente.district_id = '';
				this.formCliente.province_id = '';
				this.formCliente.razonComercial = '';
				this.formCliente.razonSocial = '';
			}
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
	destroyed() {
		if (this.syncHorario) {
			clearTimeout(this.syncHorario);
			this.syncHorario = '';
		}
	},
};
