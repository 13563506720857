
import PageTitle from "../../components/PageTitle.vue";
import { DateTime } from "luxon";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar: false,
			title: "Comisiones",
			totalRows: 1,
			currentPage: 1,
			perPage: 10,
			isBusy: false,
			fields: [
				{ key: "acciones", label: "Acciones", sortable: false },
				{ key: "medico", label: "Medico", sortable: true },
				{ key: "tipo_contrato", label: "Tipo Contrato", sortable: true },
				{ key: "descripcion", label: "Servicio o Producto", sortable: true },
				{ key: "moneda", label: "Mon", sortable: true },
				{ key: "comision", label: "Comision", sortable: true },
				{ key: "programacion_cita_id", label: "Cita", sortable: true },
				{ key: "comprobante", label: "Comprobante", sortable: true },
				{ key: "fecha", label: "Fecha", sortable: true },
				{ key: "urlRecibo", label: "Recibo", sortable: true },
			],
			columns: [
				{ label: "Medico", field: "medico", },
				{ label: "Tipo Contrato", field: "tipo_contrato", },
				{ label: "Servicio o Producto", field: "descripcion", },
				{ label: "Mon", field: "moneda", },
				{ label: "Comision", field: "comision" },
				{ label: "Cita", field: "programacion_cita_id", },
				{ label: "Comprobante", field: "comprobante", },
				{ label: "Fecha", field: "fecha", }
			],
			items: [],
			pacienteMedicos: [],
			medicosComision: [],
			filter: null,
			consulta: {
				inicio: DateTime.now().toISODate(),
				fin: DateTime.now().toISODate(),
				personal_id: '',
			},
			documentoUrl: '',
			formRRHH: {
				numeracion: "",
				personal_id: "",
				comprobante_id: "",
				tipo: "",
				fecha: "",
				importe: "",
				retencion: "",
			},
			fieldsComision: [
				{ key: "medico", label: "Medico", sortable: true },
				{ key: "contrato", label: "Tipo Contrato", sortable: true },
				{ key: "moneda", label: "Moneda", sortable: true },
				{ key: "total", label: "Total", sortable: true },
			],
			itemsComision: []
		};
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	methods: {
		async inputFile(e) {
			this.file = this.$refs.file.files[0];
		},
		async init() {
			try {
				this.listar();
				let { data } = await this.axios.get('select/medicos')
				this.pacienteMedicos = data;
			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		async listar() {
			this.isBusy = true;
			try {
				let url = `comisionDetalles/${this.consulta.inicio}/${this.consulta.fin}`;
				if (this.consulta.personal_id) { url += `/${this.consulta.personal_id}` }
				this.listarTotal();
				let { data } = await this.axios.get(url);
				this.items = data;
				this.totalRows = this.items.length;
				this.isBusy = false;
			} catch (error) {
				this.isBusy = false;
				this.catchAction(error);
			}
		},
		async listarTotal() {
			try {
				let { data } = await this.axios.get(`comisionDetallesMedico/${this.consulta.inicio}/${this.consulta.fin}`);
				this.itemsComision = data;

			} catch (error) {
				this.catchAction(error);
			}
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
		modalDocumento(url) {
			this.documentoUrl = url;
			// @ts-ignore
			$("#modalDocumento").modal({ backdrop: 'static', keyboard: false });
		},
		modalRRHH(item) {
			this.limpiarRRHH();
			this.formRRHH.numeracion = item.numeracionRH;
			this.formRRHH.comision_detalle_id = item.comision_detalle_id;
			this.formRRHH.personal_id = item.personal_id;
			this.formRRHH.comprobante_id = item.comprobante_id;
			this.formRRHH.tipo = 1;
			this.formRRHH.fecha = item.fecha;
			this.formRRHH.importe = item.comision;
			this.formRRHH.id = item.personal_recibo_honorario_id;
			// @ts-ignore
			$("#modalRRHH").modal({ backdrop: 'static', keyboard: false });
		},
		limpiarRRHH() {
			this.file = '';
			this.formRRHH = {
				personal_id: "",
				comprobante_id: "",
				tipo: "",
				fecha: "",
				importe: "",
				retencion: "",
				comision_detalle_id: "",
			}

			let file: any = document.getElementById('file');
			file.value = '';
		},
		async submitRRHH(e) {
			e.preventDefault();

			let data: any = '';
			let resp: any = '';
			this.cargar = true;
			try {
				if (this.formRRHH.id > 0) {
					resp = await this.axios.put('personalReciboHonorarios/' + this.formRRHH.id, this.formRRHH);
					data = resp.data;
				} else {
					resp = await this.axios.post('personalReciboHonorarios', this.formRRHH);
					data = resp.data;
				}

				if (this.file && data.data.id > 0) {
					await this.upload(data.data.id, data.data.file_id, 'file_id', this.file, 14);
				}

				this.listar();
				this.cargar = false;
				// @ts-ignore
				$("#modalRRHH").modal('hide');
				// @ts-ignore
				new PNotify({
					text: data.msg,
					type: 'success',
					styling: 'bootstrap3'
				});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error)
			}
		},
		async upload(id, columna_id, columna, file, model) {
			return new Promise(async (resolve, reject) => {
				let formData = new FormData();
				formData.append('id', id);
				formData.append('columna_id', columna_id);
				formData.append('columna', columna);
				formData.append('file', file);
				formData.append('model', model);
				let { data } = await this.axios.post('file', formData);
				resolve(data);
			});
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
