
export default {
	methods: {
		async logout(){
			this.axios.post('logout');
			await this.$store.dispatch('setUser', null);
			this.$router.push('/');
		}
	},
}
