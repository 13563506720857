
import PageTitle from "../../components/PageTitle.vue";
import BarChart from '../../components/charjs/BarChart.vue';

export default {
	components: { PageTitle, BarChart },
	data() {
		return {
			cargar:false,
			title: "Reporte - usuarios",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
        	isBusy: false,
			filter: null,
			items: [],
			fields:[
				{ key: "index", 		label: "N°",			sortable: false },
				{ key: "personal", 		label: "Personal",		sortable: false },
				{ key: "reservado", 	label: "Reservado",		sortable: false },
				{ key: "confirmado", 	label: "Confirmado",	sortable: false },
				{ key: "espera", 		label: "Espera",		sortable: false },
				{ key: "atendido", 		label: "Atendido",		sortable: false },
				{ key: "anulado", 		label: "Anulado",		sortable: false },
				{ key: "total", 		label: "Total",			sortable: false },
			],
			totals:[],
			fecInicio: '',
			fecFin: '',
			chartData: {}
		}
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
		user(){
			return this.$store.state.user;  
		}
	},
	methods: {
		async listar(){
			this.isBusy = true;
			try {
				let data:any = '';
				let resp:any = '';
				resp = await this.axios.get(`reporteCitasUsuario/${this.fecInicio}/${this.fecFin}`);
				data = resp.data;
				this.isBusy = false;
				this.items = data.data;	
				this.totalRows = this.items.length;		
				this.totals = data.total;
				
				//consulta barras				
				resp = await this.axios.get(`reporteCitasUsuarioBarra/${this.fecInicio}/${this.fecFin}`);
				this.chartData = resp.data;
			} catch (error) {
				this.catchAction(error);
			}
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
}
