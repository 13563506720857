import { User } from '@/models/user';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: new User(),
		url: '',
		caja: false,
	},
	mutations: {
		SET_USER(state, user) {
			state.user = user;
		},
		SET_URL(state, url) {
			state.url = url;
		},
		SET_Caja(state, url) {
			state.caja = url;
		}
	},
	actions: {
		setUser({ commit }, user) {
			return commit('SET_USER', user);
		},
		setUrl({ commit }, url) {
			return commit('SET_URL', url);
		},
		setCaja({ commit }, caja) {
			return commit('SET_Caja', caja);
		}
	},
	modules: {

	}
})
