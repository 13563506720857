
import PageTitle from "../../components/PageTitle.vue";
import { DateTime } from "luxon";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar: false,
			title: "Atención Médica",
			items: [],
			filter: null,
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "index", label: "N°", sortable: false },
				{ key: "fechaHora", label: "Hora Atencion", sortable: false },
				{ key: "paciente", label: "Paciente", sortable: false },
				{ key: "detalleAtencion", label: "Tipo", sortable: false },
				{ key: "consultorio", label: "Lugar", sortable: false },
				{ key: "hClinica", label: "H. Clinica", sortable: true },
				{ key: "aMedico", label: "Acto Medico", sortable: true },
				{ key: "fechaLlegada", label: "Hora Llegada", sortable: true },
				{ key: "doctor", label: "Medico", sortable: true },
				{ key: "estado", label: "Estado", sortable: true }],
			fecha: DateTime.now().toISODate(),
			tipo: 1,
			texto: '',
			sede_id: '',
		};
	},
	computed: {
		user() {
			return this.$store.state.user;
		}
	},
	methods: {
		async init() {
			this.sede_id = this.user.sede_id;
			this.consulta();
		},
		async consulta() {
			if (!this.fecha) {
				return false;
			}
			let consulta = (this.tipo == 1) ? this.fecha : this.texto;
			let url = 'atencion/' + this.sede_id + '/' + this.tipo + '/' + consulta;
			let { data } = await this.axios.get(url);
			this.items = data;
			this.totalRows = this.items.length;
		},
		colorCita(estado, web) {
			let clase = '';
			switch (estado) {
				case 1:
					clase = 'bg-Atendido text-white';
					break;
				case 2:
					clase = 'bg-Espera text-white';
					break;
				case 3:
					clase = 'bg-Confirmado text-white';
					if (web) {
						clase = 'bg-Reserva-Web text-white';
					}
					break;
				default:
					clase = 'bg-No-Disponible text-white';
					break;
			}
			return clase;
		},
		estadoCita(estado, enfermeria, web) {
			let text = '';
			switch (estado) {
				case 1:
					text = 'Atendido';
					break;
				case 2:
					text = 'Espera';
					if (enfermeria) {
						text = 'Espera - Enfermeria';
					}
					break;
				case 3:
					text = 'Confirmado';
					if (web) {
						text = 'Reserva Web';
					}
					break;
				default:
					text = 'error';
					break;
			}
			return text;
		},
		show(item) {
			this.$router.push({
				name: 'AtencionShow',
				params: {
					id: item.id,
					acto_medico_id: item.acto_medico_id,
					historia_clinica_id: item.historia_clinica_id
				}
			});
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
