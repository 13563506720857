
import PageTitle from "../../components/PageTitle.vue";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar:false,
			title: "Lista Precios - Tratamientos",
			titleForm: "Registrar Precio de Tratamientos",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "acciones", 		label: "Acciones",		sortable: false },
				{ key: "tratamiento", 	label: "Tratamiento", 	sortable: true },
				{ key: "corporal", 		label: "Cuerpo", 		sortable: true },
				{ key: "duracion", 		label: "Duración", 		sortable: true },
				{ key: "ubicacion_corporal", 	label: "Ubicacion", 	sortable: true },
				{ key: "diametro", 		label: "Diametro", 		sortable: true },
				{ key: "moneda", 		label: "Mon.", 		sortable: true },
				{ key: "importe", 		label: "Precio", 		sortable: true },
				{ key: "estado", 		label: "Estado", 		sortable: true }],
			items: [],
			filter: null,
			corporals: [],
			tratamientos: [],
			ubicacionCorporals: [],
			diametros: [],
			monedas: [],
			form: {
				corporal_id: "",
				diametro_id: "",
				moneda_id: "",
				duracion:0,
				importe:0,
				tratamiento_id:"",
				ubicacion_corporal_id:""
			},
		};
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	watch: {
		async "form.corporal_id"(newValor, oldValor) {
			let { data } = await this.axios.get('select/ubicacionCorporals/' + newValor);
			this.ubicacionCorporals = data;
		},
	},
	methods: {
		async init(){
			let resp: any = '';
			try {
				this.listar();
				resp = await this.axios.get('select/corporals');
				this.corporals = resp.data;
				resp = await this.axios.get('select/tratamientos');
				this.tratamientos = resp.data;
				resp = await this.axios.get('select/diametros');
				this.diametros = resp.data;
				resp = await this.axios.get('select/monedas');
				this.monedas = resp.data;				
			} catch (error) {				
				this.catchAction(error);
			}			
		},
		async listar(){
			try {
				let {data} = await this.axios.get('tratamientoUbicacionCorporals');
				this.items = data;	
				this.totalRows = this.items.length;	
			} catch (error) {
				console.log(error);
			}
		},
		async show(id = 0){
			this.cargar = false;
			try {
				this.reset();
				if(id > 0){
					let {data} = await this.axios.get('tratamientoUbicacionCorporals/' + id);
					this.form = data;

					let tratamiento = this.tratamientos.find(item => item.id ==  this.form.tratamiento_id);
					this.form.tratamiento = tratamiento;
				}
				// @ts-ignore
				$(".modal").modal({backdrop: 'static', keyboard: false})
			} catch (error) {
				this.catchAction(error);
			}	
		},
		async state(item, state){
			let title = 'Activar?';
			let confirmButtonText = 'Si, Activar.';
			if(state == 0){
				title = 'Desactivar?';
				confirmButtonText = 'Si, Desactivar.';
			}

			this.$swal.fire({
				title: title,
				text: item.nombre,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: confirmButtonText,
				showLoaderOnConfirm: true,
				preConfirm: (login) => {
					return this.axios.patch('tratamientoUbicacionCorporals/' + item.id+'/state',{isActive: state})
					.then((result) => {
						return result.data;
					}).catch((err) => {
						this.$swal.showValidationMessage(
							`Request failed: ${err}`
						)
					});
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {	
					this.$swal.fire(
						'Estado',
						result.value.msg,
						'success'
					)
					this.listar();
				}
			});
		},
		reset(){
			this.form = {
				tratamiento: "",
				tratamiento_id: "",
				corporal_id: "",
				nombre: "",
				diametro_id: "",
				moneda_id: "",
				duracion:0
			}
		},
		async submit(e){
			e.preventDefault();
			let data:any = '';
			let resp:any = '';
			this.cargar = true;
			this.form.tratamiento_id = this.form.tratamiento.id;
			try {
				if(this.form.id > 0){
					resp 	= await this.axios.put('tratamientoUbicacionCorporals/' + this.form.id, this.form);
					data	= resp.data;
				}else{
					resp 	= await this.axios.post('tratamientoUbicacionCorporals', this.form);
					data	= resp.data;
				}
				
				this.listar();
				// @ts-ignore
				$(".modal").modal('hide');
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
			this.cargar = false;
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
