

import SidebarMenu from '../components/SidebarMenu.vue';
import SidebarFooter from '../components/SidebarFooter.vue';
import Nav from '../components/Nav.vue';

export default {
	components: { SidebarMenu, Nav, SidebarFooter },
	data() {
		return {

		}
	},
	computed: {
		user() {
			return this.$store.state.user;
		}
	},
	created() {
		let body = document.querySelector("body");
		body.className = "nav-md";
	},
}
