import { render, staticRenderFns } from "./Seguimiento.vue?vue&type=template&id=f49f1fa6&scoped=true"
import script from "./Seguimiento.vue?vue&type=script&lang=ts"
export * from "./Seguimiento.vue?vue&type=script&lang=ts"
import style0 from "./Seguimiento.vue?vue&type=style&index=0&id=f49f1fa6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f49f1fa6",
  null
  
)

export default component.exports