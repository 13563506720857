import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Menu from '../views/Menu/index.vue'
import Index from '../views/Index.vue'


import Rol from '../views/Sistema/Rol.vue'
import Modulo from '../views/Sistema/Modulo.vue'
import SubModulo from '../views/Sistema/SubModulo.vue'
import Sede from '../views/Sistema/Sede.vue'
import Empresa from '../views/Sistema/Empresa.vue'

import Personal from '../views/RRHH/Personal.vue'
import Comision from '../views/RRHH/Comision.vue'
import Usuario from '../views/RRHH/Usuario.vue'
import Profile from '../views/RRHH/Profile.vue'
import Especialidad from '../views/RRHH/Especialidad.vue'

import Paciente from '../views/Paciente/Pacientes.vue'
import ShowPaciente from '../views/Paciente/ShowPaciente.vue'

import Consultorio from '../views/Clinica/Consultorio.vue'

import Programacion from '../views/Cita/Programacion.vue'
import CitaCrear from '../views/Cita/CitaCrear.vue'
import ConsultaCita from '../views/Cita/Consulta.vue'
import AgendaMedica from '../views/Cita/AgendaMedica.vue'

import Area from '../views/Clinica/Area.vue'
import Alergia from '../views/Clinica/Alergia.vue'
import Cie from '../views/Clinica/Cie.vue'
import Enfermeria from '../views/Clinica/Enfermeria.vue'
import EnfermeriaShow from '../views/Clinica/EnfermeriaShow.vue'
import GExamenClinico from '../views/Clinica/GExamenClinico.vue'
import ExamenClinico from '../views/Clinica/ExamenClinico.vue'
import UbiCorporal from '../views/Clinica/UbiCorporal.vue'
import TraUbiCorporal from '../views/Clinica/TratamientoUbicacionCorporal.vue'
import Diametro from '../views/Clinica/Diametro.vue'
import TipoAtencion from '../views/Clinica/TipoAtencion.vue'
import TipoContacto from '../views/Clinica/TipoContacto.vue'
import TipoCrm from '../views/Clinica/TipoCrm.vue'

import Atencion from '../views/Medico/Atencion.vue'
import AtencionShow from '../views/Medico/AtencionShow.vue'

import TipoTratamiento from '../views/Cita/TipoTratamiento.vue'
import Tratamiento from '../views/Cita/Tratamiento.vue'

import Productos from '../views/Producto/Productos.vue'
import ShowProducto from '../views/Producto/ShowProducto.vue'
import Categoria from '../views/Producto/Categoria.vue'
import Marca from '../views/Producto/Marca.vue'
import Presentacion from '../views/Producto/Presentacion.vue'
import FormulaComponente from '../views/Producto/FormulaComponente.vue'
import GrupoMedicamento from '../views/Producto/GrupoMedicamento.vue'
import UnidadMedida from '../views/Producto/UnidadMedida.vue'

import DocumentoIdentidad from '../views/RRHH/DocumentoIdentidad.vue'

import TipoAfectacion from '../views/Venta/TipoAfectacion.vue'
import TipoComprobante from '../views/Venta/TipoComprobante.vue'
import Moneda from '../views/Venta/Moneda.vue'
import MedioPago from '../views/Venta/MedioPago.vue'
import Cliente from '../views/Venta/Cliente.vue'
import Caja from '../views/Venta/Caja.vue'
import CajaMovimiento from '../views/Venta/CajaMovimiento.vue'
import Pos from '../views/Venta/Pos.vue'
import Nota from '../views/Venta/Nota.vue'
import Notas from '../views/Venta/Notas.vue'
import TipoCambio from '../views/Venta/TipoCambio.vue'
import CajaDiaria from '../views/Venta/CajaDiaria.vue'
import CajaDiariaShow from '../views/Venta/CajaDiariaShow.vue'

import ComisionTipoAtencion from '../views/Administracion/ComisionTipoAtencion.vue'
import Comisiones from '../views/Administracion/Comisiones.vue'
import Comprobantes from '../views/Administracion/Comprobantes.vue'
import Marketing from '../views/Administracion/Marketing.vue'
import CierreCaja from '../views/Administracion/CierreCaja.vue'
import CierreCajaDetalle from '../views/Administracion/CierreCajaDetalle.vue'

import Liquidacion from '../views/Contabilidad/Liquidacion.vue'
import LiquidacionDetalle from '../views/Contabilidad/LiquidacionDetalle.vue'

/* Marketing */
import Seguimiento from '../views/Marketing/Seguimiento.vue';
import Protocolo from '../views/Marketing/Protocolo.vue';
import Cita from '../views/Marketing/Citas.vue';
import Reactivacion from '../views/Marketing/Reactivacion.vue';
import Recuperacion from '../views/Marketing/Recuperacion.vue';
import Telemarketing from '../views/Marketing/Telemarketing.vue';
import PostVenta from '../views/Marketing/PostVenta.vue';

/* Reporte */
import RUsuario from '../views/Reporte/Usuario.vue';
import PAtencion from '../views/Reporte/PAtencion.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{ path: '/', name: 'Login', component: Login },
	{
		path: '/dashboard', name: 'Dashboard', component: Dashboard,
		children: [
			{ path: '/', name: 'Index', component: Index },
			{ path: '/menu', name: 'Menu', component: Menu },
			{ path: '/rol', name: 'Rol', component: Rol },
			{ path: '/modulo', name: 'Modulo', component: Modulo },
			{ path: '/sub_modulo', name: 'SubModulo', component: SubModulo },
			{ path: '/sede', name: 'Sede', component: Sede },
			{ path: '/empresa', name: 'Empresa', component: Empresa },

			{ path: '/personal', name: 'Personal', component: Personal },
			{ path: '/comision', name: 'Comision', component: Comision },
			{ path: '/usuarios', name: 'Usuario', component: Usuario },
			{ path: '/profile', name: 'Profile', component: Profile },
			{ path: '/especialidad', name: 'Especialidad', component: Especialidad },
			{ path: '/ubicacion_corporal', name: 'UbiCorporal', component: UbiCorporal },
			{ path: '/lista_tratamiento', name: 'TraUbiCorporal', component: TraUbiCorporal },
			{ path: '/diametro', name: 'Diametro', component: Diametro },
			{ path: '/tipoAtencion', name: 'TipoAtencion', component: TipoAtencion },
			{ path: '/tipoContacto', name: 'TipoContacto', component: TipoContacto },
			{ path: '/tipoCrm', name: 'TipoCrm', component: TipoCrm },

			{ path: '/pacientes', name: 'Paciente', component: Paciente },
			{ path: '/paciente', name: 'ShowPaciente', component: ShowPaciente },
			{ path: '/consultorio', name: 'Consultorio', component: Consultorio },

			{ path: '/comisiones', name: 'Comisiones', component: Comisiones },
			{ path: '/comision_tipo_atencion', name: 'ComisionTipoAtencion', component: ComisionTipoAtencion },
			{ path: '/comprobantes', name: 'Comprobantes', component: Comprobantes },
			{ path: '/marketing', name: 'Marketing', component: Marketing },
			{ path: '/liquidacion_caja', name: 'CierreCaja', component: CierreCaja },
			{ path: '/liquidacion_caja/:id', name: 'CierreCajaDetalle', component: CierreCajaDetalle },

			{ path: '/liquidacion', name: 'Liquidacion', component: Liquidacion },
			{ path: '/liquidacion/:id', name: 'LiquidacionDetalle', component: LiquidacionDetalle },

			{ path: '/programacion', name: 'Programacion', component: Programacion },
			{ path: '/cita_crear', name: 'CitaCrear', component: CitaCrear },
			{ path: '/cita_consulta', name: 'ConsultaCita', component: ConsultaCita },
			{ path: '/agenda_medica', name: 'AgendaMedica', component: AgendaMedica },
			{ path: '/enfermeria', name: 'Enfermeria', component: Enfermeria },
			{ path: '/enfermeria/paciente', name: 'EnfermeriaShow', component: EnfermeriaShow },
			{ path: '/area', name: 'Area', component: Area },
			{ path: '/alergia', name: 'Alergia', component: Alergia },
			{ path: '/cie', name: 'Cie', component: Cie },

			{ path: '/tipo_tratamiento', name: 'TipoTratamiento', component: TipoTratamiento },
			{ path: '/tratamiento', name: 'Tratamiento', component: Tratamiento },

			{ path: '/formula_componente', name: 'FormulaComponente', component: FormulaComponente },
			{ path: '/categoria', name: 'Categoria', component: Categoria },
			{ path: '/marca', name: 'Marca', component: Marca },
			{ path: '/presentacion', name: 'Presentacion', component: Presentacion },
			{ path: '/productos', name: 'Productos', component: Productos },
			{ path: '/producto', name: 'ShowProducto', component: ShowProducto },
			{ path: '/grupo_medicamento', name: 'GrupoMedicamento', component: GrupoMedicamento },
			{ path: '/unidad_medida', name: 'UnidadMedida', component: UnidadMedida },

			{ path: '/tipo_afectacion', name: 'TipoAfectacion', component: TipoAfectacion },
			{ path: '/tipo_comprobante', name: 'TipoComprobante', component: TipoComprobante },
			{ path: '/moneda', name: 'Moneda', component: Moneda },
			{ path: '/medio_pago', name: 'MedioPago', component: MedioPago },
			{ path: '/cliente', name: 'Cliente', component: Cliente },
			{ path: '/caja', name: 'Caja', component: Caja },
			{ path: '/cajaMovimiento', name: 'CajaMovimiento', component: CajaMovimiento },
			{ path: '/pos', name: 'Pos', component: Pos },
			{ path: '/nota', name: 'Nota', component: Nota },
			{ path: '/notas', name: 'Notas', component: Notas },
			{ path: '/tipoCambio', name: 'TipoCambio', component: TipoCambio },
			{ path: '/caja_diaria', name: 'CajaDiaria', component: CajaDiaria },
			{ path: '/caja_diaria/:id', name: 'CajaDiariaShow', component: CajaDiariaShow },

			{ path: '/atencion', name: 'Atencion', component: Atencion },
			{ path: '/atencion/historia', name: 'AtencionShow', component: AtencionShow },
			{ path: '/grupo_examen_clinico', name: 'GExamenClinico', component: GExamenClinico },
			{ path: '/examen_clinico', name: 'ExamenClinico', component: ExamenClinico },

			{ path: '/marketing-seguimiento', name: 'SeguimientoMarketing', component: Seguimiento },
			{ path: '/marketing-protocolo', name: 'Protocolo', component: Protocolo },
			{ path: '/marketing-cita', name: 'Cita', component: Cita },
			{ path: '/reactivacion', name: 'Reactivacion', component: Reactivacion },
			{ path: '/recuperacion', name: 'Recuperacion', component: Recuperacion },
			{ path: '/telemarketing', name: 'Telemarketing', component: Telemarketing },
			{ path: '/post-venta', name: 'PostVenta', component: PostVenta },

			{ path: '/documento_identidad', name: 'DocumentoIdentidad', component: DocumentoIdentidad },

			{ path: '/reporte_usuario', name: 'RUsuario', component: RUsuario },
			{ path: '/paciente_atencion', name: 'PAtencion', component: PAtencion },
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
