
import PageTitle from "../../components/PageTitle.vue";

export default {
	components: { PageTitle },
	data() {
		return {
			cargar:false,
			title: "Comisión por Atención",
			totalRows: 1,
			currentPage: 1,
			perPage: 20,
			fields: [
				{ key: "acciones", 	label: "Acciones",	sortable: false },
				{ key: "tipo_atencion", label: "Tipo Atención", 	sortable: true },
				{ key: "fecha_vigencia_inicio", label: "fecha vigencia inicio", 	sortable: true },
				{ key: "fecha_vigencia_fin", label: "fecha vigencia fin", 	sortable: true },
				{ key: "tipo", label: "Tipo", 	sortable: true },
				{ key: "comision", label: "Comisión", 	sortable: true },
				{ key: "estado", label: "Estado", sortable: true }],
			items: [],
			tipoAtencions: [],
			filter: null,
			form: {
				nombre: "",
			},
		};
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	methods: {
		async init(){
			let resp: any = '';
			try {
				resp = await this.axios.get('select/tipoAtencions');
				this.tipoAtencions = resp.data;
				this.listar();
			} catch (error) {
				this.catchAction(error);				
			}
			
		},
		async listar(){
			try {
				let {data} = await this.axios.get('comisionReciboHonorarios');
				this.items = data;	
				this.totalRows = this.items.length;	
			} catch (error) {
				this.catchAction(error);
			}
		},
		async show(id = 0){
			this.cargar = false;
			try {
				this.reset();
				if(id > 0){
					let {data} = await this.axios.get('comisionReciboHonorarios/' + id);
					this.form = data;
				}
				// @ts-ignore
				$(".modal").modal({backdrop: 'static', keyboard: false})
			} catch (error) {				
				this.catchAction(error);
			}	
		},
		reset(){
			this.form = {
				nombre: "",
			}
		},
		async submit(e){
			e.preventDefault();
			let data:any = '';
			let resp:any = '';
			this.cargar = true;
			try {
				if(this.form.id > 0){
					resp 	= await this.axios.put('comisionReciboHonorarios/' + this.form.id, this.form);
					data	= resp.data;
				}else{
					resp 	= await this.axios.post('comisionReciboHonorarios', this.form);
					data	= resp.data;
				}
				
				this.listar();
				// @ts-ignore
				$(".modal").modal('hide');
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargar = false;
				this.catchAction(error);
			}
			this.cargar = false;
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
