import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSweetalert2 from 'vue-sweetalert2';
import vSelect from 'vue-select';
import VueExcelXlsx from "vue-excel-xlsx";

// Import CSS
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

// Developer
var baseURL = 'http://127.0.0.1:8000/api/';
// QA
if(window.location.origin == 'http://adminclinic.projdevs.com'){
    baseURL = 'http://apiclinic.projdevs.com/api/';
}
// Produccion de emergencia
if(window.location.origin == 'http://admincderma.projdevs.com' || window.location.origin == 'http://www.admincderma.projdevs.com'){
    baseURL = 'http://apicderma.projdevs.com/api/';
}
// Produccion
if(window.location.origin == 'https://admin.cdermaonline.net.pe' || window.location.origin == 'https://www.admin.cdermaonline.net.pe'){
    baseURL = 'https://api.cdermaonline.net.pe/api/';
}
if(window.location.origin == 'https://10980085.cdermaonline.net.pe' || window.location.origin == 'https://www.10980085.cdermaonline.net.pe'){
    baseURL = 'https://api.cdermaonline.net.pe/api/';
}

axios.defaults.baseURL          = baseURL;
axios.defaults.withCredentials  = true;

// Vue Use
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueExcelXlsx);

//vue component
Vue.component('v-select', vSelect)

// @ts-ignore
window.Pusher = require('pusher-js');

// @ts-ignore
/*
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'ABCD123',
  wsHost: echoURL,
  wsPort: 6001,
  forceTLS: false,
  disableStats: true,
});
*/

//mixin
Vue.mixin({
	methods: {
		delaySistema: function (n) {
			return new Promise(function(resolve){
				setTimeout(resolve,n*1000);
			});
		},
		catchAction(error){
			console.log(error);
			if(error.response){
				let resp = error.response;
				let data = resp.data;
				if(resp.status == 404){
					// @ts-ignore
					return new PNotify({
							text: 'Error 404',
							type: 'error',
							styling: 'bootstrap3'
						});
				}
				if(resp.status == 422 ){
					let object = data.errors;
					let errors = [];
					for (const key in object) {
						if (Object.prototype.hasOwnProperty.call(object, key)) {
							const element = object[key];
							errors = [...errors, ...element];
						}
					}
					// @ts-ignore
					return new PNotify({
							text: errors.join('\n'),
							type: 'warning',
							styling: 'bootstrap3'
						});
				}
				if(resp.status == 429 ){					
					this.$router.push('/dashboard');
					// @ts-ignore
					return new PNotify({
							text: 'Error interno del sistema, comunicase con soporte',
							type: 'error',
							styling: 'bootstrap3'
						});
				}
				if(resp.status == 401 ){
					this.$router.push('/');
					// @ts-ignore
					return new PNotify({
							text: 'No se enuentra autenticado',
							type: 'error',
							styling: 'bootstrap3'
						});
				}
				// @ts-ignore
				return new PNotify({
							text: resp.data.msg,
							type: 'warning',
							styling: 'bootstrap3'
						});
			}else{
				// @ts-ignore
				return new PNotify({
					text: error.message,
					type: 'error',
					styling: 'bootstrap3'
				});
			}
		}
	}
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
