
import PageTitle from "../../components/PageTitle.vue";
import { Resq } from '@/models/resq';

export default {
	components: { PageTitle },
	data() {
		return {
			title: "Perfil",
			profile:{
				apellidoPaterno:"",
				apellidoMaterno:"",
				nombres:"",
				telefono:"",
				fijo:"",
				correo:"",
				direccion:"",
			},
			form: {
				password: "",
				password2: "",
			},
		};
	},
	computed: {
        user() {
            return this.$store.state.user;
        },
		fullName(){
			if(this.profile){
				return this.profile.apellidoPaterno+' '+this.profile.apellidoMaterno+' '+this.profile.nombres;
			}
			return '';
		}
    },
	methods: {
		async init(){
			if(this.user.personal_id>0){
				let {data} = await this.axios.get('personals/'+this.user.personal_id);
				console.log(data)
				this.profile = data;
			}
		},	
		async submit(e){
			e.preventDefault();
			if(this.form.password != this.form.password2){
				// @ts-ignore
				return new PNotify({
								text: 'Los password deben ser lo mismo.',
								type: 'warning',
								styling: 'bootstrap3'
							});
			}
			let data:any = '';
			this.cargar = true;
			try {

				let resp 	= await this.axios.patch('users/' + this.user.id+'/password', this.form);
				data		= resp.data;
			    this.cargar = false;
				// @ts-ignore
				new PNotify({
								text: data.msg,
								type: 'success',
								styling: 'bootstrap3'
							});
			} catch (error) {
				this.cargar = false;				
				this.catchAction(error);
			}
		}
	},
	mounted() {
		// Set the initial number of items
		this.init();
	},
};
